/*
  @file:
    _header.scss

  @description:
    Example of 'header' specific styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .header-container {
    &.default {
      svg {
        path {
          fill: $white;
        }
      }
      .languages {
        margin-left: auto;
        margin-right: 2rem;

        a {
          color: $white;
          text-decoration: none;
        }
      }

      .hamburger {
        .btn-menu {
          width: 54px;
          height: 54px;
          border: 3px solid $white;
          border-radius: 50%;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 3px;
            width: 1.5rem;
            background-color: $white;
            border-radius: 8px;

            &::before {
              content: "";
              position: absolute;
              top: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }
          }
        }
      }
    }
    &.blue {
      svg {
        path {
          fill: $white;
        }
      }
      .languages {
        margin-left: auto;
        margin-right: 2rem;
        a {
          color: $white;
        }
      }

      .hamburger {
        .btn-menu {
          width: 54px;
          height: 54px;
          border: 3px solid $white;
          border-radius: 50%;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 3px;
            width: 1.5rem;
            background-color: $white;
            border-radius: 8px;

            &::before {
              content: "";
              position: absolute;
              top: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }
          }
        }
      }
    }
    &.logo-blue {
      svg {
        path {
          fill: $color__blue;
        }
      }

      .cart {
        .nr {
          color: $white;
        }
      }

      .login-eko {
        svg {
          path {
            &.gray {
              fill: $logo__grey;
            }
            &.green {
              fill: $logo__green;
            }
            &.red {
              fill: $logo__red;
            }
          }
        }
      }

      .login-aphrodite {
        svg {
          path {
            &.dark {
              fill: $logo__aphrodite-dark;
            }
            &.green {
              fill: $logo__aphrodite-green;
            }
          }
        }
      }
      .languages {
        margin-left: auto;
        margin-right: 2rem;
        a {
          color: $color__blue;
        }
      }

      .cart {
        .nr {
          color: $white;
        }
      }

      .hamburger {
        .btn-menu {
          width: 54px;
          height: 54px;
          border: 3px solid $white;
          border-radius: 50%;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 3px;
            width: 1.5rem;
            background-color: $white;
            border-radius: 8px;

            &::before {
              content: "";
              position: absolute;
              top: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(50%);
              height: 3px;
              width: 1.5rem;
              background-color: $white;
              border-radius: 8px;
            }
          }
        }
      }
    }
    &.white {
      .logo {
        .login-eko {
          &::before {
            content: "";
            position: absolute;
            left: -1rem;
            top: -.5rem;
            bottom: -.5rem;
            width: 1px;
            background-color: $color__blue;

            @include media-breakpoint-down(sm) {
              left: -.5rem;
            }
          }

          svg {
            path {
              &.gray {
                fill: $logo__grey;
              }
              &.green {
                fill: $logo__green;
              }
              &.red {
                fill: $logo__red;
              }
            }
          }
        }

        .login-aphrodite {
          &::before {
            background-color: $color__blue;
          }

          svg {
            path {
              &.dark {
                fill: $logo__aphrodite-dark;
              }
              &.green {
                fill: $logo__aphrodite-green;
              }
            }
          }
        }
      }
      svg {
        path {
          fill: $color__blue;
        }
      }

      //.scroll-progress {
      //  position: absolute;
      //  width: 54px;
      //  height: 54px;
      //
      //  circle {
      //    stroke: $color__blue;
      //    stroke-width: 3px;
      //  }
      //
      //  .progress {
      //    stroke-width: 3px;
      //    transition: .1s;
      //    stroke: $white;
      //  }
      //}
      .languages {
        margin-left: auto;
        margin-right: 2rem;
        a {
          color: $color__blue;
        }
      }

      .cart {
        .nr {
          color: $white;
        }
      }

      .hamburger {
        .btn-menu {
          width: 54px;
          height: 54px;
          border: 3px solid $color__blue;
          border-radius: 50%;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 3px;
            width: 1.5rem;
            background-color: $color__blue;
            border-radius: 8px;

            &::before {
              content: "";
              position: absolute;
              top: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              height: 3px;
              width: 1.5rem;
              background-color: $color__blue;
              border-radius: 8px;
            }

            &::after {
              content: "";
              position: absolute;
              bottom: -6px;
              left: 50%;
              transform: translateX(-50%) translateY(50%);
              height: 3px;
              width: 1.5rem;
              background-color: $color__blue;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 1.25rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem 2rem;
    }

    &.alter {
      @media only screen and (max-width:1310px) {
        transition: background-color $general-transition-time $general-transition-ease;

        .scroll-progress {
          display: none !important;
        }

        .logo {
          .login-eko {
            &::before {
              transition: background-color $general-transition-time $general-transition-ease;
            }
          }
        }

        svg {
          path {
            transition: fill $general-transition-time $general-transition-ease;
          }
        }

        .btn-menu {
          transition: border-color $general-transition-time $general-transition-ease;

          span {
            transition: background-color $general-transition-time $general-transition-ease;

            &::before,
            &::after {
              transition: background-color $general-transition-time $general-transition-ease;
            }
          }
        }
      }
    }

    &.color-invert {
      @media only screen and (max-width:1310px) {
         background-color: $white;

        svg {
          path {
            fill: $color__blue !important;
          }
        }

        .login-eko {
          &::before {
            background-color: $color__blue !important;
          }
          svg {
            path {
              &.gray {
                fill: $logo__grey !important;
              }
              &.green {
                fill: $logo__green !important;
              }
              &.red {
                fill: $logo__red !important;
              }
            }
          }
        }
        .login-aphrodite {
          &::before {
            background-color: $color__blue !important;
          }

          svg {
            path {
              &.dark {
                fill: $logo__aphrodite-dark !important;
              }
              &.green {
                fill: $logo__aphrodite-green !important;
              }
            }
          }
        }
        .languages {
          margin-left: auto;
          margin-right: 2rem;
          a {
            color: $color__blue !important;
          }
        }

        .cart {
          .nr {
            color: $white !important;
          }
        }

        .hamburger {
          .btn-menu {
            border: 3px solid $color__blue !important;

            span {
              background-color: $color__blue !important;

              &::before,
              &::after {
                background-color: $color__blue !important;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    overflow: hidden;

    a {
      display: block;
      width: 10rem;
      height: 50px;

      @include media-breakpoint-down(xxl) {
        width: 7rem;
        height: auto;
      }
      @include media-breakpoint-down(sm) {
        width: 5rem;
        height: auto;
      }

      &.login-eko {
        width: 196px;
        height: 54px;
        //height: 50px;

        margin-left: 34px;
        position: relative;

        @include media-breakpoint-down(xxl) {
          width: 137px;
          height: auto;
        }
        @include media-breakpoint-down(sm) {
          width: 104px;
          height: auto;
          margin-left: 18px;
          position: relative;
        }

        //@include media-breakpoint-down(xxl) {
        //  width: 5rem;
        //}
        //@include media-breakpoint-down(sm) {
        //  width: 96px;
        //  //height: auto;
        //}

        &::before {
          content: "";
          position: absolute;
          left: -1rem;
          top: -.5rem;
          bottom: -.5rem;
          width: 1px;
          background-color: $white;

          @include media-breakpoint-down(sm) {
            left: -.5rem;
          }
        }

        img {
          width: 100%;
          height: auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

        svg {
          path {
            fill: $white;
          }
        }
      }

      &.login-aphrodite {
        height: 60px;
        width: auto;

        margin-left: 34px;
        position: relative;

        @include media-breakpoint-down(xxl) {
          height: 60px;
        }

        @include media-breakpoint-down(sm) {
          height: 40px;
          margin-left: 18px;
          position: relative;
        }

        &::before {
          content: "";
          position: absolute;
          left: -.5rem;
          top: -.5rem;
          bottom: -.5rem;
          width: 1px;
          background-color: $white;
        }

        img {
          width: 100%;
          height: auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

        svg {
          width: auto;

          path {
            fill: $white;
          }
        }
      }

      img {
        height: 100%;
        width: 100%;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .scroll-progress {
    position: absolute;
    pointer-events: none;
    z-index: 2;
    width: 54px;
    height: 54px;

    circle {
      stroke: $white;
      stroke-width: 3px;
    }

    .progress {
      stroke-width: 3px;
      transition: .1s;
      stroke: $color__blue;
    }

    & + .btn-menu {
      @include media-breakpoint-up(md) {
        border: 0 !important;
      }
    }
  }

  .cart {
    width: 32px;
    height: 32px;
    margin-left: auto;
    margin-right: 1.5rem;
    position: relative;
    cursor: pointer;

    .nr {
      position: absolute;
      bottom: 4px;
      right: 7px;
      transform: translateX(50%);

      @include font-style--alter($font-primary, 10, 10, $weight--xBold, $color__blue, none);
    }
  }

  //.hamburger {
  //  .btn-menu {
  //    width: 54px;
  //    height: 54px;
  //    border: 3px solid $white;
  //    border-radius: 50%;
  //    position: relative;
  //
  //    span {
  //      position: absolute;
  //      top: 50%;
  //      left: 50%;
  //      transform: translateX(-50%) translateY(-50%);
  //      height: 3px;
  //      width: 1.5rem;
  //      background-color: $white;
  //      border-radius: 8px;
  //
  //      &::before {
  //        content: "";
  //        position: absolute;
  //        top: -6px;
  //        left: 50%;
  //        transform: translateX(-50%) translateY(-50%);
  //        height: 3px;
  //        width: 1.5rem;
  //        background-color: $white;
  //        border-radius: 8px;
  //      }
  //
  //      &::after {
  //        content: "";
  //        position: absolute;
  //        bottom: -6px;
  //        left: 50%;
  //        transform: translateX(-50%) translateY(50%);
  //        height: 3px;
  //        width: 1.5rem;
  //        background-color: $white;
  //        border-radius: 8px;
  //      }
  //    }
  //  }
  //}
}