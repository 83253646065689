/*
  @file:
    _breadcrumbs.scss

  @description:
    Example of 'breadcrumb' specific styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
.breadcrumbs {
  margin-bottom: 1.75rem;

  display: flex;
  align-items: center;
  overflow: hidden;

  .breadcrumb-item {
    margin-left: 2.75rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -24px;
      transform: translateY(-50%);
      width: 7px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%230095C6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 7px 12px;

      @include media-breakpoint-down(md) {
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }
    }

    &:first-child {
      margin-left: 0;
    }

    a {
      @include font-style--alter($font-primary, 16, 32, $weight--regular, $color__blue, none);
      text-transform: uppercase;
      letter-spacing: 1px;

      @include media-breakpoint-down(md) {
        color: $white;
      }
    }
  }
}