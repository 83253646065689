/*
  @file:
    _base.scss

  @description:
    Base related rules
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {

  @include font-smoothing();
  position: relative;
  // font-size: 62.5%; // Make sure we get the 10px base font size
  text-rendering: optimizeLegibility;
  visibility: visible;
}

* {
  min-height: 0;
  min-width: 0;
}