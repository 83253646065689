/*
  @file:
    _toolbar.scss

  @description:
    Module used to toolbar related
    styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
.filters-toolbar {
  padding: 3.75rem 0;
  margin-bottom: 2.5rem;

  @include media-breakpoint-down(sm) {
    overflow: auto;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    margin: 0 auto;
    padding: 0 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
    }

    span {
      @include font-style--alter($font-secondary, 20, 20, $weight--regular, rgba($color__grey-4, .5), none);
      text-transform: uppercase;

      display: block;
      margin: 0 .75rem;

      @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
        justify-content: flex-start;
      }

      li {
        margin: 0 .75rem;

        @include media-breakpoint-down(sm) {
          flex: 0 0 auto;
        }

        &.active {
          @include media-breakpoint-down(sm) {
            order: -1;
          }

          a {
            font-weight: $weight--xBold;
            color: $color__grey-4;
          }
        }
      }

      a {
        @include font-style--alter($font-secondary, 20, 20, $weight--semiBold, $color__grey-5, none);
        text-transform: uppercase;
      }
    }
  }
}