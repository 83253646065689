.content-offset {
  width: 100%;
  max-width: 1560px;
  @include media-breakpoint-down(xxl) {
    max-width: 1160px;
  }
  margin: 0 auto 5.75rem auto;
  padding: 0 1rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }

  &-wrapper {
    max-width: 640px;
  }

  h2 {
    @include font-style--alter($font-secondary, 42, 42, $weight--regular, $color__grey-dark, none);
    text-transform: uppercase;

    @include media-breakpoint-up(xxxl) {
      @include font-style--alter($font-secondary, 80, 72, $weight--regular, $color__grey-dark, none);
    }

    strong {
      font-weight: $weight--bold;
    }
  }
}

.title-container {
  width: 100%;
  max-width: 576px;
  margin: 0 auto 4rem auto;
  padding: 0 1rem;

  h2 {
    @include font-style--alter($font-secondary, 42, 42, $weight--regular, $color__grey-dark, none);
    text-transform: uppercase;

    @include media-breakpoint-up(xxxl) {
      @include font-style--alter($font-secondary, 80, 72, $weight--regular, $color__grey-dark, none);
    }
  }
}

.text-container {
  width: 100%;
  max-width: 576px;
  margin: 0 auto 4rem auto;
  padding: 0 1rem;

  h2 {
    @include font-style--alter($font-secondary, 48, 54, $weight--semiBold, $color__grey-dark, none);
    margin-bottom: 2.25rem;
    text-transform: uppercase;
  }

  p {
    @include font-style--alter($font-primary, 22, 36, $weight--regular, $color__grey, none);

    @include media-breakpoint-down(xxl) {
      @include font-style--alter($font-primary, 18, 32, $weight--regular, $color__grey, none);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.image-text-container {
  width: 100%;
  max-width: 1232px;
  padding: 0 1rem;

  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  margin: 0 auto 2.5rem auto;

  @include media-breakpoint-up(md) {
    margin: 0 auto 3rem auto;
  }
  @include media-breakpoint-up(lg) {
    margin: 0 auto 4rem auto;
  }

  .image {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: .5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      flex: 0 0 70%;
      width: 70%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .text {
    flex: 0 0 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 30%;
      width: 30%;
      padding-left: 2rem;
      padding-right: 3rem;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 2.25rem;
      padding-right: 7rem;
    }

    p {
      @include font-style--alter($font-primary, 16, 20, $weight--regular, $color__grey-dark, none);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.content-carousel {
  width: 100%;
  max-width: 1732px;
  margin: 4rem auto;

  @include media-breakpoint-up(md) {
    padding: 0 5rem;
    margin: 80px auto 120px auto;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 5rem;
    margin: 104px auto 160px auto;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 5rem;
    margin: 132px auto 174px auto;
  }

  &.slick-slider {
    position: relative;

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 50%;

      text-indent: -999em;
      background-color: $color__grey-6;

      @include media-breakpoint-down(sm) {
        display: none !important;
      }

      @extend .btn;
      @extend .btn-plain;

      &.slick-prev {
        left: .5rem;
        background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 9px 16px;
      }
      &.slick-next {
        right: .5rem;
        background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 9px 16px;
      }
    }

    .slick-track {
      @extend %clearfix;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-slide {
      float: left;
    }

    .slick-dots {
      margin-top: 1rem;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 1rem;

      li {
        margin: 0 .25rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        span {
          @include font-style--alter($font-secondary, 20, 32, $weight--regular, $black, none);
        }

        &.slick-active {
          span {
            font-weight: $weight--black;
          }
        }
      }
    }
  }

  .carousel-item {
    position: relative;

    .image {
      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      position: relative;
      padding: 2.5rem 1rem 1.5rem 1rem;

      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        left: 3.875rem;
        width: 340px;
        background: $white;
        padding: 2.75rem 3rem 2.5rem 3.5rem;
      }

      .number {
        position: absolute;
        top: .5rem;
        left: .75rem;

        @include font-style--alter($font-secondary, 20, 32, $weight--black, $black, none);
      }

      p {
        @include font-style--alter($font-primary, 16, 26, $weight--regular, $black, none);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-video {
  width: 100%;
  max-width: 864px;
  padding: 0 1rem;
  margin: 1.5rem auto 2.5rem auto;

  @include media-breakpoint-up(md) {
    margin: 4rem auto;
  }
  @include media-breakpoint-up(lg) {
    margin: 5rem auto;
  }
  @include media-breakpoint-up(xl) {
    margin: 7rem auto;
  }

  .media {
    position: relative;
    margin-bottom: .25rem;
    overflow: hidden;

    .video {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      &.playerActive {
        .btn-play {
          opacity: 0;
          visibility: hidden;
        }

        .poster {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .poster {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;

      transition: opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .btn-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 5rem;
      z-index: 3;

      transition: opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.content-quote {
  width: 100%;
  max-width: 560px;
  padding: 0 1rem;
  position: relative;
  margin: 5rem auto 3rem auto;

  @include media-breakpoint-up(md) {
    margin: 6rem auto 4rem auto;
  }
  @include media-breakpoint-up(xl) {
    margin: 8rem auto 4rem auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-10%, -60%);
    width: 103px;
    height: 85px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%230095C6' fill-opacity='.3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;

    @include media-breakpoint-down(sm) {
      width: 5rem;
    }
  }

  p {
    @include font-style--alter($font-primary, 32, 40, $weight--bold, $color__blue, none);
    margin-bottom: 0;
  }
}

.content-banner {
  width: 100%;
  position: relative;
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    padding-right: 5%;
    margin-bottom: 10rem;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 6%;
    margin-bottom: 10rem;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 7%;
    margin-bottom: 226px;
  }

  .image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .text {
    position: relative;

    @include media-breakpoint-up(md) {
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      bottom: -7rem;
      width: 100%;
      max-width: 1712px;
      padding: 0 1rem;
    }
    @include media-breakpoint-up(lg) {
      bottom: -4rem;
    }

    &-wrapper {
      width: 100%;
      max-width: 840px;
      background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);
      padding: 2rem 1rem;

      @include media-breakpoint-up(md) {
        padding: 2rem 3.5rem;
      }
      @include media-breakpoint-up(xxl) {
        padding: 3.5rem 5.5rem;
      }
    }

    h2 {
      @include font-style--alter($font-secondary, 48, 55, $weight--semiBold, $color__grey-light, none);
      letter-spacing: -.4px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(xxl) {
        max-width: 70%;
        margin-bottom: 2rem;
      }
    }

    p {
      @include font-style--alter($font-primary, 22, 36, $weight--regular, $white, none);
      letter-spacing: .1px;
      margin-bottom: 2rem;

      @include media-breakpoint-down(xxl) {
        @include font-style--alter($font-primary, 18, 32, $weight--regular, $white, none);
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: 3rem;
        max-width: 70%;
      }
    }

    .links {
      display: flex;
      align-items: center;
    }

    .link-bullet {
      margin-left: 2.75rem;

      @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-light, none);
      text-transform: uppercase;
      padding: .5rem 0 .5rem 2.25rem;

      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 26px 26px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.image-fullwidth {
  margin-bottom: 3rem;
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 1714px;
    padding: 0 1rem;
    margin: 0 auto 6.5rem auto;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  img.mobile {
    display: none;
  }

  @include media-breakpoint-down(sm) {

    img {
      display: none;
    }

    img.mobile {
      display: block;
    }

  }
}

.content-text-image {
  margin-bottom: 3rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 6.5rem;
  }

  &.section-vision {
    &.left {
      .text {
        &-wrapper {
          @include media-breakpoint-up(lg) {
            padding-right: 10rem;
          }
        }
      }
    }

    &.right {
      .text {
        &-wrapper {
          @include media-breakpoint-up(lg) {
            padding-left: 10rem;
          }
        }
      }
    }
  }

  &.left {
    .image {
      @include media-breakpoint-up(md) {
        order: 1;
      }
    }

    .text {
      @include media-breakpoint-up(md) {
        order: 2;
      }
    }
  }

  &.right {
    .image {
      @include media-breakpoint-up(md) {
        order: 2;
      }
    }

    .text {
      order: 1;

      &-wrapper {
        margin-left: auto;
      }
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1712px;
    margin: 0 auto;
    padding: 0 1rem;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .image {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        width: 50%;
        margin-bottom: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      flex: 0 0 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        width: 50%;
      }

      &-wrapper {
        max-width: 672px;

        @include media-breakpoint-up(md) {
          padding: 0 2.5rem;
        }
        @include media-breakpoint-up(xl) {
          padding: 0 4.5rem;
        }
      }

      .number {
        display: block;
        @include font-style--alter($font-secondary, 44, 30, $weight--light, $color__grey-8, none);
        margin-bottom: 1.25rem;
      }

      h2 {
        @include font-style--alter($font-secondary, 48, 54, $weight--semiBold, $color__grey-dark, none);
        text-transform: uppercase;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 3rem;
        }
      }

      h3 {
        @include font-style--alter($font-secondary, 28, 36, $weight--bold, $color__grey-dark, none);
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.625rem;
        }
      }

      p {
        @include font-style--alter($font-primary, 22, 36, $weight--regular, $color__grey, none);

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-primary, 18, 32, $weight--regular, $color__grey, none);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.management {
  margin-top: 4rem;
  padding-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-top: 5rem;
    padding-bottom: 4.5rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 7rem;
    padding-bottom: 6rem;
  }
  @include media-breakpoint-up(xxxl) {
    margin-top: 10rem;
    padding-bottom: 9.5rem;
  }

  &-wrapper {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    padding: 0 1rem;

    h2 {
      @include font-style--alter($font-secondary, 36, 30, $weight--regular, $black-3, none);
      text-transform: uppercase;

      width: 100%;
      max-width: 550px;
      margin: 0 auto 4rem auto;

      @include media-breakpoint-up(xxxl) {
        @include font-style--alter($font-secondary, 45, 36, $weight--regular, $black-3, none);
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
      }

      strong {
        font-weight: $weight--bold;
      }
    }

    .element {
      margin-bottom: 5.5rem;

      h3 {
        width: 100%;
        max-width: 550px;
        margin: 0 auto 3.5rem auto;

        @include media-breakpoint-down(md) {
          margin-bottom: 2.5rem;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 1.25rem;
        }

        @include font-style--alter($font-primary, 28, 21, $weight--light, $black-3, none);
        text-transform: uppercase;

        @include media-breakpoint-up(xxxl) {
          @include font-style--alter($font-primary, 33, 26, $weight--light, $black-3, none);
        }

        strong {
          font-weight: $weight--bold;
        }
      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 2rem;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 3rem;
        }
      }

      p {
        width: 100%;
        max-width: 550px;
        margin: 0 auto 3.5rem auto;
        @include font-style--alter($font-primary, 22, 36, $weight--regular, $color__grey, none);

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-primary, 18, 32, $weight--regular, $color__grey, none);
        }

        //@include media-breakpoint-up(xxxl) {
        //  @include font-style--alter($font-primary, 24, 40, $weight--regular, $color__grey, none);
        //}
      }
    }
  }
}

.link-bullet {
  @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-dark, none);
  text-transform: uppercase;
  padding: .5rem 0 .5rem 2.25rem;

  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 26px 26px;
  transition: background-size .2s $general-transition-ease;

  @include media-breakpoint-down(md) {
    color: $white;
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23FFFFFF' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
  }

  &:hover {
    background-size: 29px 29px !important;
  }
}

.text-container,
.image-text-container,
.content-carousel,
.content-video,
.content-quote,
.content-banner,
.content-text-image {
  &:last-child {
    margin-bottom: 0;
  }
}

.content-text-image,
.text-container {
  .link-bullet {
    @include media-breakpoint-down(md) {
      color: $color__grey-dark;
      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
    }
  }
}

@media only screen and (max-width:768px) {
	#moove_gdpr_cookie_info_bar {
		right: 0px!important;
    bottom: 0px!important;
	}
	#moove_gdpr_cookie_info_bar .mgbutton.moove-gdpr-infobar-allow-all {
		display:block;
		margin:auto!important;
	}
	#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container {
		padding:10px!important;	
	}
	.page-about.page-service-page {
		padding-top:6rem!important;
	}
	.section-knowledge .text p {
		display:none;
	}
	.page-section-interesting .carousel-item .text {
		margin-top:1rem;
	}
}

.content-text-image-wrapper .text p a {
  color:$color__blue;
}

.home-slides .section-projects .content p {
  @include font-style--alter($font-primary, 22, 29, $weight--regular, $color__grey-light, none);
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 2.75rem;
  }

  @include media-breakpoint-down(xxl) {
    @include font-style--alter($font-primary, 18, 25, $weight--regular, $color__grey-light, none);
  }
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }  
}

.home-slides .section-projects .content h2 {
  margin-bottom: 20px!important;
}