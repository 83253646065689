.page-section {
  position: relative;

  @include media-breakpoint-up(lg) {
    height: 100vh;
    min-height: 800px;
  }

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include media-breakpoint-up(lg) {
      position: relative;
      height: 100%;
      width: 100%;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img.mobile {
      display:none;
    }
    @include media-breakpoint-down(sm) {
      img {
        display:none;
      }
      img.mobile {
        display:block;
      }
    }
  }

  &-approach {
    height: auto;
    min-height: 0;

    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;

      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(58, 58, 58, 0) 6.87%, rgba(29, 29, 29, 0.52) 50.72%);
        mix-blend-mode: multiply;
      }
    }

    .content {
      position: relative;
      z-index: 2;
      padding: 4rem 1rem;
      width: 100%;
      max-width: 1560px;
      margin: 0 auto;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      //padding: 2.25rem 1rem;

      @include media-breakpoint-down(xxl) {
        max-width: 1160px;
      }
      @include media-breakpoint-up(md) {
        padding: 4rem 1rem;
      }
      @include media-breakpoint-up(xxxl) {
        padding: 104px 1rem 4rem 1rem;
      }

      h2 {
        @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
        text-transform: uppercase;
        margin-bottom: 1.625rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 3rem;
        }
        @include media-breakpoint-up(xxxl) {
          margin-bottom: 6.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
        }

        strong {
          font-weight: $weight--bold;
        }
      }

      .text-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
      }

      .text {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 6.5rem;

        &:last-child {
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }

        @include media-breakpoint-up(lg) {
          flex: 0 0 40%;
          width: 40%;
          margin-bottom: 0;
          padding-right: 6rem;
        }
      }

      h3 {
        @include font-style--alter($font-secondary, 40, 40, $weight--bold, $white, none);
        text-transform: uppercase;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 32, 32, $weight--bold, $white, none);
        }
      }

      p {
        @include font-style--alter($font-primary, 22, 29, $weight--regular, $color__grey-light, none);
        margin-bottom: 3.75rem;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-primary, 18, 25, $weight--regular, $color__grey-light, none);
        }
        @include media-breakpoint-down(md) {
          line-height: 1.75;
          margin-bottom: 1.5rem;
        }

      }

      .link-bullet {
        @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-light, none);
        text-transform: uppercase;
        padding: .5rem 0 .5rem 2.25rem;

        background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 26px 26px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &-projects {
    height: auto;
    min-height: 0;
    overflow: hidden;

    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &::before {
        display: none;
      }

      &.background-color {
        background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);
      }
    }

    .content {
      z-index: 2;
      width: 100%;
      max-width: 1560px;
      margin: 0 auto;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 2.25rem 1rem;
      min-height: 100vh;

      @include media-breakpoint-up(md) {
        padding: 104px 1rem 4rem 1rem;
      }
      @include media-breakpoint-down(xxl) {
        max-width: 1160px;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        left: -999em;
        right: calc(100% - 1rem);
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);
      }

      h2 {
        @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
        text-transform: uppercase;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
          font-size: 2.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          margin-bottom: 4rem;
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
        }

        br {
          display: none;
        }

        strong {
          font-weight: $weight--bold;
        }
      }
    }

    .carousel {
      transition: opacity $general-transition-time $general-transition-ease;
      transition-delay: .5s;
      margin-bottom: 3rem;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: stretch;
        margin: 0 -1.5rem 3rem -1.5rem;
      }
      @include media-breakpoint-up(xxxl) {
        display: flex;
        align-items: stretch;
        margin: 0 -3rem 3rem -3rem;
      }

      .Safari & {
        transform: translateX(1rem);
      }

      &.slick-slider {
        .slick-prev {
          display: none !important;
        }

        .slick-track {
          @extend %clearfix;

          display: flex;
          align-items: stretch;
        }

        .slick-slide {
          float: left;
          width: 160px;
          margin-right: 4rem;

          @include media-breakpoint-up(md) {
            padding: 0 1.5rem;
            margin-right: 0;
            width: auto;
          }

          @include media-breakpoint-up(xxxl) {
            //width: 250px;
            padding: 0 3.5rem;
          }

          & > div {
            height: 100%;
          }

          &.slick-current {
            .carousel-item {
              .title {
                opacity: 1;
              }
              .image {
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .text {
                opacity: 1;
              }
            }
          }
        }
      }

      .carousel-item {
        height: 100%;
        position: relative;

        &-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .title {
          margin-bottom: 1.25rem;
          flex: 1 0 auto;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          opacity: .7;

          transition: opacity $general-transition-time $general-transition-ease;

          h3 {
            @include font-style--alter($font-secondary, 18, 16, $weight--xBold, $color__grey-light, none);
            text-transform: uppercase;
            margin-bottom: 0;

            @include media-breakpoint-up(xxxl) {
              @include font-style--alter($font-secondary, 22, 22, $weight--xBold, $color__grey-light, none);
            }
          }
        }

        .image {
          flex: 0 0 auto;
          height: 200px;
          width: 100%;
          position: relative;
          margin-bottom: 1.25rem;

          @include media-breakpoint-up(xl) {
            height: 280px;
          }
          @include media-breakpoint-up(xxxl) {
            height: 320px;
          }

          img {
            position: absolute;
            //width: 160px;
            //height: 200px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            transition: width $general-transition-time $general-transition-ease,
            height $general-transition-time $general-transition-ease;

            //@include media-breakpoint-up(xl) {
            //  height: 280px;
            //  width: 220px;
            //}
            //@include media-breakpoint-up(xxl) {
            //  width: 250px;
            //  height: 320px;
            //}
            //@include media-breakpoint-up(xxxl) {
            //  width: 100%;
            //}
          }
        }
        .visit {
          @include font-style--alter($font-secondary, 16, 16, $weight--black, $white, none);
          text-transform: uppercase;
          margin-top: 2rem;
  
          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            width: 100%;
            order: 2;
          }
        }

        .text {
          flex: 0 0 auto;
          opacity: .7;
          transition: opacity $general-transition-time $general-transition-ease;

          p {
            @include font-style--alter($font-primary, 14, 18, $weight--regular, $color__grey-light, none);
            margin-bottom: 0;

            @include media-breakpoint-up(xxxl) {
              @include font-style--alter($font-primary, 18, 27, $weight--regular, $color__grey-light, none);
            }
            @include media-breakpoint-down(sm) {
              font-size: .875rem;
            }
          }
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        padding-bottom: 2rem;
      }
      @include media-breakpoint-up(xxl) {
        padding-left: .5rem;
        padding-right: .5rem;
      }

      .visit {
        @include font-style--alter($font-secondary, 16, 16, $weight--black, $white, none);
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          width: 100%;
          order: 2;
        }
      }

      .btn-carousel-right {
        @include font-style--alter($font-secondary, 14, 32, $weight--semiBold, $color__grey-2, none);
        text-transform: uppercase;
        padding-right: 50px;
        position: relative;

        transition: padding-right $general-transition-time $general-transition-ease;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 35px;
          height: 31px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 35px 31px;

          transition: opacity $general-transition-time $general-transition-ease,
          visibility $general-transition-time $general-transition-ease,
          right $general-transition-time $general-transition-ease;
        }

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          width: 100%;
          margin-bottom: 1.5rem;
          text-align: right;
        }
        @include media-breakpoint-up(md) {
          //display: none !important;
        }

        &:hover {
          &::after {
            opacity: 0;
            visibility: hidden;
            right: -24px;
          }
        }
      }
    }
  }

  &-interesting {
    height: auto;
    min-height: 0;
    overflow: hidden;
    background-color: $color__grey-2;

    .content {
      z-index: 2;
      width: 100%;
      max-width: 1560px;
      margin: 0 auto;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem 1rem;
      min-height: 100vh;

      @include media-breakpoint-down(xxl) {
        max-width: 1160px;
      }

      @include media-breakpoint-up(md) {
        padding: 3rem 1rem;
      }
      @include media-breakpoint-up(lg) {
        padding: 3.5rem 1rem 2.5rem 1rem;
        justify-content: center;
      }
      @include media-breakpoint-up(xl) {
        padding: 4.5rem 1rem 2.5rem 1rem;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        left: -999em;
        right: calc(100% - 1rem);
        top: 0;
        bottom: 0;
        background: $color__grey-2;
      }

      h2 {
        @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-7, none);
        text-transform: uppercase;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 3rem;
        }
        @include media-breakpoint-up(xxxl) {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-7, none);
        }

        strong {
          font-weight: $weight--bold;
        }
      }
    }

    .carousel {
      transition: opacity $general-transition-time $general-transition-ease;
      transition-delay: .5s;
      margin-bottom: 3rem;

      .Safari & {
        transform: translateX(1rem);
      }

      &.slick-slider {
        .slick-prev {
          display: none !important;
        }

        .slick-track {
          @extend %clearfix;

          display: flex;
          align-items: stretch;
        }

        .slick-slide {
          float: left;

          width: 240px;
          margin-right: 2rem;

          @include media-breakpoint-up(md) {
            width: 500px;
            margin-right: 3rem;
          }
          @include media-breakpoint-up(xl) {
            width: 600px;
            margin-right: 5rem;
          }

          & > div {
            height: 100%;
          }
        }
      }

      .carousel-item {
        height: 100%;
        position: relative;

        &-wrapper {
          height: 100%;

          @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
          }
        }

        .image {
          position: relative;

          @include media-breakpoint-up(md) {
            flex: 0 0 45%;
            width: 45%;
          }

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            height: 235px;
            @include media-breakpoint-up(md) {
              height: 264px;
            }
          }
        }

        .text {
          margin-bottom: 2rem;

          @include media-breakpoint-up(md) {
            width: 55%;
            flex: 0 0 55%;
            padding-left: 2rem;
            margin-bottom: 0;
          }

          h3 {
            @include font-style--alter($font-secondary, 24, 30, $weight--xBold, $black-2, none);
            margin-bottom: 1.5rem;

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-secondary, 22, 28, $weight--xBold, $black-2, none);
            }
          }

          p {
            @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey-7, none);
            margin-bottom: 2rem;

            display: none;
          }

          .link-bullet {
            @include font-style--alter($font-secondary, 18, 24, $weight--xBold, $color__grey-dark, none);
            text-transform: uppercase;
            padding: .5rem 0 .5rem 2.25rem;

            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 26px 26px;
          }
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn-carousel-right {
        @include font-style--alter($font-secondary, 14, 32, $weight--semiBold, $color__grey-dark, none);
        text-transform: uppercase;
        padding-right: 50px;
        position: relative;

        transition: padding-right $general-transition-time $general-transition-ease;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 35px;
          height: 31px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23333333' stroke='%23333333'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23333333'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23333333' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 35px 31px;

          transition: opacity $general-transition-time $general-transition-ease,
                      visibility $general-transition-time $general-transition-ease,
                      right $general-transition-time $general-transition-ease;
        }

        &:hover {
          &::after {
            opacity: 0;
            visibility: hidden;
            right: -24px;
          }
        }
      }
    }
  }

  &-subscribe {
    background: linear-gradient(90.24deg, rgba(23, 162, 184, 0.75) 43.33%, rgba(72, 197, 217, 0.75) 69.49%, rgba(23, 162, 184, 0.75) 90.58%);
    overflow: hidden;

    &-wrapper {
      z-index: 2;
      height: 100%;
      width: 100%;
      max-width: 1560px;
      margin: 0 auto;
      position: relative;
      padding: 0 1rem;

      @include media-breakpoint-down(md) {
        padding: 2rem 1rem;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include media-breakpoint-down(xxl) {
        max-width: 1160px;
      }
    }

    .content {
      @include media-breakpoint-up(lg) {
        flex: 0 0 60%;
        width: 60%;
      }

      .title {
        margin-bottom: 38px;

        h2 {
          @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
          text-transform: uppercase;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
          }

          strong {
            font-weight: $weight--bold;
          }
        }
      }

      .text {
        max-width: 560px;

        p {
          @include font-style--alter($font-primary, 22, 40, $weight--regular, $white, none);
          margin-bottom: 3.75rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-primary, 18, 36, $weight--regular, $white, none);
          }

          @include media-breakpoint-down(sm) {
            @include font-style--alter($font-primary, 18, 27, $weight--regular, $white, none);
            margin-bottom: 2rem;
          }
        }
      }

      .form {
        max-width: 560px;

        .wpcf7-list-item {
          margin: 0;
        }
        .wpcf7-not-valid-tip {
          padding-left: 30px;
        }
        .wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
          @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__red, none);
          border: 0;
          padding: 10px;
        }
        .wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
          @include font-style--alter($font-primary, 16, 24, $weight--regular, $white, none);
          border: 0;
          padding: 10px;
        }
        div.wpcf7-mail-sent-ok:before,div.wpcf7-validation-errors:before {
          display: none;
          font: 26px/30px dashicons;
          margin-right: 16px;
          vertical-align: middle;
        }
        div.wpcf7-mail-sent-ok:before {
          display: none;
          content: "\f147";
        }
        div.wpcf7-validation-errors:before {
          display: none;
          content: "\f158";
        }

        form {
          .form-items {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            .form-email {
              flex: 1 0 auto;
              padding-right: 18px;

              @include media-breakpoint-down(sm) {
                margin-bottom: 1.5rem;
                width: 100%;
                padding-right: 0;
              }

              input {
                width: 100%;
                appearance: none;
                background: transparent;
                border: 1px solid $white;
                border-radius: 8px;

                padding: 1rem 1.5rem;
                @include font-style--alter($font-primary, 16, 24, $weight--regular, $white, none);

                &::placeholder {
                  @include font-style--alter($font-primary, 16, 24, $weight--regular, $white, none);
                }
              }

              &.wpcf7-not-valid {
                border-color: $color__red;
              }

              .wpcf7-not-valid-tip {
                margin-top: 4px;
              }
            }

            .form-checkbox {
              flex: 0 0 100%;
              width: 100%;
              margin-bottom: 1.5rem;

              @include media-breakpoint-up(md) {
                order: 3;
                margin-top: 30px;
                margin-bottom: 0;
              }

              .wpcf7-list-item {
                margin: 0;
              }

              .wpcf7-form-control-wrap {
                display: block;

                .wpcf7-form-control {
                  display: block;
                }

                .wpcf7-not-valid {
                  input[type="checkbox"] {
                    & + span {
                      &::before {
                        border-color: $color__red;
                      }
                    }
                  }
                }
              }

              input[type="checkbox"] {
                display: none;

                & + span {
                  display: block;
                  padding-left: 28px;
                  position: relative;
                  @include font-style--alter($font-primary, 14, 17, $weight--light, $white, none);
                  cursor: pointer;

                  &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border: 1px solid $white;
                    border-radius: 2px;

                    transition: border-color $general-transition-time $general-transition-ease;
                  }

                  &::after {
                    content: "";
                    position: absolute;
                    left: 4px;
                    top: 8px;
                    height: .5rem;
                    width: .5rem;
                    background-color: $white;
                    border-radius: 2px;
                    opacity: 0;

                    transition: background-color $general-transition-time $general-transition-ease,
                      opacity $general-transition-time $general-transition-ease;
                  }
                }

                &:checked {
                  & + span {
                    &::before {
                      border-color: $white;
                    }
                    &::after {
                      opacity: 1;
                      background-color: $white;
                    }
                  }
                }
              }

              .wpcf7-not-valid-tip {
                padding-left: 44px;
              }
            }

            .form-submit {
              input {
                padding: 1rem 1.5rem 1rem 1.5rem;
                border-radius: 8px;
                border: 1px solid $white;
                background-color: transparent;
                @include font-style--alter($font-primary, 24, 24, $weight--bold, $white, none);
                text-transform: uppercase;

                transition: background-color $general-transition-time $general-transition-ease,
                color $general-transition-time $general-transition-ease;

                &:hover {
                  background-color: rgba($white, 0.6);
                  color: $black;
                }
              }
            }
          }
        }
      }
    }

    .image {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: right;
        z-index: -1;
        opacity: 0.25;
      }
      @include media-breakpoint-up(lg) {
        flex: 0 0 35%;
        width: 35%;
        text-align: center;
        margin-right: auto;
      }
      @include media-breakpoint-up(xxxl) {
        flex: 0 0 30%;
        width: 30%;
        margin-right: auto;
      }

      img {
        @include media-breakpoint-down(md) {
          width: auto;
          height: 100%;
          position: relative;
          transform: translateX(50%);
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          height: 100%;
          position: relative;
          transform: translateX(50%);
        }

        @include media-breakpoint-up(lg) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}