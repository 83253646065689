/*
  @file:
    _admin.scss

  @description:
    Admin styles related rules
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

#moove_gdpr_cookie_info_bar {
  max-width: 360px !important;
  left: auto !important;
  right: 1rem !important;
  bottom: 1rem !important;
  background-color: rgba($white,.90) !important;
  border-top: 0 !important;
  box-shadow: 0 0 40px rgba(0,0,0,.12);
  border-radius: 2px !important;

  &.moove-gdpr-info-bar-hidden {
    bottom: -400px !important;
  }

  .moove-gdpr-info-bar-container {
    padding: 24px !important;

    .moove-gdpr-info-bar-content {
      padding: 0 !important;
      flex-direction: column !important;
      align-items: flex-start !important;

      .moove-gdpr-cookie-notice {
        margin-bottom: .5rem;
      }

      .moove-gdpr-button-holder {
        padding-left: 0 !important;

        .mgbutton {
          margin: 0 !important;
          background-color: $color__blue !important;
        }
      }
    }
  }
}

.moove_gdpr_cookie_modal_open {
  .moove-gdpr-modal-close {
    .gdpr-icon {
      background-color: $color__blue !important;
      border-color: $color__blue !important;

      &:hover {
        background-color: $white !important;
      }
    }
  }

  .moove-gdpr-button-holder {
    button {
      background-color: $color__blue !important;
      border-color: $color__blue !important;

      &:hover {
        background-color: $white !important;
        color: $color__blue !important;
      }
    }
  }
}