/*
  @file:
    _animations.scss

  @description:
    SASS file that handles all the animations
    used in the project.

  @implementation:
    File is included in the main.scss file via the
    @include function
*/
@mixin animationBezier {
  transition: all .4s cubic-bezier(.47,.17,.17,.58);
}
