/*
  @file:
    _mixins.scss

  @description:
    SASS custom mixins used and shared amongst all the
    individual files/functions
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

/*
  @mixin:
    rem( $property, $values )

  @description
    A REM-PX mixin that converst pixels to proper rem values.
    It also includes a fallback for older browsers (IE8 cough...)

  @arguments:
    $property : the property you want to apply a value to
    $values   : the values you want to apply to $property

  @implementation:
    @include rem(font-size, 1.5rem);
    @include rem(padding, 20px 10px);

  @credits
    CodePen: http://codepen.io/HugoGiraudel/pen/xsKdH
    Article: http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/

*/

@mixin rem(
  $property, // Property that we want to use the mixin on
  $values // Values to convert and play with
) {

  // List
  $px : ();
  $rem: ();

  // Loop
  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: $value / ($value * 0 + 1); /* Strip unit */

      @if $unit == px {
        $px : append($px,  $value);
        $rem: append($rem, ($val / 16 * 1rem));
      }

      @if $unit == rem {
        $px : append($px,  ($val * 16 * 1px));
        $rem: append($rem, $value);
      }
    }
  }

  // Append content
  #{$property}: $px;
  #{$property}: $rem;
}

/*
  @mixin:
    em( $property, $values )

  @description
    A EM-PX mixin that converst pixels to proper em values.
    It also includes a fallback for older browsers (IE8 cough...)

  @arguments:
    $property : the property you want to apply a value to
    $values   : the values you want to apply to $property

  @implementation:
    @include em(font-size, 1.5em);
    @include em(padding, 20px 10px);

*/

@mixin em(
  $property, // Property that we want to use the mixin on
  $values // Values to convert and play with
) {

  // List
  $px : ();
  $em: ();

  // Loop
  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $em : append($em, $value);
    }

    @else {
      $unit: unit($value);
      $val : $value / ($value * 0 + 1); /* Strip unit */

      @if $unit == px {
        $px : append($px,  $value);
        $em : append($em, ($val / 16 * 1em));
      }

      @if $unit == em {
        $px : append($px,  ($val * 16 * 1px));
        $em : append($em, $value);
      }
    }
  }

  // Append content
  #{$property}: $px;
  #{$property}: $em;
}

/*
  @mixin:
    retina( $query, $file, $type, $width, $height )

  @description
    Pretty cool mixin to generate a background image for retina display

  @arguments:
    $query  : Media query to trigger retina image
    $file   : File that will be used
    $type   : Filetype
    $width  : Width to scale the original image
    $height : Height to scale the original image

  @implementation:
    @include retina('images/video', 'png', 50%, 50%);

*/

@mixin retina(
  $file,
  $type,
  $width,
  $height,
  $query: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)"
) {

  background-image: url($file + '.' + $type);

  @media #{$query} {

    background-image: url($file + '@2x.' + $type);
    background-size: $width, $height;
  
  }
}

@mixin svg($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-size: $width, $height;
  background-repeat: no-repeat;
}

/*
  @mixin:
    font-smoothing( $value )

  @description
    Pretty cool mixin for font smoothing

  @arguments:
    $value: Defines the algorythm for the smoothing

  @implementation:
    @include font-smoothing();
*/
@mixin font-smoothing($value: antialiased) {

  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }

}

/*
  @mixin:
    keyframes( $animation-name )

  @description
    Defines the keyframes for the animation

  @arguments:
    $animation-name: Animation name

  @implementation:
    @include keyframes(slide-down) {
        0% { opacity: 1; }
        90% { opacity: 0; }
    }

*/
@mixin keyframes($animation-name) {

  @-webkit-keyframes $animation-name {
    @content;
  }

  @-moz-keyframes $animation-name {
    @content;
  }

  @-ms-keyframes $animation-name {
    @content;
  }

  @-o-keyframes $animation-name {
    @content;
  }

  @keyframes $animation-name {
    @content;
  }

}

@mixin font-style($family: false, $size: false, $lh: false, $weight: false, $color: false, $decoration: false) {
  @if $family { font-family: $family; }
  @if $size { @include rfs($size); }
  @if $lh { line-height: $lh; }
  @if $weight { font-weight: $weight; }
  @if $color { color: $color; }
  @if $decoration { text-decoration: $decoration; }
}

@mixin font-style--alter($family: false, $size: false, $lh: false, $weight: false, $color: false, $decoration: false) {
  @if $family {font-family: $family; }
  @if $size { @include rfs($size); }
  @if $lh { line-height: ($lh / $size) }
  @if $weight { font-weight: $weight; }
  @if $color { color: $color; }
  @if $decoration { text-decoration: $decoration; }
}

@mixin slickDots($marginTop, $dot__width, $dot__activeWidth, $dot__height, $margin, $color__dotBg, $color__dotBgActive, $color__dotBorder) {
  
  .slick-dots {
    width: 100%;
    text-align: center;
    margin-top: $marginTop;
    
    li {
      display: inline-block;
      vertical-align: middle;
      margin: $margin;
      position: relative;
      width: $dot__width;
      height: $dot__height;
      
      @include animationBezier;
      
      &.slick-active {
        width: $dot__activeWidth;
        
        button {
          border-radius: rem(15px);
          width: $dot__activeWidth;
          height: $dot__height;
          background-color: $color__dotBgActive;
        }
      }
      
      button {
        width: $dot__width;
        height: $dot__height;
        display: block;
        border: 1px solid $color__dotBorder;
        background-color: $color__dotBg;
        border-radius: 50%;
        -webkit-appearance: none;
        outline: none;
        padding: 0;
        margin: 0;
        text-indent: -999em;
        cursor: pointer;
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        
        @include animationBezier;
      }
    }
  }
}

@mixin marginTop($marginTop) {
  margin-top: rem($marginTop * 1px);
  &:nth-child(1) {
    margin-top: 0;
  }
}

@mixin baseLinkHover($color, $baseSize, $distance) {
  box-shadow: inset 0 ($baseSize * 1px) 0 $color;
  @include animationBezier;
  &:hover {
    box-shadow: inset 0 ($distance * 1px) 0 rgba($color, 0.5);
  }
}

@mixin objectFit($ofWidth, $ofHeight, $ofStyle) {
  width: $ofWidth;
  height: $ofHeight;
  object-fit: $ofStyle;
  vertical-align: bottom;
}
