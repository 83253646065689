.cover-project {
  position: relative;

  .image {
    position: relative;
    margin-bottom: .25rem;
    overflow: hidden;
    height: 100vh;
    max-height: 1080px;
    min-height: 640px;

    .poster {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;

      transition: opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;

      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 99.24%);
        mix-blend-mode: multiply;
        opacity: 0.95;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img.mobile {
        display: none
      }
      
      @include media-breakpoint-down(sm) {

        img {
          display: none;
        }

        img.mobile {
          display: block;
        }

      }

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .btn-play {
      position: absolute;
      top: 50%;
      left: 75%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 5rem;
      z-index: 3;

      transition: opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;

      @include media-breakpoint-down(sm) {
        top: 65%;
        left: 50%;
      }
    }

    .video-overlay {
      position: fixed;
      z-index: 20;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($black-2, .8);

      opacity: 0;
      visibility: hidden;

      transition: opacity $general-transition-time $general-transition-ease,
                  visibility $general-transition-time $general-transition-ease;

      .overlay-video-active & {
        opacity: 1;
        visibility: visible;
      }

      .video-close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 5;

        width: 54px;
        height: 54px;

        span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          border: 3px solid $white;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 24px;
            height: 3px;
            background-color: $white;
            border-radius: 8px;
          }
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 24px;
            height: 3px;
            background-color: $white;
            border-radius: 8px;
          }
        }
      }

      &-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .iframe-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
      }

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1560px;
    padding: 0 1rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-down(sm) {
      top: 35%;
    }

    &-wrapper {
      max-width: 60%;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      h1 {
        @include font-style--alter($font-secondary, 80, 80, $weight--regular, $color__grey-light, none);
        text-transform: uppercase;
        margin-bottom: 3rem;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 42, 42, $weight--regular, $color__grey-light, none);
        }
      }

      p {
        @include font-style--alter($font-primary, 40, 38, $weight--medium, $white, none);

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-primary, 22, 22, $weight--medium, $white, none);
        }
      }
    }
  }

  .scroll-down {
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%) !important;

    display: inline-block;
    text-align: center;

    .icon {
      width: 31px;
      height: 34px;
      margin: 0 auto .5rem auto;
    }

    span {
      display: block;
      @include font-style--alter($font-primary, 14, 38, $weight--medium, $white, none);
      text-transform: uppercase;
    }

    svg {
      path {
        animation-name: arrowDown;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes arrowDown {
        0% {
          transform: translateY(0);
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        75% {
          opacity: .65;
        }
        100% {
          opacity: 0;
          transform: translateY(6px)
        }
      }
    }
  }
}

.page-project {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .content-quote {
    &::before {
      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%230095C6' fill-opacity='.3'/%3E%3C/svg%3E");

      .page-id-126 & {
        background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath opacity='.4' d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%239DAB47'/%3E%3C/svg%3E");
      }
    }

    p {
      .page-id-126 & {
        color: $color__green-light;
      }
    }
  }

  .content-offset,
  .text-container,
  .content-text-image {
    ul {
      li {
        &::before {
          .page-id-126 & {
            background-color: $color__green-light;
          }
        }
      }
    }
  }
}

.about-project {
  position: relative;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: radial-gradient(29.06% 41.09% at 70.94% 51.99%, rgba(157, 171, 71, 0.7482) 0%, rgba(157, 171, 71, 0.87) 100%);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    position: relative;
    z-index: 1;
    margin: 0 auto 2.5rem auto;
    padding: 3rem 1rem 3rem 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto 3.75rem auto;
      padding: 4rem 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 4rem 5rem;
    }
    @include media-breakpoint-up(xxxl) {
      margin: 0 auto 5.75rem auto;
      padding: 9rem 5rem 11rem 5rem;
    }

    h2 {
      @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
      text-transform: uppercase;
      margin-bottom: 2.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(xxxl) {
        margin-bottom: 7rem;
        @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
      }

      strong {
        font-weight: $weight--bold;
      }
    }

    .carousel-main {

    }

    .carousel-nav {
      width: 100%;
      max-width: 860px;
      margin: 0 auto 3rem auto;

      @include media-breakpoint-up(md) {
        margin: 0 auto 5rem auto;
      }
      @include media-breakpoint-up(xxxl) {
        margin: 0 auto 11rem auto;
      }

      @include media-breakpoint-down(lg) {
        padding: 0 3rem;
      }

      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $color__green;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: 0;
            }
          }
          &.slick-next {
            right: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: 0;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;

          display: flex;
          align-items: center;
          margin: 0 auto;
        }

        .slick-slide {
          float: left;
          text-align: center;
          cursor: pointer;

          &.slick-current {
            .element {
              color: $white;
              font-weight: $weight--xBold;
              // @include font-size(1.375rem);
            }
          }

          .element {
            @include font-style--alter($font-secondary, 18, 20, $weight--medium, rgba($white, .7), none);
            text-transform: uppercase;

            transition: font-size $general-transition-time $general-transition-ease,
                        font-weight $general-transition-time $general-transition-ease,
                        color $general-transition-time $general-transition-ease;
          }
        }
      }
    }

    .carousel-text {
      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $color__green;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: -4rem;
            }
          }
          &.slick-next {
            right: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: -4rem;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;
        }

        .slick-slide {
          float: left;
        }
      }

      .element {
        h3 {
          @include font-style--alter($font-secondary, 32, 32, $weight--bold, $white, none);
          margin-bottom: 2rem;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-secondary, 40, 40, $weight--bold, $white, none);
            margin-bottom: 3.25rem;
          }
        }

        .text {

          @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          p {
            @include font-style--alter($font-primary, 18, 32, $weight--regular, $white, none);
            margin-bottom: 1.5rem;

            @include media-breakpoint-up(md) {
              flex: 0 0 50%;
              width: 50%;
              margin-bottom: 0;
            }

            @include media-breakpoint-up(xxxl) {
              @include font-style--alter($font-primary, 22, 38, $weight--regular, $white, none);
            }

            &:nth-child(odd) {
              @include media-breakpoint-up(md) {
                padding-right: 2.25rem;
              }
            }
            &:nth-child(even) {
              @include media-breakpoint-up(md) {
                padding-left: 2.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.about-project-book {
  position: relative;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(23, 162, 184, 0.59) 0.9%, rgba(23, 162, 184, 0.59) 57.97%, rgba(23, 162, 184, 0.59) 100%);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    position: relative;
    z-index: 1;
    margin: 0 auto 2.5rem auto;
    padding: 3rem 1rem 0 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 4rem 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 4rem 5rem;
    }
    @include media-breakpoint-up(xxxl) {
      margin: 0 auto;
      padding: 9rem 5rem 11rem 5rem;
    }

    h2 {
      @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
      text-transform: uppercase;
      margin-bottom: 2.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(xxxl) {
        margin-bottom: 7rem;
        @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
      }

      strong {
        font-weight: $weight--bold;
      }
    }

    .carousel-main {

    }

    .carousel-nav {
      width: 100%;
      max-width: 860px;
      margin: 0 auto 3rem auto;

      @include media-breakpoint-up(md) {
        margin: 0 auto 5rem auto;
      }
      @include media-breakpoint-up(xxxl) {
        margin: 0 auto 11rem auto;
      }

      @include media-breakpoint-down(lg) {
        padding: 0 3rem;
      }

      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $white;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: 0;
            }
          }
          &.slick-next {
            right: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: 0;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;

          display: flex;
          align-items: center;
          margin: 0 auto;
        }

        .slick-slide {
          float: left;
          text-align: center;
          cursor: pointer;

          &.slick-current {
            .element {
              color: $white;
              font-weight: $weight--xBold;
              @include font-size(1.375rem);
            }
          }

          .element {
            @include font-style--alter($font-secondary, 18, 20, $weight--medium, rgba($white, .7), none);
            text-transform: uppercase;

            transition: font-size $general-transition-time $general-transition-ease,
            font-weight $general-transition-time $general-transition-ease,
            color $general-transition-time $general-transition-ease;
          }
        }
      }
    }

    .carousel-text {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;

      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $white;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: -4rem;
            }
          }
          &.slick-next {
            right: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: -4rem;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;
        }

        .slick-slide {
          float: left;
        }
      }

      .element {
        h3 {
          @include font-style--alter($font-secondary, 32, 32, $weight--bold, $white, none);
          margin-bottom: 2rem;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-secondary, 40, 40, $weight--bold, $white, none);
            margin-bottom: 3.25rem;
          }
        }

        .text {

          @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }


        }

        p {
          @include font-style--alter($font-primary, 18, 32, $weight--regular, $white, none);
          margin-bottom: 1.5rem;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-primary, 22, 38, $weight--regular, $white, none);
          }
        }
      }
    }
  }
}

.section-aim {
  background-color: $color__green-light;
  overflow: hidden;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  @include media-breakpoint-up(xxxl) {
    padding-top: 10rem;
    padding-bottom: 4rem;
  }

  &:last-child {
    margin-bottom: -2.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: -4rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: -7rem;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    margin: 0 auto;
    padding: 0 1rem;
  }

  .section-top {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 4rem;
    }
    @include media-breakpoint-up(xxxl) {
      margin-bottom: 8rem;
    }

    h2 {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        flex: 0 0 50%;
        width: 50%;
        padding-right: 3.5rem;
      }
      @include media-breakpoint-up(lg) {
        padding-right: 5rem;
      }
      @include media-breakpoint-up(xl) {
        padding-right: 7rem;
      }

      @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
      text-transform: uppercase;

      @include media-breakpoint-up(xxxl) {
        @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
      }

      strong {
        font-weight: $weight--bold;
      }
    }

    p {
      @include font-style--alter($font-primary, 22, 36, $weight--medium, $white, none);

      @include media-breakpoint-down(xxl) {
        @include font-style--alter($font-primary, 18, 32, $weight--medium, $white, none);
      }

      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        padding-right: 3rem;
      }
      @include media-breakpoint-up(xxxl) {
        padding-right: 5rem;
        @include font-style--alter($font-primary, 24, 40, $weight--medium, $white, none);
      }
    }
  }

  .section-bottom {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: -999em;
      right: 100%;
      top: 0;
      bottom: 0;
      background: $color__green-light;
    }
    .carousel {
      margin-bottom: 3.75rem;

      &.slick-slider {
        .slick-prev {
          display: none !important;
        }

        .slick-track {
          @extend %clearfix;
        }

        .slick-slide {
          float: left;
          margin-right: 4rem;
          width: 280px;

          @include media-breakpoint-down(sm) {
            margin-right: 1rem;
          }
        }
      }

      .element {
        .image {
          width: 10rem;
          height: 10rem;
          overflow: hidden;
          border-radius: 50%;
          position: relative;
          margin-bottom: 2.5rem;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .text {
          padding-right: 4rem;

          p {
            @include font-style--alter($font-primary, 18, 22, $weight--bold, $white, none);

            @include media-breakpoint-up(xxxl) {
              @include font-style--alter($font-primary, 24, 28, $weight--bold, $white, none);
            }
          }
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn-carousel-right {
        @include font-style--alter($font-secondary, 14, 32, $weight--semiBold, $white, none);
        text-transform: uppercase;
        padding-right: 50px;
        position: relative;
        transition: padding-right $general-transition-time $general-transition-ease;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 35px;
          height: 31px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 35px 31px;

          transition: opacity $general-transition-time $general-transition-ease,
          visibility $general-transition-time $general-transition-ease,
          right $general-transition-time $general-transition-ease;
        }

        &:hover {
          &::after {
            opacity: 0;
            visibility: hidden;
            right: -24px;
          }
        }
      }
    }
  }
}