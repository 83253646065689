.multimedia-block {
  background-color: $color__grey-2;
  margin-bottom: 3.5rem;
  padding: 2.75rem 0;

  @include media-breakpoint-up(md) {
    padding: 3.75rem 0;
    margin-bottom: 4.5rem;
  }
  @include media-breakpoint-up(xxxl) {
    padding: 5.75rem 0;
    margin-bottom: 7.5rem;
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 1rem;

    @include media-breakpoint-up(md) {
      padding: 0 5rem;
    }
    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
  }

  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    @include media-breakpoint-up(xxxl) {
      margin-bottom: 5.5rem;
    }

    h2 {
      @include font-style--alter($font-secondary, 60, 48, $weight--bold, $black-2, none);
      margin-bottom: 0;

      @include media-breakpoint-down(xxl) {
        @include font-style--alter($font-secondary, 42, 42, $weight--bold, $black-2, none);
      }
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        margin-bottom: 1rem;
      }
    }

    .link {
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        text-align: right;
      }

      a {
        @include font-style--alter($font-secondary, 14, 32, $weight--semiBold, $black, none);
        text-transform: uppercase;
        padding-right: 50px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 35px;
          height: 31px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23000' stroke='%23000'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23000'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23000' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 35px 31px;

          transition: opacity $general-transition-time $general-transition-ease,
          visibility $general-transition-time $general-transition-ease,
          right $general-transition-time $general-transition-ease;
        }

        &:hover {
          &::after {
            opacity: 0;
            visibility: hidden;
            right: -24px;
          }
        }
      }
    }
  }

  &-carousel {
    & > .row {
      margin-left: -2.5rem;
      margin-right: -2.5rem;

      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        margin-left: -1rem;
        margin-right: -1rem;
      }
    }

    &-wrapper {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .slick-slider {
      position: relative;

      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;

        text-indent: -999em;
        background-color: $color__grey-6;

        @extend .btn;
        @extend .btn-plain;

        &.slick-prev {
          left: -4.5rem;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 9px 16px;
        }
        &.slick-next {
          right: -4.5rem;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 9px 16px;
        }
      }

      .slick-list {
        overflow: hidden;
      }

      .slick-track {
        @extend %clearfix;
      }

      .slick-slide {
        float: left;
      }
    }

    .carousel-item {
      @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
        margin-right: 1rem;
      }

      & > .row {
        margin-left: -2.5rem;
        margin-right: -2.5rem;

        @include media-breakpoint-down(md) {
          margin-left: -1rem;
          margin-right: -1rem;
          flex-wrap: nowrap;
        }
      }
    }

    .carousel-col {
      @include media-breakpoint-up(lg) {
        padding: 0 2.5rem;
      }

      &.col-md-8 {
        @include media-breakpoint-down(sm) {
          width: 300px;
          flex: 0 0 300px;
          margin-left: 0;
          margin-right: -1rem;
        }

        .block-single {
          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }
      }
      &.col-md-4 {
        @include media-breakpoint-down(sm) {
          width: 60%;
          flex: 0 0 60%;
          min-width: 520px;

          display: flex;
          flex-wrap: nowrap;
        }

        .block-single {
          @include media-breakpoint-down(sm) {
            width: 300px;
            flex: 0 0 300px;
          }
        }
      }
    }

    .block-single {
      margin-bottom: 2.5rem;

      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
        margin-bottom: 0;
      }

      .media {
        position: relative;
        margin-bottom: .25rem;
        overflow: hidden;

        .video {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;

          &.playerActive {
            .btn-play {
              opacity: 0;
              visibility: hidden;
            }

            .poster {
              opacity: 0;
              visibility: hidden;
            }
          }
        }

        .poster {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;

          transition: opacity $general-transition-time $general-transition-ease,
                      visibility $general-transition-time $general-transition-ease;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .btn-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 5rem;
          height: 5rem;
          z-index: 3;

          transition: opacity $general-transition-time $general-transition-ease,
          visibility $general-transition-time $general-transition-ease;
        }

        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }

      .text {
        .date {
          display: block;
          @include font-style--alter($font-secondary, 16, 34, $weight--light, $black-2, none);
          margin-bottom: .25rem;
        }

        h3 {
          @include font-style--alter($font-secondary, 24, 34, $weight--bold, $black-2, none);

          @include media-breakpoint-down(xxl) {
            @include font-size(1.125rem);
          }
        }
      }
    }
  }
}