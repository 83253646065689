/*
  @file:
    _home.scss

  @description:
    Example of 'homepage' specific styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
.home-slides {
  #fullpage-menu {
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    width: 160px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1.5rem;
    transition: transform $general-transition-time $general-transition-ease;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    //.fp-viewing-section5 & {
    .fp-viewing-section6 & {
      transform: translateX(-100%);
    }

    a {
      @include font-style--alter($font-primary, 14, 14, $weight--medium, $color__grey-light, none);
      text-transform: uppercase;
      margin-bottom: .75rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        font-weight: $weight--xBold;
      }
    }
  }

  .section {
    position: relative;

    @include media-breakpoint-down(sm) {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(30, 30, 30, 0) 9.92%, #1E1E1E 73.53%);
        opacity: 0.6;
      }
    }

    .fp-scroller {
      //@include media-breakpoint-down(sm) {
      //  height: 100%;
      //}
    }

    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @include media-breakpoint-down(sm) {
        z-index: -1;
      }

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        position: relative;
        height: 100%;
        width: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img.mobile {
        display:none;
      }

      @include media-breakpoint-down(sm) {

        img {
          display: none;
        }

        img.mobile {
          display: block;
        }

      }
    }

    .next-slide {
      position: absolute;
      z-index: 2;
      bottom: 0;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%) !important;

      display: inline-block;
      text-align: center;

      // opacity: 0;
      // visibility: hidden;
      transition: bottom $general-transition-time $general-transition-ease,
      opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;

      .fp-viewing-section1 & {
        opacity: 1;
        visibility: visible;
        bottom: 3rem;
      }

      .icon {
        width: 31px;
        height: 34px;
        margin: 0 auto .5rem auto;
      }

      span {
        display: block;
        @include font-style--alter($font-primary, 14, 38, $weight--medium, $white, none);
        text-transform: uppercase;

        svg {
          path {
            animation-name: arrowDown;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }

          @keyframes arrowDown {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            10% {
              opacity: 1;
            }
            75% {
              opacity: .65;
            }
            100% {
              opacity: 0;
              transform: translateY(6px)
            }
          }
        }
      }
    }

    &-cover {
      .background {
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(30, 30, 30, 0) 9.92%, #1E1E1E 73.53%);
          opacity: 0.6;
        }
        img.mobile {
          display: none
        }
        
        @include media-breakpoint-down(sm) {
  
          img {
            display: none;
          }
  
          img.mobile {
            display: block;
          }
        }

        video {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      .content {
        position: absolute;
        z-index: 2;
        top: 65%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1080px;
        padding: 2rem 1rem;

        text-align: center;

        // opacity: 0;
        // visibility: hidden;
        transition: top $general-transition-time $general-transition-ease,
                opacity $general-transition-time $general-transition-ease,
                    visibility $general-transition-time $general-transition-ease;

        .fp-viewing-section1 & {
          opacity: 1;
          visibility: visible;
          top: 50%;
        }

        h1 {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
          text-transform: uppercase;
          margin-bottom: 2.75rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 2rem;
          }

          strong {
            font-weight: $weight--bold;
          }
        }

        p {
          @include font-style--alter($font-primary, 32, 38, $weight--regular, $color__grey-light, none);
          max-width: 520px;
          margin: 0 auto;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-primary, 18, 28, $weight--regular, $color__grey-light, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.25rem;
          }
        }
      }
    }

    &-vision {
      overflow: hidden;

      .fp-scroller {
        @include media-breakpoint-down(md) {
          height: 100%;
        }
        @include media-breakpoint-down(sm) {
          min-height: 600px;
        }
      }
      .background {
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(0deg, #142D18 9.63%, rgba(87, 87, 87, 0) 93.24%);
          background-blend-mode: multiply;
          opacity: 0.6;
        }
      }

      .content {
        padding: 2rem 1rem;

        @include media-breakpoint-down(sm) {
          height: 100%;
          position: relative;
          padding-top: 7rem;
        }

        @include media-breakpoint-up(md) {
          margin-top: 0;
          position: absolute;
          z-index: 2;
          top: 50%;
          // opacity: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1560px;

          transition: top $general-transition-time $general-transition-ease,
                      opacity $general-transition-time $general-transition-ease;
          transition-delay: .5s;

          .fp-viewing-section2 & {
            top: 50%;
            opacity: 1;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 1160px;
          }
          padding: 2rem 1rem 2rem 140px;
        }

        &-wrapper {
          @include media-breakpoint-down(sm) {
            height: 100%;
          }
        }

        .title-row {
          display: flex;
          margin-bottom: 1rem;
          position: static;

          @include media-breakpoint-up(md) {
            position: relative;
          }
          @include media-breakpoint-up(xl) {
            margin-bottom: 2rem;
          }

          &:first-child {
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
              margin-bottom: 2rem;
            }
            @include media-breakpoint-up(xl) {
              margin-bottom: 3rem;
            }
          }

          &.active {
            z-index: 2;

            .title {

              h2 {
                &::before {
                  @include media-breakpoint-down(sm) {
                    bottom: calc(-100vh + 22rem);
                  }
                }
                &::after {
                  right: 0;

                  @include media-breakpoint-up(xl) {
                    right: -50%;
                  }
                }
              }
            }

            .text {
              transform: translateY(8px) translateX(-20px);
              opacity: 1;
              visibility: visible;

              &::before {
                bottom: 4rem;
                opacity: 1;
              }
            }
          }

          .title {
            cursor: pointer;
            flex: 0 0 100%;
            width: 100%;

            @include media-breakpoint-up(md) {
              flex: 0 0 40%;
              width: 40%;
            }
            @include media-breakpoint-up(lg) {
              flex: 0 0 50%;
              width: 50%;
            }
            @include media-breakpoint-up(xxl) {
              flex: 0 0 30%;
              width: 30%;
            }
            @include media-breakpoint-up(xxxl) {
              flex: 0 0 40%;
              width: 40%;
            }

            h2 {
              @include font-style--alter($font-secondary, 24, 24, $weight--semiBold, $color__grey-light, none);
              text-transform: uppercase;
              margin-bottom: 0;
              padding-bottom: 1rem;
              position: relative;

              @include media-breakpoint-down(xxl) {
                @include font-style--alter($font-secondary, 22, 25, $weight--semiBold, $color__grey-light, none);
              }
              @include media-breakpoint-down(sm) {
                font-size: 1.25rem;
              }

              &::before {
                @include media-breakpoint-down(sm) {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  //bottom: calc(7rem - 100vh);
                  bottom: 100%;
                  width: 2px;
                  background-color: $white;

                  opacity: 1;

                  transition: bottom $general-transition-time $general-transition-ease,
                  opacity $general-transition-time $general-transition-ease;
                }
              }

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 100%;
                height: 2px;
                background-color: $white;

                transition: right $general-transition-time $general-transition-ease;
              }

              &.big {
                @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
                padding-bottom: 1rem;

                @include media-breakpoint-down(xxl) {
                  @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
                }
                @include media-breakpoint-down(sm) {
                  font-size: 2.5rem;
                }
                @include media-breakpoint-up(md) {
                  padding-bottom: 1.75rem;
                }
              }

              strong {
                font-weight: $weight--bold;
              }
            }
          }

          .text {
            position: absolute;
            right: 0;
            top: 100%;
            transform: translateY(120px) translateX(-20px);
            padding-left: 1rem;

            opacity: 0;
            visibility: hidden;

            transition: visibility $general-transition-time $general-transition-ease,
            opacity $general-transition-time $general-transition-ease,
            transform $general-transition-time $general-transition-ease;

            flex: 0 0 60%;
            width: 60%;

            @include media-breakpoint-up(lg) {
              flex: 0 0 50%;
              width: 50%;
              left: 35%;
            }
            @include media-breakpoint-up(xxl) {
              flex: 0 0 40%;
              width: 40%;
              right: auto;
              left: 40%;
            }
            @include media-breakpoint-up(xxxl) {
              flex: 0 0 30%;
              width: 30%;
              right: auto;
              left: 50%;
            }

            @include media-breakpoint-down(sm) {
              z-index: 3;
              top: auto;
              bottom: 2rem;
              padding-left: 0;
              padding-right: 1rem;
              flex: 0 0 80%;
              width: 80%;
              text-align: right;
            }

            &::before {
              content: "";
              position: absolute;
              top: -2rem;
              left: 0;
              bottom: 100%;
              width: 2px;
              background-color: $white;

              opacity: 0;

              transition: bottom $general-transition-time $general-transition-ease,
                          opacity $general-transition-time $general-transition-ease;

              @include media-breakpoint-down(sm) {
                display: none;
              }
            }

            p {
              @include font-style--alter($font-primary, 22, 29, $weight--regular, $color__grey-light, none);
              margin-bottom: 2.5rem;

              @include media-breakpoint-down(xxl) {
                @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey-light, none);
              }
              @include media-breakpoint-down(sm) {
                font-size: 1rem;
              }
            }

            .link-bullet {
              @include font-style--alter($font-secondary, 16, 16, $weight--xBold, $color__grey-light, none);
              text-transform: uppercase;
              padding: .5rem 0 .5rem 2.25rem;

              background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: left center;
              background-size: 26px 26px;
            }
          }
        }
      }
    }

    &-projects {
      background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);

      &::before {
        display: none;
      }

      .fp-scroller {
        @include media-breakpoint-down(md) {
          height: 100%;
        }
        @include media-breakpoint-down(sm) {
          min-height: 780px;
        }
      }

      &.active {
        .content {
          .carousel {
            opacity: 1;
          }
        }
      }
      .background {
        //height: 100vh;
        //min-height: 1080px;
        @include media-breakpoint-up(md) {
          height: 100vh;
          min-height: 700px;
        }
        @include media-breakpoint-up(xxxl) {
          min-height: 960px;
        }

        &::before {
          display: none;
        }

        &.background-color {
          background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
          padding: 104px 1rem 2rem 1rem;
          height: 100%;
          position: relative;
          z-index: 2;
          display: block;
          padding-top: 50px;
          padding-bottom: 50px;
        }

        @include media-breakpoint-up(md) {
          position: absolute;
          z-index: 2;
          //top: 50%;
          //transform: translate(-50%, -50%);
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          max-width: 1160px;
          padding: 104px 1rem 4rem 140px;
        }

        @include media-breakpoint-up(xxxl) {
          max-width: 1560px;
        }

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          left: -999em;
          right: calc(100% - 140px);
          top: 0;
          bottom: 0;
          background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        h2 {
          @include font-style--alter($font-secondary, 66, 66, $weight--light, $color__grey-light, none);
          margin-bottom: 4rem;
          text-transform: uppercase;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 40, 42, $weight--light, $color__grey-light, none);
            margin-bottom: 2rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
          }

          @include media-breakpoint-up(md) {
            transform: translateY(100%);
            opacity: 0;

            transition: transform $general-transition-time $general-transition-ease,
                        opacity $general-transition-time $general-transition-ease;

            .fp-viewing-section3 & {
              transform: translateY(0%);
              opacity: 1;
            }
          }


          strong {
            font-weight: $weight--bold;
          }
        }
      }

      .carousel {
        transition: opacity $general-transition-time $general-transition-ease;
        transition-delay: .5s;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
          display: flex;
          align-items: stretch;
          margin: 0 -1.5rem 3rem -1.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          display: flex;
          align-items: stretch;
          margin: 0 -3rem 3rem -3rem;
        }

        .Safari & {
          transform: translateX(1rem);
        }

        &.slick-slider {
          .slick-prev {
            display: none !important;
          }

          .slick-track {
            @extend %clearfix;

            display: flex;
            align-items: stretch;
          }

          .slick-slide {
            float: left;
            width: 160px;
            margin-right: 4rem;

            @include media-breakpoint-up(md) {
              padding: 0 1.5rem;
              margin-right: 0;
              width: auto;
            }

            @include media-breakpoint-up(xxxl) {
              //width: 250px;
              padding: 0 3.5rem;
            }

            & > div {
              height: 100%;
            }

            &.slick-current {
              .carousel-item {
                .title {
                  opacity: 1;
                }
                .image {
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .text {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .carousel-item {
        height: 100%;
        position: relative;
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }

        &-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .title {
          margin-bottom: 10px;
          flex: 1 0 auto;

          display: flex;
          flex-direction: column;
          // justify-content: flex-end;
          justify-content: flex-start;

          opacity: .7;

          transition: opacity $general-transition-time $general-transition-ease;

          h3 {
            @include font-style--alter($font-secondary, 20, 22, $weight--xBold, $color__grey-light, none);
            text-transform: uppercase;
            margin-bottom: 0;

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-secondary, 16, 16, $weight--xBold, $color__grey-light, none);
            }
          }
        }

        .image {
          flex: 0 0 auto;
          // height: 200px;
          width: 100%;
          position: relative;
          margin-bottom: 10px;

          // @include media-breakpoint-up(xl) {
          //   height: 220px;
          // }
          // @include media-breakpoint-up(xxxl) {
          //   height: 230px;
          // }

          img {
            position: absolute;
            //width: 160px;
            //height: 200px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            transition: width $general-transition-time $general-transition-ease,
            height $general-transition-time $general-transition-ease;

            //@include media-breakpoint-up(xl) {
            //  height: 280px;
            //  width: 220px;
            //}
            //@include media-breakpoint-up(xxl) {
            //  width: 250px;
            //  height: 320px;
            //}
            //@include media-breakpoint-up(xxxl) {
            //  width: 100%;
            //}
          }
        }

        .text {
          flex: 0 0 auto;
          opacity: .7;
          transition: opacity $general-transition-time $general-transition-ease;

          p {
            @include font-style--alter($font-primary, 17, 27, $weight--regular, $color__grey-light, none);
            margin-bottom: 0;

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-primary, 14, 18, $weight--regular, $color__grey-light, none);
            }
            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }
        }
        .visit {
          @include font-style--alter($font-secondary, 14, 14, $weight--black, $white, none);
          text-transform: uppercase;
          margin-top: 2rem;

          @include media-breakpoint-down(sm) {
            flex: 0 0 auto;
            width: auto;
            order: 2;
            margin-top: 20px;
          }
        }
      }      

      .links {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          padding-bottom: 2rem;
        }
        @include media-breakpoint-up(xxl) {
          padding-left: .5rem;
          padding-right: .5rem;
        }

        .visit {
          @include font-style--alter($font-secondary, 14, 14, $weight--black, $white, none);
          text-transform: uppercase;

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            width: 100%;
            order: 2;
          }
        }

        .btn-carousel-right {
          @include font-style--alter($font-secondary, 14, 32, $weight--semiBold, $color__grey-2, none);
          text-transform: uppercase;
          padding-right: 50px;
          position: relative;

          transition: padding-right $general-transition-time $general-transition-ease;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 35px;
            height: 31px;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 35px 31px;

            transition: opacity $general-transition-time $general-transition-ease,
            visibility $general-transition-time $general-transition-ease,
            right $general-transition-time $general-transition-ease;
          }

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 1.5rem;
            text-align: right;
          }

          //@include media-breakpoint-up(md) {
          //  display: none !important;
          //}

          &:hover {
            &::after {
              opacity: 0;
              visibility: hidden;
              right: -24px;
            }
          }
        }
      }
    }

    &-book {
      .fp-scroller {
        @include media-breakpoint-down(md) {
          height: 100%;
        }
      }

      .background {
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(270deg, rgba(58, 58, 58, 0) 8.63%, rgba(58, 58, 58, 0.52) 50.57%);
          background-blend-mode: multiply;
        }
      }

      .content {
        @include media-breakpoint-down(sm) {
          padding: 2rem 1rem 2rem 1rem;
          margin-top: 5rem;
          position: relative;
          z-index: 2;
        }
        @include media-breakpoint-up(md) {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1160px;
          opacity: 0;

          padding: 2rem 1rem 0 140px;
          margin-top: 2.5rem;

          transition: opacity $general-transition-time $general-transition-ease;
          transition-delay: .5s;

          .fp-viewing-section4 & {
            opacity: 1;
          }
        }

        @include media-breakpoint-up(xxxl) {
          max-width: 1560px;
        }

        .content-top {
          max-width: 580px;
          margin-bottom: 3rem;

          @include media-breakpoint-up(xxl) {
            margin-bottom: 5rem;
          }

          h2 {
            @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
            text-transform: uppercase;
            margin-bottom: 2.75rem;

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
              margin-bottom: 1.25rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 2.5rem;
            }

            strong {
              font-weight: $weight--bold;
            }
          }

          .text {
            margin-bottom: 2rem;
          }

          p {
            @include font-style--alter($font-primary, 22, 28, $weight--regular, $color__grey-light, none);

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-primary, 18, 24, $weight--regular, $color__grey-light, none);
            }
            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .links {
            display: flex;
            align-items: center;
          }

          .link-bullet {
            margin-left: 2.75rem;

            @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-light, none);
            text-transform: uppercase;
            padding: .5rem 0 .5rem 2.25rem;

            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 26px 26px;

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .content-bottom {
          width: 100%;
          max-width: 1160px;
          display: flex;
          align-items: flex-start;

          @include media-breakpoint-down(sm) {
            display: none;
          }

          .content-col {
            flex: 0 0 60%;
            width: 60%;

            border-left: 1px solid $white;

            &.active {
              p {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
              }
            }

            &:first-child {
              flex: 0 0 40%;
              width: 40%;
              border-left: 0;

              h3,
              p {
                padding-left: 0;
              }
            }

            h3 {
              @include font-style--alter($font-secondary, 40, 40, $weight--semiBold, $white, none);
              text-transform: uppercase;
              border-bottom: 1px solid $white;

              padding: 0 2rem 1.25rem 2rem;
              cursor: pointer;

              @include media-breakpoint-down(xxl) {
                @include font-style--alter($font-secondary, 32, 32, $weight--semiBold, $white, none);
              }
            }

            p {
              @include font-style--alter($font-primary, 22, 28, $weight--regular, $color__grey-light, none);
              padding: 1rem 2rem 1.25rem 2rem;

              transform: translateY(100px);
              opacity: 0;
              visibility: hidden;
              transition: opacity $general-transition-time $general-transition-ease,
                          visibility $general-transition-time $general-transition-ease,
                          transform $general-transition-time $general-transition-ease;

              @include media-breakpoint-down(xxl) {
                @include font-style--alter($font-primary, 16, 22, $weight--regular, $color__grey-light, none);
              }

              &:last-child {
                margin-bottom: 0;
              }

              strong {
                font-weight: $weight--bold;
              }
            }
          }
        }
      }
    }

    &-approach , &-vision , &-knowledge {
      .fp-scroller {
        @include media-breakpoint-down(md) {
          height: 100%;
        }
        @include media-breakpoint-down(sm) {
          min-height: 700px;
        }
      }

      &.active {
        .content {
          opacity: 1;
          .carousel {
            opacity: 1;
          }
        }
      }


      .background {
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(58, 58, 58, 0) 6.87%, rgba(29, 29, 29, 0.52) 50.72%);
          background-blend-mode: multiply;
        }
      }

      .content {
        @include media-breakpoint-down(sm) {
          position: relative;
          z-index: 2;
          padding: 2rem 1rem 2rem 1rem;
          margin-top: 5rem;
          height: 100%;
        }
        @include media-breakpoint-up(md) {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1160px;
          // opacity: 0;

          padding: 2rem 1rem 2rem 140px;
          margin-top: 5rem;

          transition: opacity $general-transition-time $general-transition-ease;
          transition-delay: .5s;

          .fp-viewing-section5 & {
          //.fp-viewing-section4 & {
            opacity: 1;
          }
        }

        @include media-breakpoint-up(xxxl) {
          max-width: 1560px;
        }

        h2 {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
          text-transform: uppercase;
          margin-bottom: 1.625rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 3rem;
          }
          @include media-breakpoint-up(xxxl) {
            margin-bottom: 6.5rem;
          }

          strong {
            font-weight: $weight--bold;
          }
        }

        .text-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
          }
        }

        .text {
          flex: 0 0 100%;
          width: 100%;
          margin-bottom: 5rem;

          @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            width: 50%;
            margin-bottom: 2.75rem;
            padding-right: 6rem;
          }
        }

        h3 {
          @include font-style--alter($font-secondary, 40, 40, $weight--bold, $white, none);
          text-transform: uppercase;
          margin-bottom: 1.5rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 32, 32, $weight--bold, $white, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.375rem;
          }
        }

        p {
          @include font-style--alter($font-primary, 22, 29, $weight--regular, $color__grey-light, none);
          margin-bottom: 1rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 2.75rem;
          }

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-primary, 18, 25, $weight--regular, $color__grey-light, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
        }

        .link-bullet {
          @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-light, none);
          text-transform: uppercase;
          padding: .5rem 0 .5rem 2.25rem;

          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 26px 26px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    &-knowledge {
      &::after {
        @include media-breakpoint-down(sm) {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 300px;
          background-color: $color__grey-3;
        }
      }

      .fp-tableCell {
        @include media-breakpoint-down(sm) {
          position: relative;
          z-index: 1;
        }
      }
      .fp-scrollable {
        touch-action: none;
      }

      .sectionScrollable {
        @include media-breakpoint-down(sm) {
          //height: 100%;
          //overflow: auto;
        }
      }

      .section-wrapper {
        position: relative;
        height: 100vh;

        @include media-breakpoint-down(sm) {
          height: auto;
        }
      }

      .background {
        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(58, 58, 58, 0) 0%, rgba(58, 58, 58, 0.42) 38.14%);
          background-blend-mode: multiply;
        }
      }

      .content {
        @include media-breakpoint-down(sm) {
          position: relative;
          z-index: 2;
          height: 100%;
          padding: 7rem 1rem 2rem 1rem;
        }
        @include media-breakpoint-up(md) {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1160px;

          padding: 2rem 1rem 2rem 140px;
          margin-top: 3rem;

          // opacity: 0;

          transition: opacity $general-transition-time $general-transition-ease;
          transition-delay: .5s;

          .fp-viewing-section6 & {
          //.fp-viewing-section5 & {
            opacity: 1;
          }
        }

        @include media-breakpoint-up(xxxl) {
          max-width: 1560px;
        }

        h2 {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $color__grey-light, none);
          text-transform: uppercase;
          margin-bottom: 2.5rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 42, 42, $weight--light, $color__grey-light, none);
            margin-bottom: 1.25rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
          }

          strong {
            font-weight: $weight--bold;
          }
        }

        .text-intro {
          max-width: 40rem;
          margin-bottom: 8rem;

          @include media-breakpoint-down(xxl) {
            margin-bottom: 4rem;
          }

          p {
            @include font-style--alter($font-primary, 22, 27, $weight--regular, $color__grey-light, none);
            margin-bottom: 2rem;

            @include media-breakpoint-down(xxl) {
              @include font-style--alter($font-primary, 18, 23, $weight--regular, $color__grey-light, none);
            }
            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }
          }

          a {
            opacity: 1;
          }
        }

        .text-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          margin: 0 -1.25rem;

          &.slick-slider {
            .slick-list {
              @include media-breakpoint-up(md) {
                overflow: hidden;
              }
            }

            .slick-track {
              @extend %clearfix;
            }

            .slick-slide {
              float: left;

            }
          }
        }

        .text {
          flex: 0 0 25%;
          width: 25%;
          padding: 1.25rem;
          position: relative;

          @include media-breakpoint-down(sm) {
            flex: 0 0 auto;
            width: auto;
            max-width: 290px;
          }

          &:hover {
            a {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }

        h3 {
          @include font-style--alter($font-secondary, 22, 30, $weight--bold, $white, none);
          margin-bottom: 1.25rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 18, 26, $weight--bold, $white, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
          }
        }

        p {
          @include font-style--alter($font-primary, 18, 24, $weight--regular, $color__grey-light, none);

          margin-bottom: 2rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-primary, 14, 20, $weight--regular, $color__grey-light, none);
          }
          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
        }

        .link-bullet {
          @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-light, none);
          text-transform: uppercase;
          padding: .5rem 0 .5rem 2.25rem;

          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 26px 26px;

          opacity: 0;
          transform: translateY(50px);

          transition: opacity $general-transition-time $general-transition-ease,
                      transform $general-transition-time $general-transition-ease;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.home-slides .section-projects .content {
  justify-content:center;
}

@include media-breakpoint-down(sm) {
  .section-vision .fp-tableCell , .section-projects .fp-tableCell , .section-approach .fp-tableCell, .section-last .fp-tableCell   {
    height: auto!important;
  }
  .home-slides .section-vision   ,  .home-slides  .section-projects  , .section-approach, .section-last {
    height: auto!important;
  }
  .home-slides .section-last:before {
    display: none!important;
  }
}


.home-slides .section-projects .content h2 {
  opacity: 1!important;
    transition: none!important;
    transform: none!important;
}

h2.big  {
  span {
    text-transform: none!important;
  }
}

#fullpage-menu {
  a {
    span {
      text-transform: none!important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .nomax {
    min-height: auto!important;
  }
}

.home-slides .section-projects .carousel-item .image-logineko-project {
  height:200px;
  @include media-breakpoint-down(xxl) {
    height: 170px;
  }
  @include media-breakpoint-down(xl) {
    height: 160px;
  }
  @include media-breakpoint-down(lg) {
    height: 150px;
  }
  @include media-breakpoint-down(md) {
    height: 200px;
  }
}

.home-slides .section-projects .carousel-item .image {
  height:200px;
  @include media-breakpoint-down(xxl) {
    height: 170px;
  }
  @include media-breakpoint-down(xl) {
    height: 160px;
  }
  @include media-breakpoint-down(lg) {
    height: 150px;
  }  
  @include media-breakpoint-down(md) {
    height: 200px;
  }  
}

.home-slides .projects-eko {
background: rgb(170,181,85);
background: linear-gradient(180deg, rgba(170,181,85,1) 0%, rgba(121,155,56,1) 100%);
}

.home-slides .projects-eko .background.background-color {
background: rgb(170,181,85);
background: linear-gradient(180deg, rgba(170,181,85,1) 0%, rgba(121,155,56,1) 100%);
}

.home-slides .projects-eko .content:before {
background: rgb(170,181,85);
background: linear-gradient(180deg, rgba(170,181,85,1) 0%, rgba(121,155,56,1) 100%);
}

.home-slides .projects-eko .content p {
  max-width:1100px;
}

.home-slides .projects-eko h2 span {
  text-transform: none!important;
}

.home-slides .section-projects .content {
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.cover-project .content-wrapper p a {
  color:#fff;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.home-slides .section-projects .carousel-item .visit::after {
  display: none!important;
}

.home-slides .section-projects .carousel-item .title h3 a {
  color:#fff;
  &:hover {
    color:#fff;
  }
}

.home-slides .section-projects .content p a {
  color:#fff;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color:#fff;
    text-decoration: none;
  }
}

.home-slides .section-projects .carousel-item .title {
  justify-content: flex-start!important;
}

@media screen and (max-width:1660px) {
  .home-slides .section-projects .carousel-item .title {
    margin-bottom: 10px;
  }
}

@media screen and (min-width:1024px) and (max-width:1660px) {
  .home-slides .section-projects .carousel-item .title h3 {
    font-size:16px!important;
  }
}