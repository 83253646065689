/*
  @file:
    _placeholders.scss

  @description:
    SASS custom placeholders used as abstract classes for
    extending purposes
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

/*
  @placeholder:
    clearfix

  @description
    Classic clearfix approach, just the SASS way :)

  @implementation:
    .selector { @extend %clearfix; }

*/

%clearfix {


  &:before, &:after {

    content: '';
    display: table;

  }

  &:after {

    clear: both;

  }

}
