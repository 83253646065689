/*
  @file:
    _footer.scss

  @description:
    Example of 'footer' specific styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

.footer {
  background-color: $color__grey-3;

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    padding: 4rem 1rem 2rem 1rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-up(lg) {
      padding: 5.5rem 1rem 2rem 1rem;
    }
  }

  &-logo {
    width: 100%;
    max-width: 364px;
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 4rem;
    }

    img {
      width: 100%;
      height: auto;
    }

    .login-eko {
      svg {
        path {
          &.gray {
            fill: $logo__grey;
          }
          &.green {
            fill: $logo__green;
          }
          &.red {
            fill: $logo__red;
          }
        }
      }
    }

    .login-aphrodite {
      svg {
        path {
          &.dark {
            fill: $logo__aphrodite-dark;
          }
          &.green {
            fill: $logo__aphrodite-green;
          }
        }
      }
    }
    
  }

  &-blocks {
    @include media-breakpoint-up(md) {
      margin-bottom: 6.5rem;
    }
  }

  &-block {
    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }

    &.text-md-right {
      .text {
        @include media-breakpoint-up(md) {
          margin-left: auto;
        }
      }
    }

    .info {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3.5rem;
      }

      .lead {
        @include font-style--alter($font-secondary, 12, 24, $weight--black, $color__grey-dark, none);
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: .5rem;

        span {
          font-weight: $weight--bold;
        }
      }

      p {
        @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey-dark, none);

        a {
          color: inherit;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .text {
      max-width: 336px;
      margin-bottom: 1.5rem;

      p {
        @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey-dark, none);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .join {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 1.5rem;

      a {
        @include font-style--alter($font-secondary, 16, 24, $weight--bold, $color__grey-dark, none);
        text-transform: uppercase;
        margin-left: 2rem;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -1.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $black-2;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .social {
      display: flex;
      align-items: center;

      a {
        margin-left: .75rem;
        flex: 0 0 2.5rem;

        &:first-child {
          margin-left: 0;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .links {
      p {
        @include font-style--alter($font-secondary, 16, 16, $weight--xBold, $color__grey-dark, none);
        text-transform: uppercase;
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          font-weight: $weight--medium;
        }
      }
    }

    .joinus {

      @include media-breakpoint-up(md) {
        margin-top: 3rem;
      }

      a {
        @include font-style--alter($font-primary, 30, 24, $weight--regular, $color__grey-dark, none);
        text-transform: uppercase;

        strong {
          font-weight: $weight--bold;
        }
      }
    }
  }

  .colophon {
    opacity: .7;

    .col-auto {
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        width: 100%;
        text-align: center;
        padding: 0;
      }
    }

    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: .5rem 0;

      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

      @include font-style--alter($font-primary, 14, 20, $weight--bold, $color__grey-dark, none);

      a {
        display: flex;
        align-items: center;
        @include font-style--alter($font-primary, 14, 20, $weight--bold, $color__grey-dark, none);
        margin: 0 .75rem;

        &.enki {
          margin: 0;
          padding-right: 18px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath d='M6.676 6.676v5.677M8.408 6.508l2.917 3.432M4.904 6.508L1.972 9.954M12.311 1a8.464 8.464 0 01-5.663 2.16A8.455 8.455 0 011 1' stroke='%23333' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 13px 13px;

          img {
            width: 44px;
            height: auto;
            margin-top: -4px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}