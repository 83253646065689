.cover-base {
  position: relative;

  .image {
    position: relative;
    margin-bottom: .25rem;
    overflow: hidden;
    height: 100vh;
    max-height: 1080px;
    min-height: 640px;

    .poster {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;

      transition: opacity $general-transition-time $general-transition-ease,
      visibility $general-transition-time $general-transition-ease;

      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, #494949 0%, rgba(186, 186, 186, 0.13) 100%);
        mix-blend-mode: multiply;
        opacity: 0.95;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      img.mobile {
        display: none
      }
      
      @include media-breakpoint-down(sm) {

        img {
          display: none;
        }

        img.mobile {
          display: block;
        }

      }

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .btn-play {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%) !important;
    width: 5rem;
    height: 5rem;
    z-index: 3;

    transition: opacity $general-transition-time $general-transition-ease,
    visibility $general-transition-time $general-transition-ease;

    @include media-breakpoint-down(sm) {
      top: 65%;
      left: 50%;
    }
  }

  .video-overlay {
    position: fixed;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($black-2, .8);

    opacity: 0;
    visibility: hidden;

    transition: opacity $general-transition-time $general-transition-ease,
    visibility $general-transition-time $general-transition-ease;

    .overlay-video-active & {
      opacity: 1;
      visibility: visible;
    }

    .video-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      z-index: 5;

      width: 54px;
      height: 54px;

      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        border: 3px solid $white;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 24px;
          height: 3px;
          background-color: $white;
          border-radius: 8px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 24px;
          height: 3px;
          background-color: $white;
          border-radius: 8px;
        }
      }
    }

    &-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .iframe-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1560px;
    padding: 0 1rem;

    @include media-breakpoint-down(sm) {
      top: 35%;
    }
    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    &-wrapper {
      @include media-breakpoint-up(md) {
        max-width: 66%;
      }

      h1 {
        @include font-style--alter($font-secondary, 80, 80, $weight--regular, $color__grey-light, none);
        text-transform: uppercase;
        margin-bottom: 3rem;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 42, 42, $weight--regular, $color__grey-light, none);
        }

        strong {
          font-weight: $weight--bold;
        }
      }

      p {
        @include font-style--alter($font-primary, 40, 38, $weight--medium, $white, none);

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-primary, 22, 20, $weight--medium, $white, none);
        }
      }
    }
  }

  .scroll-down {
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%) !important;

    display: inline-block;
    text-align: center;

    .icon {
      width: 31px;
      height: 34px;
      margin: 0 auto .5rem auto;
    }

    span {
      display: block;
      @include font-style--alter($font-primary, 14, 38, $weight--medium, $white, none);
      text-transform: uppercase;
    }

    svg {
      path {
        animation-name: arrowDown;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes arrowDown {
        0% {
          transform: translateY(0);
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        75% {
          opacity: .65;
        }
        100% {
          opacity: 0;
          transform: translateY(6px)
        }
      }
    }
  }
}

.page-about {
  padding-top: 2.5rem;

  @include media-breakpoint-up(lg) {
    padding-top: 7rem;
  }

  .content-text-image {
    .text {
      h2 {
        font-weight: $weight--regular;

        strong {
          font-weight: $weight--semiBold;
        }
      }
    }
  }
}