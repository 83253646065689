/*
  @file:
    _overlay.scss

  @description:
    Example of 'overlay' specific styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/
.menu-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  //background: linear-gradient(180deg, #0098CA 28.65%, #0069CA 100%);


  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: 0;
  visibility: hidden;

  transition: opacity .6s $general-transition-ease,
              visibility .6s $general-transition-ease;

  &::after {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 4px;
    height: 4px;
    margin: -2px 0 0 -2px;
    border-radius: 100%;
    transition: all 0.6s 0s ease;
    visibility: hidden;
  }

  .overlay-menu-active & {
    opacity: 1;
    visibility: visible;

    &::after {
      visibility: visible;
      box-shadow: 0 0 0 210vw $color__blue, inset 0 0 0 100px $color__blue;

      @include media-breakpoint-up(lg) {
        box-shadow: 0 0 0 135vw $color__blue, inset 0 0 0 10px $color__blue;
      }
    }
  }

  .menu-close {
    width: 54px;
    height: 54px;
    position: relative;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      border: 3px solid $white;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 24px;
        height: 3px;
        background-color: $white;
        border-radius: 8px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 24px;
        height: 3px;
        background-color: $white;
        border-radius: 8px;
      }
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 996px;
    margin: 0 auto;
    padding: 0 1rem;

    @include media-breakpoint-down(sm) {
      margin: 0 auto 6rem auto;
    }

    .menu {
      li {
        position: relative;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        max-width: 55%;

        @include media-breakpoint-up(md) {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
          max-width: 432px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: .75rem;
          padding-bottom: .75rem;
        }

        &.menu-item-2958 {
          a {
            text-transform: none;
          }
        }

        &.current-menu-item {
          a {
            font-weight: $weight--bold;
            text-decoration: underline;
          }
        }

        &.menu-item-has-children {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: -268px;
            height: 1px;
            background-color: $white;
          }

          a {
            font-weight: $weight--xBold;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          @include font-style--alter($font-secondary, 38, 38, $weight--medium, $color__grey-light, none);
          text-transform: uppercase;

          @include media-breakpoint-down(sm) {
            @include rfs(32);
          }
        }

        .sub-menu {
          position: absolute;
          transform: translateY(-40px);
          left: 100%;
          padding-top: 64px;
          padding-bottom: 40px;
          padding-left: 1.25rem;
          border-left: 1px solid $white;
          width: 85%;

          @include media-breakpoint-up(md) {
            padding-top: 76px;
            padding-bottom: 80px;
            min-width: 268px;
            width: auto;
          }

          li {
            margin-bottom: 1rem;
            padding-bottom: 0;

            @include media-breakpoint-up(md) {
              margin-bottom: 1.5rem;
            }

            @include media-breakpoint-down(sm) {
              margin-bottom: .5rem;
              width: 100%;
              max-width: none;
              line-height: 1.25;
            }

            &.current-menu-item {
              a {
                font-weight: $weight--bold;
                text-decoration: underline;
              }
            }

            a {
              @include font-style--alter($font-secondary, 18, 22, $weight--medium, $color__grey-light, none);
              text-transform: uppercase;
              padding-left: 1rem;
              position: relative;
              display: block;

              @include media-breakpoint-down(sm) {
                @include rfs(12);
                line-height: 1.25;
              }

              &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.logineko-projects {
  margin-bottom: 40px;
  .project-card {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
        height: 300px;
      }
      span {
        background-color: #fff;
        position: absolute;
        left:25px;
        bottom:0px;
        padding:15px;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}