.cover-shop {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color__blue-light;
    opacity: 0.7;
  }

  .image {
    position: relative;
    overflow: hidden;
    height: 295px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    bottom: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1560px;
    padding: 5rem 1rem 1rem 1rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-down(sm) {
      bottom: 1.5rem;
    }

    &-wrapper {
      width: 100%;
      max-width: 480px;

      h1 {
        @include font-style--alter($font-secondary, 42, 42, $weight--bold, $white, none);
        text-transform: uppercase;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          @include font-style--alter($font-secondary, 80, 80, $weight--bold, $white, none);
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.page-shop {
  padding: 2rem 0;

  @include media-breakpoint-up(md) {
    padding: 4rem 0;
  }

  &--wrapper {
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    position: relative;
    max-width: 1560px;
    overflow: hidden;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
  }

  .topbar {
    margin-bottom: 35px;

    &--wrapper {
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .sort {
        @include media-breakpoint-down(sm) {
          margin-bottom: 1.5rem;
        }

        select {
          appearance: none;
          padding: .25rem 4rem .25rem 1rem;
          @include font-style--alter($font-primary, 14, 18, $weight--regular, rgba($black-2, 0.6), none);
          border: 1px solid $color__grey-light;

          background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 7'%3E%3Cpath fill='%236B6B6B' d='M5.5 7L.737.25h9.526L5.5 7z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center right 2rem;
          background-size: 11px 9px;
        }
      }

      .pagination {
        .pager {
          display: flex;
          align-items: center;

          @include media-breakpoint-down(sm) {
            justify-content: flex-end;
          }

          li {
            margin: 0 5px;
            width: 30px;
            height: 30px;
            background-color: $color__grey-light-2;
            border-radius: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.active {
              background-color: $color__blue-light;

              a {
                color: $white;
              }
            }

            &.prev,
            &.next {
              background-color: transparent;
            }

            &.next {
              background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M9 5.5L.75 10.263V.737L9 5.5z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 11px 11px;
            }

            &.prev {
              background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M0 5.5L8.25.737v9.526L0 5.5z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 11px 11px;
            }
          }

          a {
            display: block;
            @include font-style--alter($font-primary, 14, 14, $weight--bold, rgba($black-2, 0.6), none);
          }
        }
      }
    }
  }

  .bottombar {
    margin-top: 35px;

    &--wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pagination {
        margin-left: auto;

        .pager {
          display: flex;
          align-items: center;

          li {
            margin: 0 5px;
            width: 30px;
            height: 30px;
            background-color: $color__grey-light-2;
            border-radius: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.active {
              background-color: $color__blue-light;

              a {
                color: $white;
              }
            }

            &.prev,
            &.next {
              background-color: transparent;
            }

            &.next {
              background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M9 5.5L.75 10.263V.737L9 5.5z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 11px 11px;
            }

            &.prev {
              background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M0 5.5L8.25.737v9.526L0 5.5z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 11px 11px;
            }
          }

          a {
            display: block;
            @include font-style--alter($font-primary, 14, 14, $weight--bold, rgba($black-2, 0.6), none);
          }
        }
      }
    }
  }

  &--blocks {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -.75rem;

    @include media-breakpoint-up(lg) {
      margin: 0 -1.75rem;
    }

    .block {
      padding: 0 .75rem;
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {
        padding: 0 1.75rem;
      }

      &.book {
        flex: 0 0 25%;
        width: 25%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 50%;
          width: 50%;
        }
      }
      &.workshop {
        flex: 0 0 50%;
        width: 50%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          width: 100%;
        }

        .content {
          @include media-breakpoint-up(lg) {
            width: 45%;
          }
        }
      }

      &--wrapper {
        height: 100%;
        position: relative;

        .image {
          margin-bottom: 1rem;
          width: 100%;
          position: relative;
          overflow: hidden;

          @include media-breakpoint-up(md) {
            height: 245px;
          }
          @include media-breakpoint-up(lg) {
            height: 320px;
          }
          @include media-breakpoint-up(xxxl) {
            height: 480px;
          }

          img {
            width: 100%;
            height: auto;

            @include media-breakpoint-up(md) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .badge {
            position: absolute;
            z-index: 2;
            top: 11px;
            left: 0;
            border-radius: 0 11.5px 11.5px 0;
            padding: .25rem;

            display: flex;
            flex-direction: column;
            justify-content: center;

            &.new {
              background-color: $color__red-2;
            }

            span {
              display: inline-block;
              padding: .125rem .75rem;
              @include font-style--alter($font-primary, 12, 12, $weight--semiBold, $white, none);
            }
          }
        }

        .content {
          h2 {
            @include font-style--alter($font-primary, 14, 18, $weight--bold, rgba($black-2, 0.6), none);
            margin-bottom: .75rem;
          }

          .price {
            @include font-style--alter($font-primary, 14, 18, $weight--bold, rgba($color__grey-11, 0.6), none);
            margin-bottom: 1rem;
            display: block;
          }

          .link {
            position: relative;
            z-index: 2;

            a {
              display: inline-block;
              padding: .75rem 1.5rem;
              background-color: $color__grey-3;
              @include font-style--alter($font-primary, 12, 12, $weight--semiBold, $black-2, none);
              text-transform: uppercase;

              transition: background-color $general-transition-time $general-transition-ease,
            color $general-transition-time $general-transition-ease;

              &:hover {
                background-color: $color__blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.cover-shop--single {
  overflow: hidden;

  &--wrapper {
    width: 100%;
    max-width: 1560px;
    padding: 0 1rem;
    margin: 6rem auto 0 auto;

    @include media-breakpoint-up(md) {
      margin: 8.5rem auto 0 auto;
    }
    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    .content--wrapper {
      &.cover--workshop {
        .gallery {
          width: 100%;
          flex: 0 0 100%;
          padding-bottom: 1.5rem;

          @include media-breakpoint-up(md) {
            width: 35%;
            flex: 0 0 35%;
            padding-bottom: 3rem;
          }
          @include media-breakpoint-up(lg) {
            width: 540px;
            flex: 0 0 540px;
            padding-bottom: 3rem;
          }

          .main {
            margin-bottom: .75rem;

            img {
              width: 100%;
              height: auto;
            }
          }

          .nav {
            display: flex;
            align-items: stretch;
            margin: 0 -0.375rem;

            .image {
              flex: 0 0 33.33333%;
              width: 33.33333%;
              padding: 0 .375rem;
              height: 90px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .content {
          @include media-breakpoint-up(md) {
            flex: 0 0 65%;
            padding-left: 3rem;
          }
          @include media-breakpoint-up(lg) {
            flex: 1 1 auto;
            padding-left: 4rem;
          }
        }
      }
    }

    .breadcrumbs {
      margin-bottom: 1.5rem;

      .breadcrumb-item {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23C4C4C4'/%3E%3C/svg%3E");

          @include media-breakpoint-down(md) {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23C4C4C4'/%3E%3C/svg%3E");
          }
        }

        a {
          @include font-style--alter($font-primary, 16, 32, $weight--bold, rgba($black-2, 0.6), none);
        }
      }
    }

    .content--wrapper {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      .gallery {
        width: 100%;
        flex: 0 0 100%;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
          width: 30%;
          flex: 0 0 30%;
          padding-bottom: 3rem;
        }
        @include media-breakpoint-up(lg) {
          width: 300px;
          flex: 0 0 300px;
          padding-bottom: 3rem;
        }

        .top {
          h1 {
            @include font-style--alter($font-secondary, 42, 42, $weight--bold, $black-2, none);
            text-transform: uppercase;
            margin-bottom: .5rem;

            @include media-breakpoint-down(sm) {
              @include font-style--alter($font-secondary, 80, 80, $weight--bold, $black-2, none);
              margin-bottom: 1rem;
            }
          }

          .author {
            @include font-style--alter($font-primary, 18, 30, $weight--regular, rgba($black-2, 0.6), none);
            margin-bottom: 1.5rem;
          }
        }

        .main {
          margin-bottom: .75rem;

          img {
            width: 100%;
            height: auto;
          }
        }

        .nav {
          display: flex;
          align-items: stretch;
          margin: 0 -0.375rem;

          .image {
            flex: 0 0 33.33333%;
            width: 33.33333%;
            padding: 0 .375rem;
            height: 90px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .content {
        @include media-breakpoint-up(md) {
          flex: 0 0 70%;
          padding-left: 80px;
        }
        @include media-breakpoint-up(lg) {
          flex: 1 1 auto;
          padding-left: 110px;
        }

        .top {
          h1 {
            @include font-style--alter($font-secondary, 42, 42, $weight--bold, $black-2, none);
            text-transform: uppercase;
            margin-bottom: .5rem;

            @include media-breakpoint-down(sm) {
              @include font-style--alter($font-secondary, 80, 80, $weight--bold, $black-2, none);
              margin-bottom: 1rem;
            }
          }

          .author {
            @include font-style--alter($font-primary, 18, 30, $weight--regular, rgba($black-2, 0.6), none);
            margin-bottom: 1.5rem;
          }
        }

        .bottom {
          position: relative;
          padding: 2rem 0;

          @include media-breakpoint-up(md) {
            padding: 2.5rem 0 3rem 0;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: -9999rem;
            right: -9999rem;
            background-color: $color__blue-light;
          }

          .text {
            margin-bottom: 3rem;

            @include media-breakpoint-up(md) {
              margin-bottom: 6rem;
            }
            @include media-breakpoint-up(lg) {
              max-width: 504px;
              margin-bottom: 10rem;
            }

            p {
              @include font-style--alter($font-primary, 18, 30, $weight--regular, $white, none);
            }
          }

          .options {
            padding-top: 3rem;
            max-width: 550px;
            width: 100%;
            border-top: 1px solid $white;

            display: flex;
            align-items: center;

            form {
              display: flex;
              align-items: center;
              margin-right: 1.5rem;

              @include media-breakpoint-up(lg) {
                margin-right: 2.5rem;
              }

              .form-item {
                margin-right: 2rem;
              }

              input[type="number"] {
                border: 1px solid $white;
                width: 40px;
                height: 40px;
                text-align: center;
                padding: .5rem;
                appearance: none;
                background-color: transparent;

                @include font-style--alter($font-secondary, 14, 32, $weight--xBold, $white, none);

                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                &::placeholder {
                  @include font-style--alter($font-primary, 14, 32, $weight--xBold, $white, none);
                }
              }

              button {
                appearance: none;
                border: 1px solid $white;
                display: inline-block;
                padding: .75rem 1.5rem;
                background-color: $white;
                @include font-style--alter($font-primary, 12, 14, $weight--semiBold, $color__blue, none);
                text-transform: uppercase;

                transition: background-color $general-transition-time $general-transition-ease,
                color $general-transition-time $general-transition-ease;

                &:hover {
                  background-color: transparent;
                  color: $white;
                }
              }
            }

            .price {
              @include font-style--alter($font-primary, 32, 32, $weight--regular, $white, none);
            }
          }
        }
      }
    }
  }
}

.page-shop--single {
  &--wrapper {
    width: 100%;
    max-width: 1560px;
    //padding: 0 1rem;
    margin: 0 auto;
    position: relative;

    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    @include media-breakpoint-up(md) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }

    .sidemenu {
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 576px;
        margin: 0 auto;
        padding: 0 1rem 2rem 1rem;
        display: flex;
        overflow: auto;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 7rem;
        left: 1rem;
      }

      a {
        display: block;
        position: relative;
        @include font-style--alter($font-primary, 24, 24, $weight--light, $black, none);
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin-bottom: .75rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid $color__grey-9;
          padding-right: 44px;
        }

        @include media-breakpoint-down(md) {
          padding-right: 1.5rem;
          flex: 0 0 auto;
        }

        &:last-child {
          @include media-breakpoint-up(lg) {
            border-bottom: 0;
          }
        }

        &.active {
          font-weight: $weight--bold;

          @include media-breakpoint-down(md) {
            //order: -1;
            &::before {
              content: "";
              position: absolute;
              bottom: -.25rem;
              left: 0;
              right: 1.5rem;
              height: 1px;
              background-color: $black;
            }
          }
        }
      }
    }
  }

  .title-container,
  .text-container {
    max-width: 736px;

    @include media-breakpoint-between(lg, xxl) {
      padding-left: 208px;
    }
  }

  .text-container {
    p {
      @include font-style--alter($font-primary, 18, 30, $weight--regular, rgba($black-2, 0.6), none);
    }
  }

  .tabs-wrapper {
    .tab {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .title-container {
    margin-bottom: 2rem;

    h2 {
      @include font-style--alter($font-primary, 21, 18, $weight--bold, rgba($black-2, 0.6), none);
    }
  }

  .content-offset {
    @include media-breakpoint-down(xxl) {
      max-width: 1380px;
    }

    h2 {
      @include media-breakpoint-down(xxl) {
        @include font-size(3rem);
      }
    }
  }

  .content-quote {
    &::before {
      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%2351B9CA' fill-opacity='.3'/%3E%3C/svg%3E");
    }

    p {
      color: $color__blue-light;
    }
  }

  .content-offset {
    //padding: 0;

    &-wrapper {
      max-width: none;
    }
  }

  .content-text-image {
    @include media-breakpoint-up(xxl) {
      margin-bottom: 5rem;
    }

    &-wrapper {
      //padding: 0;
    }

    .image {
      @include media-breakpoint-up(xxxl) {
        width: 55%;
        flex: 0 0 55%;
      }
    }
    .text {
      @include media-breakpoint-up(xxxl) {
        width: 45%;
        flex: 0 0 45%;
      }

      h2 {
        @include media-breakpoint-down(xxl) {
          @include font-size(2.25rem);
        }
      }
    }

    &.right {
      .text {
        &-wrapper {
          padding-left: 0;
          margin-left: 0;

          p {
            margin-bottom: 3rem;

            @include media-breakpoint-up(xxl) {
              margin-bottom: 6rem;
            }
          }
        }
      }
    }
  }
}

.section-related {
  &--wrapper {
    width: 100%;
    max-width: 1560px;
    padding: 0 1rem;
    margin: 0 auto;
    overflow: hidden;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    .title {
      margin-bottom: 2.625rem;

      h2 {
        @include font-style--alter($font-primary, 21, 18, $weight--bold, rgba($black-2, 0.6), none);
      }
    }

    .blocks--wrapper {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 -.75rem;

      @include media-breakpoint-up(lg) {
        margin: 0 -1.75rem;
      }

      .block {
        padding: 0 .75rem;
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
          padding: 0 1.75rem;
        }

        &.book {
          flex: 0 0 25%;
          width: 25%;

          @include media-breakpoint-down(sm) {
            flex: 0 0 50%;
            width: 50%;
          }
        }
        &.workshop {
          flex: 0 0 50%;
          width: 50%;

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            width: 100%;
          }

          .content {
            @include media-breakpoint-up(lg) {
              width: 45%;
            }
          }
        }

        &--wrapper {
          height: 100%;
          position: relative;

          .image {
            margin-bottom: 1rem;
            width: 100%;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-up(md) {
              height: 245px;
            }
            @include media-breakpoint-up(lg) {
              height: 320px;
            }
            @include media-breakpoint-up(xxxl) {
              height: 480px;
            }

            img {
              width: 100%;
              height: auto;

              @include media-breakpoint-up(md) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .badge {
              position: absolute;
              z-index: 2;
              top: 11px;
              left: 0;
              border-radius: 0 11.5px 11.5px 0;
              padding: .25rem;

              display: flex;
              flex-direction: column;
              justify-content: center;

              &.new {
                background-color: $color__red-2;
              }

              span {
                display: inline-block;
                padding: .125rem .75rem;
                @include font-style--alter($font-primary, 12, 12, $weight--semiBold, $white, none);
              }
            }
          }

          .content {
            h2 {
              @include font-style--alter($font-primary, 14, 18, $weight--bold, rgba($black-2, 0.6), none);
              margin-bottom: .75rem;
            }

            .price {
              @include font-style--alter($font-primary, 14, 18, $weight--bold, rgba($color__grey-11, 0.6), none);
              margin-bottom: 1rem;
              display: block;
            }

            .link {
              position: relative;
              z-index: 2;

              a {
                display: inline-block;
                padding: .75rem 1.5rem;
                background-color: $color__grey-3;
                @include font-style--alter($font-primary, 12, 12, $weight--semiBold, $black-2, none);
                text-transform: uppercase;

                transition: background-color $general-transition-time $general-transition-ease,
                color $general-transition-time $general-transition-ease;

                &:hover {
                  background-color: $color__blue;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}