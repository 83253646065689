/*
  @file:
    _typography.scss

  @description:
    Typography related rules
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

a {
  color: inherit;
  text-decoration: none;
  outline: none;
  border: 0;
}

.content-offset,
.text-container,
.content-text-image {
  ul {
    margin-bottom: 2rem;

    li {
      @include font-style--alter($font-primary, 22, 36, $weight--regular, $color__grey, none);

      @include media-breakpoint-down(xxl) {
        @include font-style--alter($font-primary, 18, 32, $weight--regular, $color__grey, none);
      }

      padding-left: 26px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 15px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $color__blue;

        @include media-breakpoint-down(sm) {
          top: 9px;
        }
      }
    }
  }

  .table-overflow {
    @include media-breakpoint-down(md) {
      overflow: auto;
    }
  }

  table {
    width: 100%;

    tr {
      th,
      td {
        padding: .5rem 1rem;
        border-right: 2px solid $color__grey-light;
        border-bottom: 2px solid $color__grey-light;

        @include media-breakpoint-down(md) {
          min-width: 300px;
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: 0;
        }

        @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey, none);

        p {
          @include font-style--alter($font-primary, 16, 24, $weight--regular, $color__grey, none);
        }
      }
    }
  }
}