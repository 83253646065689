@import "https://use.typekit.net/mgx0wna.css";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4e4e4e;
  --secondary: #fff;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --breakpoint-xxxl: 1600px;
  --font-family-sans-serif: "Lato", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #4e4e4e;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #4e4e4e;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #282828;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + .3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #4e4e4e;
  margin-bottom: .5rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 7.5rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.875rem + 7.5vw);
  }
}

h2, .h2 {
  font-size: 5rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.625rem + 4.5vw);
  }
}

h3, .h3 {
  font-size: 3rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

h4, .h4 {
  font-size: 2.75rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.4rem + 1.8vw);
  }
}

h5, .h5 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h5, .h5 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h6, .h6 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h6, .h6 {
    font-size: calc(1.325rem + .9vw);
  }
}

.lead {
  font-size: 1.2rem;
  font-weight: 500;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: .875em;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1032px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1232px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xxxl, .col-xxxl-auto, .col-xxxl-12, .col-xxxl-11, .col-xxxl-10, .col-xxxl-9, .col-xxxl-8, .col-xxxl-7, .col-xxxl-6, .col-xxxl-5, .col-xxxl-4, .col-xxxl-3, .col-xxxl-2, .col-xxxl-1, .col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xxl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xxl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xxl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xxl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xxl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xxl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xxl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xxl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xxl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xxl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xxl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xxl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xxl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xxl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xxl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xxl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xxl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xxl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xxl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1600px) {
  .col-xxxl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xxxl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xxxl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xxxl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xxxl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xxxl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xxxl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xxxl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xxxl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xxxl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xxxl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xxxl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xxxl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xxxl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xxxl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xxxl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xxxl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xxxl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xxxl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xxxl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xxxl-first {
    order: -1;
  }

  .order-xxxl-last {
    order: 13;
  }

  .order-xxxl-0 {
    order: 0;
  }

  .order-xxxl-1 {
    order: 1;
  }

  .order-xxxl-2 {
    order: 2;
  }

  .order-xxxl-3 {
    order: 3;
  }

  .order-xxxl-4 {
    order: 4;
  }

  .order-xxxl-5 {
    order: 5;
  }

  .order-xxxl-6 {
    order: 6;
  }

  .order-xxxl-7 {
    order: 7;
  }

  .order-xxxl-8 {
    order: 8;
  }

  .order-xxxl-9 {
    order: 9;
  }

  .order-xxxl-10 {
    order: 10;
  }

  .order-xxxl-11 {
    order: 11;
  }

  .order-xxxl-12 {
    order: 12;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.6667%;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4e4e4e !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #353535 !important;
}

.bg-secondary {
  background-color: #fff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4e4e4e !important;
}

.border-secondary {
  border-color: #fff !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxxl-fill {
    flex: auto !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xxxl-left {
    float: left !important;
  }

  .float-xxxl-right {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .5rem !important;
}

.mt-1, .my-1 {
  margin-top: .5rem !important;
}

.mr-1, .mx-1 {
  margin-right: .5rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .5rem !important;
}

.ml-1, .mx-1 {
  margin-left: .5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2, .my-2 {
  margin-top: 1rem !important;
}

.mr-2, .mx-2 {
  margin-right: 1rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 1rem !important;
}

.ml-2, .mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3, .my-3 {
  margin-top: 1.5rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4, .my-4 {
  margin-top: 2rem !important;
}

.mr-4, .mx-4 {
  margin-right: 2rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 2rem !important;
}

.ml-4, .mx-4 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5, .my-5 {
  margin-top: 2.5rem !important;
}

.mr-5, .mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5, .mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6, .my-6 {
  margin-top: 3rem !important;
}

.mr-6, .mx-6 {
  margin-right: 3rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 3rem !important;
}

.ml-6, .mx-6 {
  margin-left: 3rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10, .my-10 {
  margin-top: 5rem !important;
}

.mr-10, .mx-10 {
  margin-right: 5rem !important;
}

.mb-10, .my-10 {
  margin-bottom: 5rem !important;
}

.ml-10, .mx-10 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .5rem !important;
}

.pt-1, .py-1 {
  padding-top: .5rem !important;
}

.pr-1, .px-1 {
  padding-right: .5rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .5rem !important;
}

.pl-1, .px-1 {
  padding-left: .5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2, .py-2 {
  padding-top: 1rem !important;
}

.pr-2, .px-2 {
  padding-right: 1rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 1rem !important;
}

.pl-2, .px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3, .py-3 {
  padding-top: 1.5rem !important;
}

.pr-3, .px-3 {
  padding-right: 1.5rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3, .px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4, .py-4 {
  padding-top: 2rem !important;
}

.pr-4, .px-4 {
  padding-right: 2rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 2rem !important;
}

.pl-4, .px-4 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5, .py-5 {
  padding-top: 2.5rem !important;
}

.pr-5, .px-5 {
  padding-right: 2.5rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5, .px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6, .py-6 {
  padding-top: 3rem !important;
}

.pr-6, .px-6 {
  padding-right: 3rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 3rem !important;
}

.pl-6, .px-6 {
  padding-left: 3rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10, .py-10 {
  padding-top: 5rem !important;
}

.pr-10, .px-10 {
  padding-right: 5rem !important;
}

.pb-10, .py-10 {
  padding-bottom: 5rem !important;
}

.pl-10, .px-10 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -.5rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.5rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.5rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.5rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -1rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -2rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -2rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -2rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -2rem !important;
}

.m-n5 {
  margin: -2.5rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -2.5rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -2.5rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -2.5rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -2.5rem !important;
}

.m-n6 {
  margin: -3rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -3rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -3rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -3rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -3rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.mt-n10, .my-n10 {
  margin-top: -5rem !important;
}

.mr-n10, .mx-n10 {
  margin-right: -5rem !important;
}

.mb-n10, .my-n10 {
  margin-bottom: -5rem !important;
}

.ml-n10, .mx-n10 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .5rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .5rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .5rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .5rem !important;
  }

  .m-sm-2 {
    margin: 1rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: 1rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: 1rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: 1rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 2rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 2rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 2rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 2rem !important;
  }

  .m-sm-5 {
    margin: 2.5rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 2.5rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 2.5rem !important;
  }

  .m-sm-6 {
    margin: 3rem !important;
  }

  .mt-sm-6, .my-sm-6 {
    margin-top: 3rem !important;
  }

  .mr-sm-6, .mx-sm-6 {
    margin-right: 3rem !important;
  }

  .mb-sm-6, .my-sm-6 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-6, .mx-sm-6 {
    margin-left: 3rem !important;
  }

  .m-sm-10 {
    margin: 5rem !important;
  }

  .mt-sm-10, .my-sm-10 {
    margin-top: 5rem !important;
  }

  .mr-sm-10, .mx-sm-10 {
    margin-right: 5rem !important;
  }

  .mb-sm-10, .my-sm-10 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-10, .mx-sm-10 {
    margin-left: 5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .5rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .5rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .5rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .5rem !important;
  }

  .p-sm-2 {
    padding: 1rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: 1rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: 1rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: 1rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 2rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 2rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 2rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 2rem !important;
  }

  .p-sm-5 {
    padding: 2.5rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 2.5rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 2.5rem !important;
  }

  .p-sm-6 {
    padding: 3rem !important;
  }

  .pt-sm-6, .py-sm-6 {
    padding-top: 3rem !important;
  }

  .pr-sm-6, .px-sm-6 {
    padding-right: 3rem !important;
  }

  .pb-sm-6, .py-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-6, .px-sm-6 {
    padding-left: 3rem !important;
  }

  .p-sm-10 {
    padding: 5rem !important;
  }

  .pt-sm-10, .py-sm-10 {
    padding-top: 5rem !important;
  }

  .pr-sm-10, .px-sm-10 {
    padding-right: 5rem !important;
  }

  .pb-sm-10, .py-sm-10 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-10, .px-sm-10 {
    padding-left: 5rem !important;
  }

  .m-sm-n1 {
    margin: -.5rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.5rem !important;
  }

  .m-sm-n2 {
    margin: -1rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -1rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -1rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -1rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -2rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -2rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -2rem !important;
  }

  .m-sm-n5 {
    margin: -2.5rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -2.5rem !important;
  }

  .m-sm-n6 {
    margin: -3rem !important;
  }

  .mt-sm-n6, .my-sm-n6 {
    margin-top: -3rem !important;
  }

  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -3rem !important;
  }

  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -3rem !important;
  }

  .m-sm-n10 {
    margin: -5rem !important;
  }

  .mt-sm-n10, .my-sm-n10 {
    margin-top: -5rem !important;
  }

  .mr-sm-n10, .mx-sm-n10 {
    margin-right: -5rem !important;
  }

  .mb-sm-n10, .my-sm-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n10, .mx-sm-n10 {
    margin-left: -5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .5rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .5rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .5rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .5rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .5rem !important;
  }

  .m-md-2 {
    margin: 1rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: 1rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: 1rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: 1rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: 1rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1.5rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1.5rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 2rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 2rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 2rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 2rem !important;
  }

  .m-md-5 {
    margin: 2.5rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 2.5rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 2.5rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 2.5rem !important;
  }

  .m-md-6 {
    margin: 3rem !important;
  }

  .mt-md-6, .my-md-6 {
    margin-top: 3rem !important;
  }

  .mr-md-6, .mx-md-6 {
    margin-right: 3rem !important;
  }

  .mb-md-6, .my-md-6 {
    margin-bottom: 3rem !important;
  }

  .ml-md-6, .mx-md-6 {
    margin-left: 3rem !important;
  }

  .m-md-10 {
    margin: 5rem !important;
  }

  .mt-md-10, .my-md-10 {
    margin-top: 5rem !important;
  }

  .mr-md-10, .mx-md-10 {
    margin-right: 5rem !important;
  }

  .mb-md-10, .my-md-10 {
    margin-bottom: 5rem !important;
  }

  .ml-md-10, .mx-md-10 {
    margin-left: 5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .5rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .5rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .5rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .5rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .5rem !important;
  }

  .p-md-2 {
    padding: 1rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: 1rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: 1rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: 1rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: 1rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1.5rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1.5rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 2rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 2rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 2rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 2rem !important;
  }

  .p-md-5 {
    padding: 2.5rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 2.5rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 2.5rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 2.5rem !important;
  }

  .p-md-6 {
    padding: 3rem !important;
  }

  .pt-md-6, .py-md-6 {
    padding-top: 3rem !important;
  }

  .pr-md-6, .px-md-6 {
    padding-right: 3rem !important;
  }

  .pb-md-6, .py-md-6 {
    padding-bottom: 3rem !important;
  }

  .pl-md-6, .px-md-6 {
    padding-left: 3rem !important;
  }

  .p-md-10 {
    padding: 5rem !important;
  }

  .pt-md-10, .py-md-10 {
    padding-top: 5rem !important;
  }

  .pr-md-10, .px-md-10 {
    padding-right: 5rem !important;
  }

  .pb-md-10, .py-md-10 {
    padding-bottom: 5rem !important;
  }

  .pl-md-10, .px-md-10 {
    padding-left: 5rem !important;
  }

  .m-md-n1 {
    margin: -.5rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.5rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.5rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.5rem !important;
  }

  .m-md-n2 {
    margin: -1rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -1rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -1rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -1rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -2rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -2rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -2rem !important;
  }

  .m-md-n5 {
    margin: -2.5rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -2.5rem !important;
  }

  .m-md-n6 {
    margin: -3rem !important;
  }

  .mt-md-n6, .my-md-n6 {
    margin-top: -3rem !important;
  }

  .mr-md-n6, .mx-md-n6 {
    margin-right: -3rem !important;
  }

  .mb-md-n6, .my-md-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n6, .mx-md-n6 {
    margin-left: -3rem !important;
  }

  .m-md-n10 {
    margin: -5rem !important;
  }

  .mt-md-n10, .my-md-n10 {
    margin-top: -5rem !important;
  }

  .mr-md-n10, .mx-md-n10 {
    margin-right: -5rem !important;
  }

  .mb-md-n10, .my-md-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n10, .mx-md-n10 {
    margin-left: -5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .5rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .5rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .5rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .5rem !important;
  }

  .m-lg-2 {
    margin: 1rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: 1rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: 1rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: 1rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 2rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 2rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 2rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 2rem !important;
  }

  .m-lg-5 {
    margin: 2.5rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 2.5rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 2.5rem !important;
  }

  .m-lg-6 {
    margin: 3rem !important;
  }

  .mt-lg-6, .my-lg-6 {
    margin-top: 3rem !important;
  }

  .mr-lg-6, .mx-lg-6 {
    margin-right: 3rem !important;
  }

  .mb-lg-6, .my-lg-6 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-6, .mx-lg-6 {
    margin-left: 3rem !important;
  }

  .m-lg-10 {
    margin: 5rem !important;
  }

  .mt-lg-10, .my-lg-10 {
    margin-top: 5rem !important;
  }

  .mr-lg-10, .mx-lg-10 {
    margin-right: 5rem !important;
  }

  .mb-lg-10, .my-lg-10 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-10, .mx-lg-10 {
    margin-left: 5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .5rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .5rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .5rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .5rem !important;
  }

  .p-lg-2 {
    padding: 1rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: 1rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: 1rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: 1rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 2rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 2rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 2rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 2rem !important;
  }

  .p-lg-5 {
    padding: 2.5rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 2.5rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 2.5rem !important;
  }

  .p-lg-6 {
    padding: 3rem !important;
  }

  .pt-lg-6, .py-lg-6 {
    padding-top: 3rem !important;
  }

  .pr-lg-6, .px-lg-6 {
    padding-right: 3rem !important;
  }

  .pb-lg-6, .py-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-6, .px-lg-6 {
    padding-left: 3rem !important;
  }

  .p-lg-10 {
    padding: 5rem !important;
  }

  .pt-lg-10, .py-lg-10 {
    padding-top: 5rem !important;
  }

  .pr-lg-10, .px-lg-10 {
    padding-right: 5rem !important;
  }

  .pb-lg-10, .py-lg-10 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-10, .px-lg-10 {
    padding-left: 5rem !important;
  }

  .m-lg-n1 {
    margin: -.5rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.5rem !important;
  }

  .m-lg-n2 {
    margin: -1rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -1rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -1rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -1rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -2rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -2rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -2rem !important;
  }

  .m-lg-n5 {
    margin: -2.5rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -2.5rem !important;
  }

  .m-lg-n6 {
    margin: -3rem !important;
  }

  .mt-lg-n6, .my-lg-n6 {
    margin-top: -3rem !important;
  }

  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -3rem !important;
  }

  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -3rem !important;
  }

  .m-lg-n10 {
    margin: -5rem !important;
  }

  .mt-lg-n10, .my-lg-n10 {
    margin-top: -5rem !important;
  }

  .mr-lg-n10, .mx-lg-n10 {
    margin-right: -5rem !important;
  }

  .mb-lg-n10, .my-lg-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n10, .mx-lg-n10 {
    margin-left: -5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .5rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .5rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .5rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .5rem !important;
  }

  .m-xl-2 {
    margin: 1rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: 1rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: 1rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: 1rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 2rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 2rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 2rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 2rem !important;
  }

  .m-xl-5 {
    margin: 2.5rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 2.5rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 2.5rem !important;
  }

  .m-xl-6 {
    margin: 3rem !important;
  }

  .mt-xl-6, .my-xl-6 {
    margin-top: 3rem !important;
  }

  .mr-xl-6, .mx-xl-6 {
    margin-right: 3rem !important;
  }

  .mb-xl-6, .my-xl-6 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-6, .mx-xl-6 {
    margin-left: 3rem !important;
  }

  .m-xl-10 {
    margin: 5rem !important;
  }

  .mt-xl-10, .my-xl-10 {
    margin-top: 5rem !important;
  }

  .mr-xl-10, .mx-xl-10 {
    margin-right: 5rem !important;
  }

  .mb-xl-10, .my-xl-10 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-10, .mx-xl-10 {
    margin-left: 5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .5rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .5rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .5rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .5rem !important;
  }

  .p-xl-2 {
    padding: 1rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: 1rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: 1rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: 1rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 2rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 2rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 2rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 2rem !important;
  }

  .p-xl-5 {
    padding: 2.5rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 2.5rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 2.5rem !important;
  }

  .p-xl-6 {
    padding: 3rem !important;
  }

  .pt-xl-6, .py-xl-6 {
    padding-top: 3rem !important;
  }

  .pr-xl-6, .px-xl-6 {
    padding-right: 3rem !important;
  }

  .pb-xl-6, .py-xl-6 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-6, .px-xl-6 {
    padding-left: 3rem !important;
  }

  .p-xl-10 {
    padding: 5rem !important;
  }

  .pt-xl-10, .py-xl-10 {
    padding-top: 5rem !important;
  }

  .pr-xl-10, .px-xl-10 {
    padding-right: 5rem !important;
  }

  .pb-xl-10, .py-xl-10 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-10, .px-xl-10 {
    padding-left: 5rem !important;
  }

  .m-xl-n1 {
    margin: -.5rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.5rem !important;
  }

  .m-xl-n2 {
    margin: -1rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -1rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -1rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -1rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -2rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -2rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -2rem !important;
  }

  .m-xl-n5 {
    margin: -2.5rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -2.5rem !important;
  }

  .m-xl-n6 {
    margin: -3rem !important;
  }

  .mt-xl-n6, .my-xl-n6 {
    margin-top: -3rem !important;
  }

  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -3rem !important;
  }

  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -3rem !important;
  }

  .m-xl-n10 {
    margin: -5rem !important;
  }

  .mt-xl-n10, .my-xl-n10 {
    margin-top: -5rem !important;
  }

  .mr-xl-n10, .mx-xl-n10 {
    margin-right: -5rem !important;
  }

  .mb-xl-n10, .my-xl-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n10, .mx-xl-n10 {
    margin-left: -5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0, .my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0, .mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0, .my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0, .mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: .5rem !important;
  }

  .mt-xxl-1, .my-xxl-1 {
    margin-top: .5rem !important;
  }

  .mr-xxl-1, .mx-xxl-1 {
    margin-right: .5rem !important;
  }

  .mb-xxl-1, .my-xxl-1 {
    margin-bottom: .5rem !important;
  }

  .ml-xxl-1, .mx-xxl-1 {
    margin-left: .5rem !important;
  }

  .m-xxl-2 {
    margin: 1rem !important;
  }

  .mt-xxl-2, .my-xxl-2 {
    margin-top: 1rem !important;
  }

  .mr-xxl-2, .mx-xxl-2 {
    margin-right: 1rem !important;
  }

  .mb-xxl-2, .my-xxl-2 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-2, .mx-xxl-2 {
    margin-left: 1rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .mt-xxl-3, .my-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-3, .mx-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-3, .my-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-3, .mx-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2rem !important;
  }

  .mt-xxl-4, .my-xxl-4 {
    margin-top: 2rem !important;
  }

  .mr-xxl-4, .mx-xxl-4 {
    margin-right: 2rem !important;
  }

  .mb-xxl-4, .my-xxl-4 {
    margin-bottom: 2rem !important;
  }

  .ml-xxl-4, .mx-xxl-4 {
    margin-left: 2rem !important;
  }

  .m-xxl-5 {
    margin: 2.5rem !important;
  }

  .mt-xxl-5, .my-xxl-5 {
    margin-top: 2.5rem !important;
  }

  .mr-xxl-5, .mx-xxl-5 {
    margin-right: 2.5rem !important;
  }

  .mb-xxl-5, .my-xxl-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxl-5, .mx-xxl-5 {
    margin-left: 2.5rem !important;
  }

  .m-xxl-6 {
    margin: 3rem !important;
  }

  .mt-xxl-6, .my-xxl-6 {
    margin-top: 3rem !important;
  }

  .mr-xxl-6, .mx-xxl-6 {
    margin-right: 3rem !important;
  }

  .mb-xxl-6, .my-xxl-6 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-6, .mx-xxl-6 {
    margin-left: 3rem !important;
  }

  .m-xxl-10 {
    margin: 5rem !important;
  }

  .mt-xxl-10, .my-xxl-10 {
    margin-top: 5rem !important;
  }

  .mr-xxl-10, .mx-xxl-10 {
    margin-right: 5rem !important;
  }

  .mb-xxl-10, .my-xxl-10 {
    margin-bottom: 5rem !important;
  }

  .ml-xxl-10, .mx-xxl-10 {
    margin-left: 5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0, .py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0, .px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0, .py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0, .px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: .5rem !important;
  }

  .pt-xxl-1, .py-xxl-1 {
    padding-top: .5rem !important;
  }

  .pr-xxl-1, .px-xxl-1 {
    padding-right: .5rem !important;
  }

  .pb-xxl-1, .py-xxl-1 {
    padding-bottom: .5rem !important;
  }

  .pl-xxl-1, .px-xxl-1 {
    padding-left: .5rem !important;
  }

  .p-xxl-2 {
    padding: 1rem !important;
  }

  .pt-xxl-2, .py-xxl-2 {
    padding-top: 1rem !important;
  }

  .pr-xxl-2, .px-xxl-2 {
    padding-right: 1rem !important;
  }

  .pb-xxl-2, .py-xxl-2 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-2, .px-xxl-2 {
    padding-left: 1rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .pt-xxl-3, .py-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-3, .px-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-3, .py-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-3, .px-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2rem !important;
  }

  .pt-xxl-4, .py-xxl-4 {
    padding-top: 2rem !important;
  }

  .pr-xxl-4, .px-xxl-4 {
    padding-right: 2rem !important;
  }

  .pb-xxl-4, .py-xxl-4 {
    padding-bottom: 2rem !important;
  }

  .pl-xxl-4, .px-xxl-4 {
    padding-left: 2rem !important;
  }

  .p-xxl-5 {
    padding: 2.5rem !important;
  }

  .pt-xxl-5, .py-xxl-5 {
    padding-top: 2.5rem !important;
  }

  .pr-xxl-5, .px-xxl-5 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-5, .py-xxl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxl-5, .px-xxl-5 {
    padding-left: 2.5rem !important;
  }

  .p-xxl-6 {
    padding: 3rem !important;
  }

  .pt-xxl-6, .py-xxl-6 {
    padding-top: 3rem !important;
  }

  .pr-xxl-6, .px-xxl-6 {
    padding-right: 3rem !important;
  }

  .pb-xxl-6, .py-xxl-6 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-6, .px-xxl-6 {
    padding-left: 3rem !important;
  }

  .p-xxl-10 {
    padding: 5rem !important;
  }

  .pt-xxl-10, .py-xxl-10 {
    padding-top: 5rem !important;
  }

  .pr-xxl-10, .px-xxl-10 {
    padding-right: 5rem !important;
  }

  .pb-xxl-10, .py-xxl-10 {
    padding-bottom: 5rem !important;
  }

  .pl-xxl-10, .px-xxl-10 {
    padding-left: 5rem !important;
  }

  .m-xxl-n1 {
    margin: -.5rem !important;
  }

  .mt-xxl-n1, .my-xxl-n1 {
    margin-top: -.5rem !important;
  }

  .mr-xxl-n1, .mx-xxl-n1 {
    margin-right: -.5rem !important;
  }

  .mb-xxl-n1, .my-xxl-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-xxl-n1, .mx-xxl-n1 {
    margin-left: -.5rem !important;
  }

  .m-xxl-n2 {
    margin: -1rem !important;
  }

  .mt-xxl-n2, .my-xxl-n2 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n2, .mx-xxl-n2 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n2, .my-xxl-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n2, .mx-xxl-n2 {
    margin-left: -1rem !important;
  }

  .m-xxl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n3, .my-xxl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n3, .mx-xxl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n3, .my-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n3, .mx-xxl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n4 {
    margin: -2rem !important;
  }

  .mt-xxl-n4, .my-xxl-n4 {
    margin-top: -2rem !important;
  }

  .mr-xxl-n4, .mx-xxl-n4 {
    margin-right: -2rem !important;
  }

  .mb-xxl-n4, .my-xxl-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-xxl-n4, .mx-xxl-n4 {
    margin-left: -2rem !important;
  }

  .m-xxl-n5 {
    margin: -2.5rem !important;
  }

  .mt-xxl-n5, .my-xxl-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-xxl-n5, .mx-xxl-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n5, .my-xxl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxl-n5, .mx-xxl-n5 {
    margin-left: -2.5rem !important;
  }

  .m-xxl-n6 {
    margin: -3rem !important;
  }

  .mt-xxl-n6, .my-xxl-n6 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n6, .mx-xxl-n6 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n6, .my-xxl-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n6, .mx-xxl-n6 {
    margin-left: -3rem !important;
  }

  .m-xxl-n10 {
    margin: -5rem !important;
  }

  .mt-xxl-n10, .my-xxl-n10 {
    margin-top: -5rem !important;
  }

  .mr-xxl-n10, .mx-xxl-n10 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n10, .my-xxl-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-xxl-n10, .mx-xxl-n10 {
    margin-left: -5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto, .my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto, .mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto, .my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto, .mx-xxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }

  .mt-xxxl-0, .my-xxxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxxl-0, .mx-xxxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxxl-0, .my-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxxl-0, .mx-xxxl-0 {
    margin-left: 0 !important;
  }

  .m-xxxl-1 {
    margin: .5rem !important;
  }

  .mt-xxxl-1, .my-xxxl-1 {
    margin-top: .5rem !important;
  }

  .mr-xxxl-1, .mx-xxxl-1 {
    margin-right: .5rem !important;
  }

  .mb-xxxl-1, .my-xxxl-1 {
    margin-bottom: .5rem !important;
  }

  .ml-xxxl-1, .mx-xxxl-1 {
    margin-left: .5rem !important;
  }

  .m-xxxl-2 {
    margin: 1rem !important;
  }

  .mt-xxxl-2, .my-xxxl-2 {
    margin-top: 1rem !important;
  }

  .mr-xxxl-2, .mx-xxxl-2 {
    margin-right: 1rem !important;
  }

  .mb-xxxl-2, .my-xxxl-2 {
    margin-bottom: 1rem !important;
  }

  .ml-xxxl-2, .mx-xxxl-2 {
    margin-left: 1rem !important;
  }

  .m-xxxl-3 {
    margin: 1.5rem !important;
  }

  .mt-xxxl-3, .my-xxxl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xxxl-3, .mx-xxxl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xxxl-3, .my-xxxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxxl-3, .mx-xxxl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xxxl-4 {
    margin: 2rem !important;
  }

  .mt-xxxl-4, .my-xxxl-4 {
    margin-top: 2rem !important;
  }

  .mr-xxxl-4, .mx-xxxl-4 {
    margin-right: 2rem !important;
  }

  .mb-xxxl-4, .my-xxxl-4 {
    margin-bottom: 2rem !important;
  }

  .ml-xxxl-4, .mx-xxxl-4 {
    margin-left: 2rem !important;
  }

  .m-xxxl-5 {
    margin: 2.5rem !important;
  }

  .mt-xxxl-5, .my-xxxl-5 {
    margin-top: 2.5rem !important;
  }

  .mr-xxxl-5, .mx-xxxl-5 {
    margin-right: 2.5rem !important;
  }

  .mb-xxxl-5, .my-xxxl-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxxl-5, .mx-xxxl-5 {
    margin-left: 2.5rem !important;
  }

  .m-xxxl-6 {
    margin: 3rem !important;
  }

  .mt-xxxl-6, .my-xxxl-6 {
    margin-top: 3rem !important;
  }

  .mr-xxxl-6, .mx-xxxl-6 {
    margin-right: 3rem !important;
  }

  .mb-xxxl-6, .my-xxxl-6 {
    margin-bottom: 3rem !important;
  }

  .ml-xxxl-6, .mx-xxxl-6 {
    margin-left: 3rem !important;
  }

  .m-xxxl-10 {
    margin: 5rem !important;
  }

  .mt-xxxl-10, .my-xxxl-10 {
    margin-top: 5rem !important;
  }

  .mr-xxxl-10, .mx-xxxl-10 {
    margin-right: 5rem !important;
  }

  .mb-xxxl-10, .my-xxxl-10 {
    margin-bottom: 5rem !important;
  }

  .ml-xxxl-10, .mx-xxxl-10 {
    margin-left: 5rem !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .pt-xxxl-0, .py-xxxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxxl-0, .px-xxxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxxl-0, .py-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxxl-0, .px-xxxl-0 {
    padding-left: 0 !important;
  }

  .p-xxxl-1 {
    padding: .5rem !important;
  }

  .pt-xxxl-1, .py-xxxl-1 {
    padding-top: .5rem !important;
  }

  .pr-xxxl-1, .px-xxxl-1 {
    padding-right: .5rem !important;
  }

  .pb-xxxl-1, .py-xxxl-1 {
    padding-bottom: .5rem !important;
  }

  .pl-xxxl-1, .px-xxxl-1 {
    padding-left: .5rem !important;
  }

  .p-xxxl-2 {
    padding: 1rem !important;
  }

  .pt-xxxl-2, .py-xxxl-2 {
    padding-top: 1rem !important;
  }

  .pr-xxxl-2, .px-xxxl-2 {
    padding-right: 1rem !important;
  }

  .pb-xxxl-2, .py-xxxl-2 {
    padding-bottom: 1rem !important;
  }

  .pl-xxxl-2, .px-xxxl-2 {
    padding-left: 1rem !important;
  }

  .p-xxxl-3 {
    padding: 1.5rem !important;
  }

  .pt-xxxl-3, .py-xxxl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xxxl-3, .px-xxxl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xxxl-3, .py-xxxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxxl-3, .px-xxxl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xxxl-4 {
    padding: 2rem !important;
  }

  .pt-xxxl-4, .py-xxxl-4 {
    padding-top: 2rem !important;
  }

  .pr-xxxl-4, .px-xxxl-4 {
    padding-right: 2rem !important;
  }

  .pb-xxxl-4, .py-xxxl-4 {
    padding-bottom: 2rem !important;
  }

  .pl-xxxl-4, .px-xxxl-4 {
    padding-left: 2rem !important;
  }

  .p-xxxl-5 {
    padding: 2.5rem !important;
  }

  .pt-xxxl-5, .py-xxxl-5 {
    padding-top: 2.5rem !important;
  }

  .pr-xxxl-5, .px-xxxl-5 {
    padding-right: 2.5rem !important;
  }

  .pb-xxxl-5, .py-xxxl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxxl-5, .px-xxxl-5 {
    padding-left: 2.5rem !important;
  }

  .p-xxxl-6 {
    padding: 3rem !important;
  }

  .pt-xxxl-6, .py-xxxl-6 {
    padding-top: 3rem !important;
  }

  .pr-xxxl-6, .px-xxxl-6 {
    padding-right: 3rem !important;
  }

  .pb-xxxl-6, .py-xxxl-6 {
    padding-bottom: 3rem !important;
  }

  .pl-xxxl-6, .px-xxxl-6 {
    padding-left: 3rem !important;
  }

  .p-xxxl-10 {
    padding: 5rem !important;
  }

  .pt-xxxl-10, .py-xxxl-10 {
    padding-top: 5rem !important;
  }

  .pr-xxxl-10, .px-xxxl-10 {
    padding-right: 5rem !important;
  }

  .pb-xxxl-10, .py-xxxl-10 {
    padding-bottom: 5rem !important;
  }

  .pl-xxxl-10, .px-xxxl-10 {
    padding-left: 5rem !important;
  }

  .m-xxxl-n1 {
    margin: -.5rem !important;
  }

  .mt-xxxl-n1, .my-xxxl-n1 {
    margin-top: -.5rem !important;
  }

  .mr-xxxl-n1, .mx-xxxl-n1 {
    margin-right: -.5rem !important;
  }

  .mb-xxxl-n1, .my-xxxl-n1 {
    margin-bottom: -.5rem !important;
  }

  .ml-xxxl-n1, .mx-xxxl-n1 {
    margin-left: -.5rem !important;
  }

  .m-xxxl-n2 {
    margin: -1rem !important;
  }

  .mt-xxxl-n2, .my-xxxl-n2 {
    margin-top: -1rem !important;
  }

  .mr-xxxl-n2, .mx-xxxl-n2 {
    margin-right: -1rem !important;
  }

  .mb-xxxl-n2, .my-xxxl-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-xxxl-n2, .mx-xxxl-n2 {
    margin-left: -1rem !important;
  }

  .m-xxxl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xxxl-n3, .my-xxxl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xxxl-n3, .mx-xxxl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xxxl-n3, .my-xxxl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxxl-n3, .mx-xxxl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xxxl-n4 {
    margin: -2rem !important;
  }

  .mt-xxxl-n4, .my-xxxl-n4 {
    margin-top: -2rem !important;
  }

  .mr-xxxl-n4, .mx-xxxl-n4 {
    margin-right: -2rem !important;
  }

  .mb-xxxl-n4, .my-xxxl-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-xxxl-n4, .mx-xxxl-n4 {
    margin-left: -2rem !important;
  }

  .m-xxxl-n5 {
    margin: -2.5rem !important;
  }

  .mt-xxxl-n5, .my-xxxl-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-xxxl-n5, .mx-xxxl-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-xxxl-n5, .my-xxxl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxxl-n5, .mx-xxxl-n5 {
    margin-left: -2.5rem !important;
  }

  .m-xxxl-n6 {
    margin: -3rem !important;
  }

  .mt-xxxl-n6, .my-xxxl-n6 {
    margin-top: -3rem !important;
  }

  .mr-xxxl-n6, .mx-xxxl-n6 {
    margin-right: -3rem !important;
  }

  .mb-xxxl-n6, .my-xxxl-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-xxxl-n6, .mx-xxxl-n6 {
    margin-left: -3rem !important;
  }

  .m-xxxl-n10 {
    margin: -5rem !important;
  }

  .mt-xxxl-n10, .my-xxxl-n10 {
    margin-top: -5rem !important;
  }

  .mr-xxxl-n10, .mx-xxxl-n10 {
    margin-right: -5rem !important;
  }

  .mb-xxxl-n10, .my-xxxl-n10 {
    margin-bottom: -5rem !important;
  }

  .ml-xxxl-n10, .mx-xxxl-n10 {
    margin-left: -5rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mt-xxxl-auto, .my-xxxl-auto {
    margin-top: auto !important;
  }

  .mr-xxxl-auto, .mx-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-auto, .my-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxxl-auto, .mx-xxxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xxxl-left {
    text-align: left !important;
  }

  .text-xxxl-right {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4e4e4e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #282828 !important;
}

.text-secondary {
  color: #fff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #4e4e4e !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.btn-plain, .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .slick-arrow, .content-carousel.slick-slider .slick-arrow {
  -webkit-appearance: none;
  background: none;
  border: 0;
  -webkit-border-radius: 0;
  margin: 0;
  padding: 0;
}

html.fp-enabled, .fp-enabled body {
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#superContainer {
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  position: relative;
}

.fp-section {
  box-sizing: border-box;
  position: relative;
}

.fp-slide {
  float: left;
}

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  transition: all .3s ease-out;
  position: relative;
  overflow: hidden;
}

.fp-section.fp-table, .fp-slide.fp-table {
  table-layout: fixed;
  width: 100%;
  display: table;
}

.fp-tableCell {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: table-cell;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  z-index: 4;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  position: absolute;
  top: 50%;
  transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: #0000 #fff #0000 #0000;
  left: 15px;
}

.fp-controlArrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: #0000 #0000 #0000 #fff;
  right: 15px;
}

.fp-scrollable {
  position: relative;
  overflow: hidden;
}

.fp-scroller {
  overflow: hidden;
}

.iScrollIndicator {
  border: 0 !important;
}

.fp-notransition {
  transition: none !important;
}

#fp-nav {
  z-index: 100;
  opacity: 1;
  margin-top: -32px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate3d(0, 0, 0);
}

#fp-nav.right {
  right: 17px;
}

#fp-nav.left {
  left: 17px;
}

.fp-slidesNav {
  z-index: 4;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translate3d(0, 0, 0);
}

.fp-slidesNav.bottom {
  bottom: 17px;
}

.fp-slidesNav.top {
  top: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 14px;
  height: 13px;
  margin: 7px;
  display: block;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  display: block;
  position: relative;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin: -6px 0 0 -6px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1;
  height: 4px;
  width: 4px;
  -o-transition: all .1s ease-in-out;
  background: #333;
  border: 0;
  border-radius: 50%;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  color: #fff;
  white-space: nowrap;
  max-width: 220px;
  opacity: 0;
  width: 0;
  cursor: pointer;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -2px;
  overflow: hidden;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  width: auto;
  opacity: 1;
  transition: opacity .2s ease-in;
}

#fp-nav ul li .fp-tooltip.right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.left {
  left: 20px;
}

.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section, .fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important;
}

.section-aim .section-bottom .carousel.slick-slider .slick-track:before, .about-project-book-wrapper .carousel-text.slick-slider .slick-track:before, .about-project-book-wrapper .carousel-nav.slick-slider .slick-track:before, .about-project-wrapper .carousel-text.slick-slider .slick-track:before, .about-project-wrapper .carousel-nav.slick-slider .slick-track:before, .home-slides .section-knowledge .content .text-wrapper.slick-slider .slick-track:before, .home-slides .section-projects .carousel.slick-slider .slick-track:before, .multimedia-block-carousel .slick-slider .slick-track:before, .content-carousel.slick-slider .slick-track:before, .page-section-interesting .carousel.slick-slider .slick-track:before, .page-section-projects .carousel.slick-slider .slick-track:before, .section-aim .section-bottom .carousel.slick-slider .slick-track:after, .about-project-book-wrapper .carousel-text.slick-slider .slick-track:after, .about-project-book-wrapper .carousel-nav.slick-slider .slick-track:after, .about-project-wrapper .carousel-text.slick-slider .slick-track:after, .about-project-wrapper .carousel-nav.slick-slider .slick-track:after, .home-slides .section-knowledge .content .text-wrapper.slick-slider .slick-track:after, .home-slides .section-projects .carousel.slick-slider .slick-track:after, .multimedia-block-carousel .slick-slider .slick-track:after, .content-carousel.slick-slider .slick-track:after, .page-section-interesting .carousel.slick-slider .slick-track:after, .page-section-projects .carousel.slick-slider .slick-track:after {
  content: "";
  display: table;
}

.section-aim .section-bottom .carousel.slick-slider .slick-track:after, .about-project-book-wrapper .carousel-text.slick-slider .slick-track:after, .about-project-book-wrapper .carousel-nav.slick-slider .slick-track:after, .about-project-wrapper .carousel-text.slick-slider .slick-track:after, .about-project-wrapper .carousel-nav.slick-slider .slick-track:after, .home-slides .section-knowledge .content .text-wrapper.slick-slider .slick-track:after, .home-slides .section-projects .carousel.slick-slider .slick-track:after, .multimedia-block-carousel .slick-slider .slick-track:after, .content-carousel.slick-slider .slick-track:after, .page-section-interesting .carousel.slick-slider .slick-track:after, .page-section-projects .carousel.slick-slider .slick-track:after {
  clear: both;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  visibility: visible;
  position: relative;
}

* {
  min-height: 0;
  min-width: 0;
}

a {
  color: inherit;
  border: 0;
  outline: none;
  text-decoration: none;
}

.content-offset ul, .text-container ul, .content-text-image ul {
  margin-bottom: 2rem;
}

.content-offset ul li, .text-container ul li, .content-text-image ul li {
  color: #4e4e4e;
  padding-left: 26px;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.63636;
  text-decoration: none;
  position: relative;
}

@media (max-width: 1200px) {
  .content-offset ul li, .text-container ul li, .content-text-image ul li {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .content-offset ul li, .text-container ul li, .content-text-image ul li {
    color: #4e4e4e;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .content-offset ul li, .text-container ul li, .content-text-image ul li {
    font-size: calc(14.4px + .3vw);
  }
}

.content-offset ul li:before, .text-container ul li:before, .content-text-image ul li:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #0095c6;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 0;
}

@media (max-width: 767.98px) {
  .content-offset ul li:before, .text-container ul li:before, .content-text-image ul li:before {
    top: 9px;
  }
}

@media (max-width: 991.98px) {
  .content-offset .table-overflow, .text-container .table-overflow, .content-text-image .table-overflow {
    overflow: auto;
  }
}

.content-offset table, .text-container table, .content-text-image table {
  width: 100%;
}

.content-offset table tr th, .content-offset table tr td, .text-container table tr th, .text-container table tr td, .content-text-image table tr th, .content-text-image table tr td {
  color: #4e4e4e;
  border-bottom: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
  padding: .5rem 1rem;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .content-offset table tr th, .content-offset table tr td, .text-container table tr th, .text-container table tr td, .content-text-image table tr th, .content-text-image table tr td {
    min-width: 300px;
  }
}

.content-offset table tr th:first-child, .content-offset table tr td:first-child, .text-container table tr th:first-child, .text-container table tr td:first-child, .content-text-image table tr th:first-child, .content-text-image table tr td:first-child {
  padding-left: 0;
}

.content-offset table tr th:last-child, .content-offset table tr td:last-child, .text-container table tr th:last-child, .text-container table tr td:last-child, .content-text-image table tr th:last-child, .content-text-image table tr td:last-child {
  border-right: 0;
}

@media (max-width: 1200px) {
  .content-offset table tr th, .content-offset table tr td, .text-container table tr th, .text-container table tr td, .content-text-image table tr th, .content-text-image table tr td {
    font-size: calc(14.2px + .15vw);
  }
}

.content-offset table tr th p, .content-offset table tr td p, .text-container table tr th p, .text-container table tr td p, .content-text-image table tr th p, .content-text-image table tr td p {
  color: #4e4e4e;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-offset table tr th p, .content-offset table tr td p, .text-container table tr th p, .text-container table tr td p, .content-text-image table tr th p, .content-text-image table tr td p {
    font-size: calc(14.2px + .15vw);
  }
}

.filters-toolbar {
  margin-bottom: 2.5rem;
  padding: 3.75rem 0;
}

@media (max-width: 767.98px) {
  .filters-toolbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    overflow: auto;
  }

  .filters-toolbar::-webkit-scrollbar {
    display: none;
  }
}

.filters-toolbar-wrapper {
  width: 100%;
  max-width: 1560px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

@media (max-width: 1599.98px) {
  .filters-toolbar-wrapper {
    max-width: 1160px;
  }
}

@media (max-width: 767.98px) {
  .filters-toolbar-wrapper {
    justify-content: flex-start;
  }
}

.filters-toolbar-wrapper span {
  color: #52525280;
  text-transform: uppercase;
  margin: 0 .75rem;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  display: block;
}

@media (max-width: 1200px) {
  .filters-toolbar-wrapper span {
    font-size: calc(14.6px + .45vw);
  }
}

@media (max-width: 767.98px) {
  .filters-toolbar-wrapper span {
    flex: none;
  }
}

.filters-toolbar-wrapper .menu {
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

@media (max-width: 767.98px) {
  .filters-toolbar-wrapper .menu {
    flex: none;
    justify-content: flex-start;
  }
}

.filters-toolbar-wrapper .menu li {
  margin: 0 .75rem;
}

@media (max-width: 767.98px) {
  .filters-toolbar-wrapper .menu li {
    flex: none;
  }

  .filters-toolbar-wrapper .menu li.active {
    order: -1;
  }
}

.filters-toolbar-wrapper .menu li.active a {
  color: #525252;
  font-weight: 800;
}

.filters-toolbar-wrapper .menu a {
  color: #969696;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .filters-toolbar-wrapper .menu a {
    font-size: calc(14.6px + .45vw);
  }
}

.page-pager {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .page-pager {
    margin-bottom: 4.5rem;
  }
}

@media (min-width: 992px) {
  .page-pager {
    margin-bottom: 7.5rem;
  }
}

.page-pager .pager {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0 1rem;
  display: flex;
}

.page-pager .pager .pager-item.pager-prev {
  margin-right: 1.5rem;
}

.page-pager .pager .pager-item.pager-prev a {
  padding: 1rem;
  line-height: 0;
  display: block;
}

.page-pager .pager .pager-item.pager-prev a svg {
  width: 8px;
  height: 20px;
}

.page-pager .pager .pager-item.pager-next {
  margin-left: 1.5rem;
}

.page-pager .pager .pager-item.pager-next a {
  padding: 1rem;
  line-height: 0;
  display: block;
}

.page-pager .pager .pager-item.pager-next a svg {
  width: 8px;
  height: 20px;
}

.page-pager .pager .pager-item.pager-current a {
  font-weight: 900;
}

.page-pager .pager .pager-item a {
  color: #151515;
  padding: 1rem .5rem;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-pager .pager .pager-item a {
    font-size: calc(14.6px + .45vw);
  }
}

.breadcrumbs {
  align-items: center;
  margin-bottom: 1.75rem;
  display: flex;
  overflow: hidden;
}

.breadcrumbs .breadcrumb-item {
  margin-left: 2.75rem;
  position: relative;
}

.breadcrumbs .breadcrumb-item:before {
  content: "";
  width: 7px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%230095C6'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 7px 12px;
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
}

@media (max-width: 991.98px) {
  .breadcrumbs .breadcrumb-item:before {
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23FFFFFF'/%3E%3C/svg%3E");
  }
}

.breadcrumbs .breadcrumb-item:first-child {
  margin-left: 0;
}

.breadcrumbs .breadcrumb-item a {
  color: #0095c6;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .breadcrumbs .breadcrumb-item a {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 991.98px) {
  .breadcrumbs .breadcrumb-item a {
    color: #fff;
  }
}

.page-section {
  position: relative;
}

@media (min-width: 992px) {
  .page-section {
    height: 100vh;
    min-height: 800px;
  }
}

.page-section .background {
  position: absolute;
  inset: 0;
}

@media (min-width: 992px) {
  .page-section .background {
    height: 100%;
    width: 100%;
    position: relative;
    inset: auto;
  }
}

.page-section .background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-section .background img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .page-section .background img {
    display: none;
  }

  .page-section .background img.mobile {
    display: block;
  }
}

.page-section-approach {
  height: auto;
  min-height: 0;
}

.page-section-approach .background {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.page-section-approach .background:before {
  content: "";
  z-index: 1;
  mix-blend-mode: multiply;
  background: linear-gradient(#3a3a3a00 6.87%, #1d1d1d85 50.72%);
  position: absolute;
  inset: 0;
}

.page-section-approach .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 4rem 1rem;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .page-section-approach .content {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .page-section-approach .content {
    padding: 4rem 1rem;
  }
}

@media (min-width: 1600px) {
  .page-section-approach .content {
    padding: 104px 1rem 4rem;
  }
}

.page-section-approach .content h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 1.625rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-approach .content h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 768px) {
  .page-section-approach .content h2 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1600px) {
  .page-section-approach .content h2 {
    color: #f2f2f2;
    margin-bottom: 6.5rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-approach .content h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.page-section-approach .content h2 strong {
  font-weight: 700;
}

.page-section-approach .content .text-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 991.98px) {
  .page-section-approach .content .text-wrapper {
    flex-wrap: wrap;
  }
}

.page-section-approach .content .text {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 6.5rem;
}

@media (max-width: 991.98px) {
  .page-section-approach .content .text:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .page-section-approach .content .text {
    width: 40%;
    flex: 0 0 40%;
    margin-bottom: 0;
    padding-right: 6rem;
  }
}

.page-section-approach .content h3 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-approach .content h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 1599.98px) {
  .page-section-approach .content h3 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-section-approach .content h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

.page-section-approach .content p {
  color: #f2f2f2;
  margin-bottom: 3.75rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.31818;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-approach .content p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .page-section-approach .content p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.38889;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-section-approach .content p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 991.98px) {
  .page-section-approach .content p {
    margin-bottom: 1.5rem;
    line-height: 1.75;
  }
}

.page-section-approach .content .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-approach .content .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-approach .content .link-bullet:first-child {
  margin-left: 0;
}

.page-section-projects {
  height: auto;
  min-height: 0;
  overflow: hidden;
}

.page-section-projects .background {
  position: absolute;
  inset: 0;
}

.page-section-projects .background:before {
  display: none;
}

.page-section-projects .background.background-color {
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
}

.page-section-projects .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2.25rem 1rem;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .page-section-projects .content {
    padding: 104px 1rem 4rem;
  }
}

@media (max-width: 1599.98px) {
  .page-section-projects .content {
    max-width: 1160px;
  }
}

.page-section-projects .content:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
  position: absolute;
  inset: 0 calc(100% - 1rem) 0 -999em;
}

.page-section-projects .content h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-projects .content h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .page-section-projects .content h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1600px) {
  .page-section-projects .content h2 {
    color: #f2f2f2;
    margin-bottom: 4rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-projects .content h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.page-section-projects .content h2 br {
  display: none;
}

.page-section-projects .content h2 strong {
  font-weight: 700;
}

.page-section-projects .carousel {
  margin-bottom: 3rem;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
}

@media (min-width: 768px) {
  .page-section-projects .carousel {
    align-items: stretch;
    margin: 0 -1.5rem 3rem;
    display: flex;
  }
}

@media (min-width: 1600px) {
  .page-section-projects .carousel {
    align-items: stretch;
    margin: 0 -3rem 3rem;
    display: flex;
  }
}

.Safari .page-section-projects .carousel {
  transform: translateX(1rem);
}

.page-section-projects .carousel.slick-slider .slick-prev {
  display: none !important;
}

.page-section-projects .carousel.slick-slider .slick-track {
  align-items: stretch;
  display: flex;
}

.page-section-projects .carousel.slick-slider .slick-slide {
  float: left;
  width: 160px;
  margin-right: 4rem;
}

@media (min-width: 768px) {
  .page-section-projects .carousel.slick-slider .slick-slide {
    width: auto;
    margin-right: 0;
    padding: 0 1.5rem;
  }
}

@media (min-width: 1600px) {
  .page-section-projects .carousel.slick-slider .slick-slide {
    padding: 0 3.5rem;
  }
}

.page-section-projects .carousel.slick-slider .slick-slide > div {
  height: 100%;
}

.page-section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .title {
  opacity: 1;
}

.page-section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .text {
  opacity: 1;
}

.page-section-projects .carousel .carousel-item {
  height: 100%;
  position: relative;
}

.page-section-projects .carousel .carousel-item-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.page-section-projects .carousel .carousel-item .title {
  opacity: .7;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58);
  display: flex;
}

.page-section-projects .carousel .carousel-item .title h3 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: .888889;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-projects .carousel .carousel-item .title h3 {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 1600px) {
  .page-section-projects .carousel .carousel-item .title h3 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-projects .carousel .carousel-item .title h3 {
    font-size: calc(14.8px + .6vw);
  }
}

.page-section-projects .carousel .carousel-item .image {
  height: 200px;
  width: 100%;
  flex: none;
  margin-bottom: 1.25rem;
  position: relative;
}

@media (min-width: 1200px) {
  .page-section-projects .carousel .carousel-item .image {
    height: 280px;
  }
}

@media (min-width: 1600px) {
  .page-section-projects .carousel .carousel-item .image {
    height: 320px;
  }
}

.page-section-projects .carousel .carousel-item .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: width .4s cubic-bezier(.47, .17, .17, .58), height .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.page-section-projects .carousel .carousel-item .visit {
  color: #fff;
  text-transform: uppercase;
  margin-top: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-projects .carousel .carousel-item .visit {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 767.98px) {
  .page-section-projects .carousel .carousel-item .visit {
    width: 100%;
    flex: 0 0 100%;
    order: 2;
  }
}

.page-section-projects .carousel .carousel-item .text {
  opacity: .7;
  flex: none;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58);
}

.page-section-projects .carousel .carousel-item .text p {
  color: #f2f2f2;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28571;
  text-decoration: none;
}

@media (min-width: 1600px) {
  .page-section-projects .carousel .carousel-item .text p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-projects .carousel .carousel-item .text p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .page-section-projects .carousel .carousel-item .text p {
    font-size: .875rem;
  }
}

.page-section-projects .links {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 767.98px) {
  .page-section-projects .links {
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1400px) {
  .page-section-projects .links {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.page-section-projects .links .visit {
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-projects .links .visit {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 767.98px) {
  .page-section-projects .links .visit {
    width: 100%;
    flex: 0 0 100%;
    order: 2;
  }
}

.page-section-projects .links .btn-carousel-right {
  color: #e9e9e9;
  text-transform: uppercase;
  padding-right: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.28571;
  text-decoration: none;
  transition: padding-right .4s cubic-bezier(.47, .17, .17, .58);
  position: relative;
}

.page-section-projects .links .btn-carousel-right:after {
  content: "";
  width: 35px;
  height: 31px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px 31px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .page-section-projects .links .btn-carousel-right {
    width: 100%;
    text-align: right;
    flex: 0 0 100%;
    margin-bottom: 1.5rem;
  }
}

.page-section-projects .links .btn-carousel-right:hover:after {
  opacity: 0;
  visibility: hidden;
  right: -24px;
}

.page-section-interesting {
  height: auto;
  min-height: 0;
  background-color: #e9e9e9;
  overflow: hidden;
}

.page-section-interesting .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .page-section-interesting .content {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .page-section-interesting .content {
    padding: 3rem 1rem;
  }
}

@media (min-width: 992px) {
  .page-section-interesting .content {
    justify-content: center;
    padding: 3.5rem 1rem 2.5rem;
  }
}

@media (min-width: 1200px) {
  .page-section-interesting .content {
    padding: 4.5rem 1rem 2.5rem;
  }
}

.page-section-interesting .content:before {
  content: "";
  z-index: 1;
  background: #e9e9e9;
  position: absolute;
  inset: 0 calc(100% - 1rem) 0 -999em;
}

.page-section-interesting .content h2 {
  color: #2e2e2e;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-interesting .content h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 768px) {
  .page-section-interesting .content h2 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1600px) {
  .page-section-interesting .content h2 {
    color: #2e2e2e;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-interesting .content h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.page-section-interesting .content h2 strong {
  font-weight: 700;
}

.page-section-interesting .carousel {
  margin-bottom: 3rem;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
}

.Safari .page-section-interesting .carousel {
  transform: translateX(1rem);
}

.page-section-interesting .carousel.slick-slider .slick-prev {
  display: none !important;
}

.page-section-interesting .carousel.slick-slider .slick-track {
  align-items: stretch;
  display: flex;
}

.page-section-interesting .carousel.slick-slider .slick-slide {
  float: left;
  width: 240px;
  margin-right: 2rem;
}

@media (min-width: 768px) {
  .page-section-interesting .carousel.slick-slider .slick-slide {
    width: 500px;
    margin-right: 3rem;
  }
}

@media (min-width: 1200px) {
  .page-section-interesting .carousel.slick-slider .slick-slide {
    width: 600px;
    margin-right: 5rem;
  }
}

.page-section-interesting .carousel.slick-slider .slick-slide > div {
  height: 100%;
}

.page-section-interesting .carousel .carousel-item {
  height: 100%;
  position: relative;
}

.page-section-interesting .carousel .carousel-item-wrapper {
  height: 100%;
}

@media (min-width: 768px) {
  .page-section-interesting .carousel .carousel-item-wrapper {
    align-items: center;
    display: flex;
  }
}

.page-section-interesting .carousel .carousel-item .image {
  position: relative;
}

@media (min-width: 768px) {
  .page-section-interesting .carousel .carousel-item .image {
    width: 45%;
    flex: 0 0 45%;
  }
}

.page-section-interesting .carousel .carousel-item .image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  height: 235px;
}

@media (min-width: 768px) {
  .page-section-interesting .carousel .carousel-item .image img {
    height: 264px;
  }
}

.page-section-interesting .carousel .carousel-item .text {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .page-section-interesting .carousel .carousel-item .text {
    width: 55%;
    flex: 0 0 55%;
    margin-bottom: 0;
    padding-left: 2rem;
  }
}

.page-section-interesting .carousel .carousel-item .text h3 {
  color: #151515;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-interesting .carousel .carousel-item .text h3 {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 1599.98px) {
  .page-section-interesting .carousel .carousel-item .text h3 {
    color: #151515;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.27273;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-section-interesting .carousel .carousel-item .text h3 {
    font-size: calc(14.8px + .6vw);
  }
}

.page-section-interesting .carousel .carousel-item .text p {
  color: #2e2e2e;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: none;
}

@media (max-width: 1200px) {
  .page-section-interesting .carousel .carousel-item .text p {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-interesting .carousel .carousel-item .text .link-bullet {
  color: #333;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33333;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-interesting .carousel .carousel-item .text .link-bullet {
    font-size: calc(14.4px + .3vw);
  }
}

.page-section-interesting .links {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.page-section-interesting .links .btn-carousel-right {
  color: #333;
  text-transform: uppercase;
  padding-right: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.28571;
  text-decoration: none;
  transition: padding-right .4s cubic-bezier(.47, .17, .17, .58);
  position: relative;
}

.page-section-interesting .links .btn-carousel-right:after {
  content: "";
  width: 35px;
  height: 31px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23333333' stroke='%23333333'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23333333'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23333333' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px 31px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.page-section-interesting .links .btn-carousel-right:hover:after {
  opacity: 0;
  visibility: hidden;
  right: -24px;
}

.page-section-subscribe {
  background: linear-gradient(90.24deg, #17a2b8bf 43.33%, #48c5d9bf 69.49%, #17a2b8bf 90.58%);
  overflow: hidden;
}

.page-section-subscribe-wrapper {
  z-index: 2;
  height: 100%;
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .page-section-subscribe-wrapper {
    padding: 2rem 1rem;
  }
}

@media (min-width: 992px) {
  .page-section-subscribe-wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media (max-width: 1599.98px) {
  .page-section-subscribe-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 992px) {
  .page-section-subscribe .content {
    width: 60%;
    flex: 0 0 60%;
  }
}

.page-section-subscribe .content .title {
  margin-bottom: 38px;
}

.page-section-subscribe .content .title h2 {
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .title h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 1600px) {
  .page-section-subscribe .content .title h2 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-section-subscribe .content .title h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.page-section-subscribe .content .title h2 strong {
  font-weight: 700;
}

.page-section-subscribe .content .text {
  max-width: 560px;
}

.page-section-subscribe .content .text p {
  color: #fff;
  margin-bottom: 3.75rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.81818;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .text p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .page-section-subscribe .content .text p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 2;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-section-subscribe .content .text p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .page-section-subscribe .content .text p {
    color: #fff;
    margin-bottom: 2rem;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .page-section-subscribe .content .text p {
    font-size: calc(14.4px + .3vw);
  }
}

.page-section-subscribe .content .form {
  max-width: 560px;
}

.page-section-subscribe .content .form .wpcf7-list-item {
  margin: 0;
}

.page-section-subscribe .content .form .wpcf7-not-valid-tip {
  padding-left: 30px;
}

.page-section-subscribe .content .form .wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
  color: #ff0202;
  border: 0;
  padding: 10px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .form .wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-subscribe .content .form .wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
  color: #fff;
  border: 0;
  padding: 10px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .form .wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-subscribe .content .form div.wpcf7-mail-sent-ok:before, .page-section-subscribe .content .form div.wpcf7-validation-errors:before {
  vertical-align: middle;
  margin-right: 16px;
  font: 26px / 30px dashicons;
  display: none;
}

.page-section-subscribe .content .form div.wpcf7-mail-sent-ok:before {
  content: "";
  display: none;
}

.page-section-subscribe .content .form div.wpcf7-validation-errors:before {
  content: "";
  display: none;
}

.page-section-subscribe .content .form form .form-items {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
}

.page-section-subscribe .content .form form .form-items .form-email {
  flex: 1 0 auto;
  padding-right: 18px;
}

@media (max-width: 767.98px) {
  .page-section-subscribe .content .form form .form-items .form-email {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-right: 0;
  }
}

.page-section-subscribe .content .form form .form-items .form-email input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .form form .form-items .form-email input {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-subscribe .content .form form .form-items .form-email input::placeholder {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .form form .form-items .form-email input::placeholder {
    font-size: calc(14.2px + .15vw);
  }
}

.page-section-subscribe .content .form form .form-items .form-email.wpcf7-not-valid {
  border-color: #ff0202;
}

.page-section-subscribe .content .form form .form-items .form-email .wpcf7-not-valid-tip {
  margin-top: 4px;
}

.page-section-subscribe .content .form form .form-items .form-checkbox {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .page-section-subscribe .content .form form .form-items .form-checkbox {
    order: 3;
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.page-section-subscribe .content .form form .form-items .form-checkbox .wpcf7-list-item {
  margin: 0;
}

.page-section-subscribe .content .form form .form-items .form-checkbox .wpcf7-form-control-wrap, .page-section-subscribe .content .form form .form-items .form-checkbox .wpcf7-form-control-wrap .wpcf7-form-control {
  display: block;
}

.page-section-subscribe .content .form form .form-items .form-checkbox .wpcf7-form-control-wrap .wpcf7-not-valid input[type="checkbox"] + span:before {
  border-color: #ff0202;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"] {
  display: none;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"] + span {
  color: #fff;
  cursor: pointer;
  padding-left: 28px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.21429;
  text-decoration: none;
  display: block;
  position: relative;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"] + span:before {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 1px solid #fff;
  border-radius: 2px;
  transition: border-color .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 4px;
  left: 0;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"] + span:after {
  content: "";
  height: .5rem;
  width: .5rem;
  opacity: 0;
  background-color: #fff;
  border-radius: 2px;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 8px;
  left: 4px;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"]:checked + span:before {
  border-color: #fff;
}

.page-section-subscribe .content .form form .form-items .form-checkbox input[type="checkbox"]:checked + span:after {
  opacity: 1;
  background-color: #fff;
}

.page-section-subscribe .content .form form .form-items .form-checkbox .wpcf7-not-valid-tip {
  padding-left: 44px;
}

.page-section-subscribe .content .form form .form-items .form-submit input {
  color: #fff;
  text-transform: uppercase;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .page-section-subscribe .content .form form .form-items .form-submit input {
    font-size: calc(15px + .75vw);
  }
}

.page-section-subscribe .content .form form .form-items .form-submit input:hover {
  color: #000;
  background-color: #fff9;
}

@media (max-width: 991.98px) {
  .page-section-subscribe .image {
    text-align: right;
    z-index: -1;
    opacity: .25;
    position: absolute;
    inset: 0;
  }
}

@media (min-width: 992px) {
  .page-section-subscribe .image {
    width: 35%;
    text-align: center;
    flex: 0 0 35%;
    margin-right: auto;
  }
}

@media (min-width: 1600px) {
  .page-section-subscribe .image {
    width: 30%;
    flex: 0 0 30%;
    margin-right: auto;
  }
}

@media (max-width: 991.98px) {
  .page-section-subscribe .image img {
    width: auto;
    height: 100%;
    position: relative;
    transform: translateX(50%);
  }
}

@media (max-width: 767.98px) {
  .page-section-subscribe .image img {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateX(50%);
  }
}

@media (min-width: 992px) {
  .page-section-subscribe .image img {
    width: 100%;
    height: auto;
  }
}

.cart-sidebar {
  z-index: 9999;
  width: 100%;
  max-width: 412px;
  background-color: #f4f4f4;
  transition: transform .4s cubic-bezier(.47, .17, .17, .58);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
}

@media (min-width: 768px) {
  .cart-sidebar {
    width: 80%;
  }
}

.sidebar--active .cart-sidebar {
  transform: translateX(0);
}

.cart-sidebar--wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.25rem;
  display: flex;
}

.cart-sidebar .close {
  color: #2aaec3;
  text-transform: uppercase;
  margin-bottom: 3rem;
  margin-left: auto;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

.cart-sidebar .cart-content {
  border-top: 1px solid #2aaec3;
  margin-bottom: auto;
  padding-top: 1.25rem;
  overflow: auto;
}

.cart-sidebar .cart-content .element {
  padding-bottom: 1.5rem;
}

.cart-sidebar .cart-content .element--wrapper {
  align-items: flex-end;
  display: flex;
}

.cart-sidebar .cart-content .element .image {
  width: 5rem;
  flex: 0 0 5rem;
}

.cart-sidebar .cart-content .element .image img {
  width: 100%;
  height: auto;
}

.cart-sidebar .cart-content .element .content {
  width: 100%;
  margin-bottom: .25rem;
  padding-left: .75rem;
}

.cart-sidebar .cart-content .element .content .remove {
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 9'%3E%3Cg stroke='%232AAEC3' opacity='.6'%3E%3Cpath d='M1.258 8.536L9.036.757M1.354.646l7.778 7.779'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 1rem;
  margin-left: auto;
  display: flex;
}

.cart-sidebar .cart-content .element .content .title {
  color: #2aaec3;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.05556;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cart-sidebar .cart-content .element .content .title {
    font-size: calc(14.4px + .3vw);
  }
}

.cart-sidebar .cart-content .element .content .description {
  color: #2aaec3;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cart-sidebar .cart-content .element .content .description {
    font-size: calc(14.2px + .15vw);
  }
}

.cart-sidebar .cart-content .element .content .options {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cart-sidebar .cart-content .element .content .options .qty {
  align-items: center;
  display: flex;
}

.cart-sidebar .cart-content .element .content .options .qty span {
  color: #2aaec3;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14286;
  text-decoration: none;
}

.cart-sidebar .cart-content .element .content .options .qty .quantity {
  width: 3rem;
  margin-left: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  position: relative;
}

.cart-sidebar .cart-content .element .content .options .qty .quantity-nav {
  position: absolute;
  inset: 0;
}

.cart-sidebar .cart-content .element .content .options .qty .quantity-nav .quantity-button {
  cursor: pointer;
  width: .5rem;
  height: .5rem;
  position: absolute;
}

.cart-sidebar .cart-content .element .content .options .qty .quantity-nav .quantity-down {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 3 4'%3E%3Cpath fill='%232AAEC3' d='M0 2L3 .268v3.464L0 2z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.cart-sidebar .cart-content .element .content .options .qty .quantity-nav .quantity-up {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 3 4'%3E%3Cpath fill='%232AAEC3' d='M3 2L0 3.732V.268L3 2z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.cart-sidebar .cart-content .element .content .options .qty input[type="number"] {
  height: 1rem;
  width: 1rem;
  -webkit-appearance: textfield;
  appearance: textfield;
  text-align: center;
  color: #2aaec3;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0 .25rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14286;
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cart-sidebar .cart-content .element .content .options .qty input[type="number"]::-webkit-inner-spin-button, .cart-sidebar .cart-content .element .content .options .qty input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.cart-sidebar .cart-content .element .content .options .qty input[type="number"]::placeholder {
  color: #2aaec3;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14286;
  text-decoration: none;
}

.cart-sidebar .cart-content .element .content .options .price {
  color: #2aaec3;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cart-sidebar .cart-content .element .content .options .price {
    font-size: calc(14.2px + .15vw);
  }
}

.cart-sidebar .cart-footer {
  flex: none;
}

@media (min-width: 768px) {
  .cart-sidebar .cart-footer {
    margin-bottom: 2rem;
  }
}

.cart-sidebar .cart-footer .subtotal {
  border-top: 1px solid #2aaec3;
  border-bottom: 1px solid #2aaec3;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: .5rem .125rem;
  display: flex;
}

.cart-sidebar .cart-footer .subtotal span {
  color: #2aaec3;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.875;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cart-sidebar .cart-footer .subtotal span {
    font-size: calc(14.2px + .15vw);
  }
}

.cart-sidebar .cart-footer .subtotal .price {
  color: #2aaec3;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.875;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cart-sidebar .cart-footer .subtotal .price {
    font-size: calc(14.2px + .15vw);
  }
}

.cart-sidebar .cart-footer .actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cart-sidebar .cart-footer .actions .show {
  color: #2aaec3;
  text-transform: uppercase;
  background-color: #0000;
  border: 1px solid #2aaec3;
  padding: .75rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58), border-color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

.cart-sidebar .cart-footer .actions .show:hover {
  color: #0095c6;
  border: 1px solid #0095c6;
}

.cart-sidebar .cart-footer .actions .checkout {
  color: #fff;
  text-transform: uppercase;
  background-color: #2aaec3;
  border: 1px solid #2aaec3;
  padding: .75rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58), border-color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

.cart-sidebar .cart-footer .actions .checkout:hover {
  color: #fff;
  background-color: #0095c6;
  border: 1px solid #0095c6;
}

.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .header-container.default svg path {
  fill: #fff;
}

.header .header-container.default .languages {
  margin-left: auto;
  margin-right: 2rem;
}

.header .header-container.default .languages a {
  color: #fff;
  text-decoration: none;
}

.header .header-container.default .hamburger .btn-menu {
  width: 54px;
  height: 54px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: relative;
}

.header .header-container.default .hamburger .btn-menu span {
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.default .hamburger .btn-menu span:before {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.default .hamburger .btn-menu span:after {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(50%);
}

.header .header-container.blue svg path {
  fill: #fff;
}

.header .header-container.blue .languages {
  margin-left: auto;
  margin-right: 2rem;
}

.header .header-container.blue .languages a {
  color: #fff;
}

.header .header-container.blue .hamburger .btn-menu {
  width: 54px;
  height: 54px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: relative;
}

.header .header-container.blue .hamburger .btn-menu span {
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.blue .hamburger .btn-menu span:before {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.blue .hamburger .btn-menu span:after {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(50%);
}

.header .header-container.logo-blue svg path {
  fill: #0095c6;
}

.header .header-container.logo-blue .cart .nr {
  color: #fff;
}

.header .header-container.logo-blue .login-eko svg path.gray {
  fill: #636466;
}

.header .header-container.logo-blue .login-eko svg path.green {
  fill: #aeab49;
}

.header .header-container.logo-blue .login-eko svg path.red {
  fill: #d71920;
}

.header .header-container.logo-blue .login-aphrodite svg path.dark {
  fill: #0b1e41;
}

.header .header-container.logo-blue .login-aphrodite svg path.green {
  fill: #5cc7d3;
}

.header .header-container.logo-blue .languages {
  margin-left: auto;
  margin-right: 2rem;
}

.header .header-container.logo-blue .languages a {
  color: #0095c6;
}

.header .header-container.logo-blue .cart .nr {
  color: #fff;
}

.header .header-container.logo-blue .hamburger .btn-menu {
  width: 54px;
  height: 54px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: relative;
}

.header .header-container.logo-blue .hamburger .btn-menu span {
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.logo-blue .hamburger .btn-menu span:before {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.logo-blue .hamburger .btn-menu span:after {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(50%);
}

.header .header-container.white .logo .login-eko:before {
  content: "";
  width: 1px;
  background-color: #0095c6;
  position: absolute;
  top: -.5rem;
  bottom: -.5rem;
  left: -1rem;
}

@media (max-width: 767.98px) {
  .header .header-container.white .logo .login-eko:before {
    left: -.5rem;
  }
}

.header .header-container.white .logo .login-eko svg path.gray {
  fill: #636466;
}

.header .header-container.white .logo .login-eko svg path.green {
  fill: #aeab49;
}

.header .header-container.white .logo .login-eko svg path.red {
  fill: #d71920;
}

.header .header-container.white .logo .login-aphrodite:before {
  background-color: #0095c6;
}

.header .header-container.white .logo .login-aphrodite svg path.dark {
  fill: #0b1e41;
}

.header .header-container.white .logo .login-aphrodite svg path.green {
  fill: #5cc7d3;
}

.header .header-container.white svg path {
  fill: #0095c6;
}

.header .header-container.white .languages {
  margin-left: auto;
  margin-right: 2rem;
}

.header .header-container.white .languages a {
  color: #0095c6;
}

.header .header-container.white .cart .nr {
  color: #fff;
}

.header .header-container.white .hamburger .btn-menu {
  width: 54px;
  height: 54px;
  border: 3px solid #0095c6;
  border-radius: 50%;
  position: relative;
}

.header .header-container.white .hamburger .btn-menu span {
  height: 3px;
  width: 1.5rem;
  background-color: #0095c6;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.white .hamburger .btn-menu span:before {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #0095c6;
  border-radius: 8px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.header .header-container.white .hamburger .btn-menu span:after {
  content: "";
  height: 3px;
  width: 1.5rem;
  background-color: #0095c6;
  border-radius: 8px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%)translateY(50%);
}

.header-wrapper {
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.25rem;
  display: flex;
}

@media (min-width: 768px) {
  .header-wrapper {
    padding: 1.5rem 2rem;
  }
}

@media only screen and (max-width: 1310px) {
  .header-wrapper.alter {
    transition: background-color .4s cubic-bezier(.47, .17, .17, .58);
  }

  .header-wrapper.alter .scroll-progress {
    display: none !important;
  }

  .header-wrapper.alter .logo .login-eko:before {
    transition: background-color .4s cubic-bezier(.47, .17, .17, .58);
  }

  .header-wrapper.alter svg path {
    transition: fill .4s cubic-bezier(.47, .17, .17, .58);
  }

  .header-wrapper.alter .btn-menu {
    transition: border-color .4s cubic-bezier(.47, .17, .17, .58);
  }

  .header-wrapper.alter .btn-menu span, .header-wrapper.alter .btn-menu span:before, .header-wrapper.alter .btn-menu span:after {
    transition: background-color .4s cubic-bezier(.47, .17, .17, .58);
  }

  .header-wrapper.color-invert {
    background-color: #fff;
  }

  .header-wrapper.color-invert svg path {
    fill: #0095c6 !important;
  }

  .header-wrapper.color-invert .login-eko:before {
    background-color: #0095c6 !important;
  }

  .header-wrapper.color-invert .login-eko svg path.gray {
    fill: #636466 !important;
  }

  .header-wrapper.color-invert .login-eko svg path.green {
    fill: #aeab49 !important;
  }

  .header-wrapper.color-invert .login-eko svg path.red {
    fill: #d71920 !important;
  }

  .header-wrapper.color-invert .login-aphrodite:before {
    background-color: #0095c6 !important;
  }

  .header-wrapper.color-invert .login-aphrodite svg path.dark {
    fill: #0b1e41 !important;
  }

  .header-wrapper.color-invert .login-aphrodite svg path.green {
    fill: #5cc7d3 !important;
  }

  .header-wrapper.color-invert .languages {
    margin-left: auto;
    margin-right: 2rem;
  }

  .header-wrapper.color-invert .languages a {
    color: #0095c6 !important;
  }

  .header-wrapper.color-invert .cart .nr {
    color: #fff !important;
  }

  .header-wrapper.color-invert .hamburger .btn-menu {
    border: 3px solid #0095c6 !important;
  }

  .header-wrapper.color-invert .hamburger .btn-menu span, .header-wrapper.color-invert .hamburger .btn-menu span:before, .header-wrapper.color-invert .hamburger .btn-menu span:after {
    background-color: #0095c6 !important;
  }
}

.header .logo {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.header .logo a {
  width: 10rem;
  height: 50px;
  display: block;
}

@media (max-width: 1599.98px) {
  .header .logo a {
    width: 7rem;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .header .logo a {
    width: 5rem;
    height: auto;
  }
}

.header .logo a.login-eko {
  width: 196px;
  height: 54px;
  margin-left: 34px;
  position: relative;
}

@media (max-width: 1599.98px) {
  .header .logo a.login-eko {
    width: 137px;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .header .logo a.login-eko {
    width: 104px;
    height: auto;
    margin-left: 18px;
    position: relative;
  }
}

.header .logo a.login-eko:before {
  content: "";
  width: 1px;
  background-color: #fff;
  position: absolute;
  top: -.5rem;
  bottom: -.5rem;
  left: -1rem;
}

@media (max-width: 767.98px) {
  .header .logo a.login-eko:before {
    left: -.5rem;
  }
}

.header .logo a.login-eko img {
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.header .logo a.login-eko svg path {
  fill: #fff;
}

.header .logo a.login-aphrodite {
  height: 60px;
  width: auto;
  margin-left: 34px;
  position: relative;
}

@media (max-width: 1599.98px) {
  .header .logo a.login-aphrodite {
    height: 60px;
  }
}

@media (max-width: 767.98px) {
  .header .logo a.login-aphrodite {
    height: 40px;
    margin-left: 18px;
    position: relative;
  }
}

.header .logo a.login-aphrodite:before {
  content: "";
  width: 1px;
  background-color: #fff;
  position: absolute;
  top: -.5rem;
  bottom: -.5rem;
  left: -.5rem;
}

.header .logo a.login-aphrodite img {
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.header .logo a.login-aphrodite svg {
  width: auto;
}

.header .logo a.login-aphrodite svg path {
  fill: #fff;
}

.header .logo a img, .header .logo a svg {
  height: 100%;
  width: 100%;
}

.header .scroll-progress {
  pointer-events: none;
  z-index: 2;
  width: 54px;
  height: 54px;
  position: absolute;
}

.header .scroll-progress circle {
  stroke: #fff;
  stroke-width: 3px;
}

.header .scroll-progress .progress {
  stroke-width: 3px;
  stroke: #0095c6;
  transition: all .1s;
}

@media (min-width: 768px) {
  .header .scroll-progress + .btn-menu {
    border: 0 !important;
  }
}

.header .cart {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1.5rem;
  position: relative;
}

.header .cart .nr {
  color: #0095c6;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;
  position: absolute;
  bottom: 4px;
  right: 7px;
  transform: translateX(50%);
}

.footer {
  background-color: #e0e0e0;
}

.footer-wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 4rem 1rem 2rem;
}

@media (max-width: 1599.98px) {
  .footer-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 992px) {
  .footer-wrapper {
    padding: 5.5rem 1rem 2rem;
  }
}

.footer-logo {
  width: 100%;
  max-width: 364px;
  margin-bottom: 2.5rem;
}

@media (min-width: 992px) {
  .footer-logo {
    margin-bottom: 4rem;
  }
}

.footer-logo img {
  width: 100%;
  height: auto;
}

.footer-logo .login-eko svg path.gray {
  fill: #636466;
}

.footer-logo .login-eko svg path.green {
  fill: #aeab49;
}

.footer-logo .login-eko svg path.red {
  fill: #d71920;
}

.footer-logo .login-aphrodite svg path.dark {
  fill: #0b1e41;
}

.footer-logo .login-aphrodite svg path.green {
  fill: #5cc7d3;
}

@media (min-width: 768px) {
  .footer-blocks {
    margin-bottom: 6.5rem;
  }
}

@media (max-width: 767.98px) {
  .footer-block {
    margin-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .footer-block.text-md-right .text {
    margin-left: auto;
  }
}

.footer-block .info {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .footer-block .info {
    margin-bottom: 3.5rem;
  }
}

.footer-block .info .lead {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: .5rem;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 2;
  text-decoration: none;
}

.footer-block .info .lead span {
  font-weight: 700;
}

.footer-block .info p {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer-block .info p {
    font-size: calc(14.2px + .15vw);
  }
}

.footer-block .info p a {
  color: inherit;
}

.footer-block .info p:last-child {
  margin-bottom: 0;
}

.footer-block .text {
  max-width: 336px;
  margin-bottom: 1.5rem;
}

.footer-block .text p {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer-block .text p {
    font-size: calc(14.2px + .15vw);
  }
}

.footer-block .text p:last-child {
  margin-bottom: 0;
}

.footer-block .join {
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
  overflow: hidden;
}

.footer-block .join a {
  color: #333;
  text-transform: uppercase;
  margin-left: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  position: relative;
}

@media (max-width: 1200px) {
  .footer-block .join a {
    font-size: calc(14.2px + .15vw);
  }
}

.footer-block .join a:before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #151515;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translateY(-50%);
}

.footer-block .join a:first-child {
  margin-left: 0;
}

.footer-block .social {
  align-items: center;
  display: flex;
}

.footer-block .social a {
  flex: 0 0 2.5rem;
  margin-left: .75rem;
}

.footer-block .social a:first-child {
  margin-left: 0;
}

.footer-block .social a img {
  width: 100%;
  height: auto;
}

.footer-block .links p {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer-block .links p {
    font-size: calc(14.2px + .15vw);
  }
}

.footer-block .links p:last-child {
  margin-bottom: 0;
}

.footer-block .links p a {
  font-weight: 500;
}

@media (min-width: 768px) {
  .footer-block .joinus {
    margin-top: 3rem;
  }
}

.footer-block .joinus a {
  color: #333;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: .8;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer-block .joinus a {
    font-size: calc(15.6px + 1.2vw);
  }
}

.footer-block .joinus a strong {
  font-weight: 700;
}

.footer .colophon {
  opacity: .7;
}

@media (max-width: 767.98px) {
  .footer .colophon .col-auto {
    width: 100%;
    text-align: center;
    flex: 0 0 100%;
    padding: 0;
  }
}

.footer .colophon p {
  color: #333;
  align-items: center;
  margin-bottom: 0;
  padding: .5rem 0;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857;
  text-decoration: none;
  display: flex;
}

@media (max-width: 767.98px) {
  .footer .colophon p {
    justify-content: center;
  }
}

.footer .colophon p a {
  color: #333;
  align-items: center;
  margin: 0 .75rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857;
  text-decoration: none;
  display: flex;
}

.footer .colophon p a.enki {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath d='M6.676 6.676v5.677M8.408 6.508l2.917 3.432M4.904 6.508L1.972 9.954M12.311 1a8.464 8.464 0 01-5.663 2.16A8.455 8.455 0 011 1' stroke='%23333' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  margin: 0;
  padding-right: 18px;
}

.footer .colophon p a.enki img {
  width: 44px;
  height: auto;
  margin-top: -4px;
  margin-left: 4px;
}

#moove_gdpr_cookie_info_bar {
  box-shadow: 0 0 40px #0000001f;
  max-width: 360px !important;
  background-color: #ffffffe6 !important;
  border-top: 0 !important;
  border-radius: 2px !important;
  bottom: 1rem !important;
  left: auto !important;
  right: 1rem !important;
}

#moove_gdpr_cookie_info_bar.moove-gdpr-info-bar-hidden {
  bottom: -400px !important;
}

#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container {
  padding: 24px !important;
}

#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content {
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 0 !important;
}

#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content .moove-gdpr-cookie-notice {
  margin-bottom: .5rem;
}

#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content .moove-gdpr-button-holder {
  padding-left: 0 !important;
}

#moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content .moove-gdpr-button-holder .mgbutton {
  background-color: #0095c6 !important;
  margin: 0 !important;
}

.moove_gdpr_cookie_modal_open .moove-gdpr-modal-close .gdpr-icon {
  background-color: #0095c6 !important;
  border-color: #0095c6 !important;
}

.moove_gdpr_cookie_modal_open .moove-gdpr-modal-close .gdpr-icon:hover {
  background-color: #fff !important;
}

.moove_gdpr_cookie_modal_open .moove-gdpr-button-holder button {
  background-color: #0095c6 !important;
  border-color: #0095c6 !important;
}

.moove_gdpr_cookie_modal_open .moove-gdpr-button-holder button:hover {
  color: #0095c6 !important;
  background-color: #fff !important;
}

.menu-overlay {
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  transition: opacity .6s cubic-bezier(.47, .17, .17, .58), visibility .6s cubic-bezier(.47, .17, .17, .58);
  display: flex;
  position: fixed;
  inset: 0;
}

.menu-overlay:after {
  content: "";
  z-index: -1;
  width: 4px;
  height: 4px;
  visibility: hidden;
  border-radius: 100%;
  margin: -2px 0 0 -2px;
  transition: all .6s;
  position: fixed;
  top: 0;
  right: 0;
}

.overlay-menu-active .menu-overlay {
  opacity: 1;
  visibility: visible;
}

.overlay-menu-active .menu-overlay:after {
  visibility: visible;
  box-shadow: 0 0 0 210vw #0095c6, inset 0 0 0 100px #0095c6;
}

@media (min-width: 992px) {
  .overlay-menu-active .menu-overlay:after {
    box-shadow: 0 0 0 135vw #0095c6, inset 0 0 0 10px #0095c6;
  }
}

.menu-overlay .menu-close {
  width: 54px;
  height: 54px;
  position: relative;
}

.menu-overlay .menu-close span {
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.menu-overlay .menu-close span:before {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.menu-overlay .menu-close span:after {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.menu-overlay-wrapper {
  width: 100%;
  max-width: 996px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 767.98px) {
  .menu-overlay-wrapper {
    margin: 0 auto 6rem;
  }
}

.menu-overlay-wrapper .menu li {
  max-width: 55%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

@media (min-width: 768px) {
  .menu-overlay-wrapper .menu li {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .menu-overlay-wrapper .menu li {
    max-width: 432px;
  }
}

@media (max-width: 767.98px) {
  .menu-overlay-wrapper .menu li {
    margin-bottom: .75rem;
    padding-bottom: .75rem;
  }
}

.menu-overlay-wrapper .menu li.menu-item-2958 a {
  text-transform: none;
}

.menu-overlay-wrapper .menu li.current-menu-item a {
  font-weight: 700;
  text-decoration: underline;
}

.menu-overlay-wrapper .menu li.menu-item-has-children:after {
  content: "";
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: -268px;
}

.menu-overlay-wrapper .menu li.menu-item-has-children a {
  font-weight: 800;
}

.menu-overlay-wrapper .menu li:last-child {
  margin-bottom: 0;
}

.menu-overlay-wrapper .menu li a {
  color: #f2f2f2;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .menu-overlay-wrapper .menu li a {
    font-size: calc(16.4px + 1.8vw);
  }
}

@media (max-width: 767.98px) {
  .menu-overlay-wrapper .menu li a {
    font-size: 32px;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .menu-overlay-wrapper .menu li a {
    font-size: calc(15.8px + 1.35vw);
  }
}

.menu-overlay-wrapper .menu li .sub-menu {
  width: 85%;
  border-left: 1px solid #fff;
  padding-top: 64px;
  padding-bottom: 40px;
  padding-left: 1.25rem;
  position: absolute;
  left: 100%;
  transform: translateY(-40px);
}

@media (min-width: 768px) {
  .menu-overlay-wrapper .menu li .sub-menu {
    min-width: 268px;
    width: auto;
    padding-top: 76px;
    padding-bottom: 80px;
  }
}

.menu-overlay-wrapper .menu li .sub-menu li {
  margin-bottom: 1rem;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .menu-overlay-wrapper .menu li .sub-menu li {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .menu-overlay-wrapper .menu li .sub-menu li {
    width: 100%;
    max-width: none;
    margin-bottom: .5rem;
    line-height: 1.25;
  }
}

.menu-overlay-wrapper .menu li .sub-menu li.current-menu-item a {
  font-weight: 700;
  text-decoration: underline;
}

.menu-overlay-wrapper .menu li .sub-menu li a {
  color: #f2f2f2;
  text-transform: uppercase;
  padding-left: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22222;
  text-decoration: none;
  display: block;
  position: relative;
}

@media (max-width: 1200px) {
  .menu-overlay-wrapper .menu li .sub-menu li a {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .menu-overlay-wrapper .menu li .sub-menu li a {
    font-size: 12px;
    line-height: 1.25;
  }
}

.menu-overlay-wrapper .menu li .sub-menu li a:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0;
}

.logineko-projects {
  margin-bottom: 40px;
}

@media (max-width: 991.98px) {
  .logineko-projects .project-card {
    margin-bottom: 30px;
  }
}

.logineko-projects .project-card a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.logineko-projects .project-card a img {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.logineko-projects .project-card a span {
  background-color: #fff;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 25px;
}

.content-offset {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto 5.75rem;
  padding: 0 1rem;
}

@media (max-width: 1599.98px) {
  .content-offset {
    max-width: 1160px;
  }
}

@media (max-width: 991.98px) {
  .content-offset {
    margin-bottom: 2rem;
  }
}

.content-offset-wrapper {
  max-width: 640px;
}

.content-offset h2 {
  color: #333;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-offset h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 1600px) {
  .content-offset h2 {
    color: #333;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: .9;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .content-offset h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.content-offset h2 strong {
  font-weight: 700;
}

.title-container {
  width: 100%;
  max-width: 576px;
  margin: 0 auto 4rem;
  padding: 0 1rem;
}

.title-container h2 {
  color: #333;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .title-container h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 1600px) {
  .title-container h2 {
    color: #333;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: .9;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .title-container h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.text-container {
  width: 100%;
  max-width: 576px;
  margin: 0 auto 4rem;
  padding: 0 1rem;
}

.text-container h2 {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.125;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .text-container h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

.text-container p {
  color: #4e4e4e;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.63636;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .text-container p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .text-container p {
    color: #4e4e4e;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .text-container p {
    font-size: calc(14.4px + .3vw);
  }
}

.text-container p:last-child {
  margin-bottom: 0;
}

.image-text-container {
  width: 100%;
  max-width: 1232px;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 auto 2.5rem;
  padding: 0 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .image-text-container {
    margin: 0 auto 3rem;
  }
}

@media (min-width: 992px) {
  .image-text-container {
    margin: 0 auto 4rem;
  }
}

.image-text-container .image {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: .5rem;
}

@media (min-width: 768px) {
  .image-text-container .image {
    width: 70%;
    flex: 0 0 70%;
    margin-bottom: 0;
  }
}

.image-text-container .image img {
  width: 100%;
  height: auto;
}

.image-text-container .text {
  width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .image-text-container .text {
    width: 30%;
    flex: 0 0 30%;
    padding-left: 2rem;
    padding-right: 3rem;
  }
}

@media (min-width: 1200px) {
  .image-text-container .text {
    padding-left: 2.25rem;
    padding-right: 7rem;
  }
}

.image-text-container .text p {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .image-text-container .text p {
    font-size: calc(14.2px + .15vw);
  }
}

.image-text-container .text p:last-child {
  margin-bottom: 0;
}

.content-carousel {
  width: 100%;
  max-width: 1732px;
  margin: 4rem auto;
}

@media (min-width: 768px) {
  .content-carousel {
    margin: 80px auto 120px;
    padding: 0 5rem;
  }
}

@media (min-width: 992px) {
  .content-carousel {
    margin: 104px auto 160px;
    padding: 0 5rem;
  }
}

@media (min-width: 1200px) {
  .content-carousel {
    margin: 132px auto 174px;
    padding: 0 5rem;
  }
}

.content-carousel.slick-slider {
  position: relative;
}

.content-carousel.slick-slider .slick-arrow {
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #c6c6c6;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .content-carousel.slick-slider .slick-arrow {
    display: none !important;
  }
}

.content-carousel.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: .5rem;
}

.content-carousel.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: .5rem;
}

.content-carousel.slick-slider .slick-list {
  overflow: hidden;
}

.content-carousel.slick-slider .slick-slide {
  float: left;
}

.content-carousel.slick-slider .slick-dots {
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  padding: 0 1rem;
  display: flex;
}

.content-carousel.slick-slider .slick-dots li {
  cursor: pointer;
  margin: 0 .25rem;
}

.content-carousel.slick-slider .slick-dots li:last-child {
  margin-right: 0;
}

.content-carousel.slick-slider .slick-dots li span {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-carousel.slick-slider .slick-dots li span {
    font-size: calc(14.6px + .45vw);
  }
}

.content-carousel.slick-slider .slick-dots li.slick-active span {
  font-weight: 900;
}

.content-carousel .carousel-item {
  position: relative;
}

.content-carousel .carousel-item .image img {
  width: 100%;
  height: auto;
}

.content-carousel .carousel-item .text {
  padding: 2.5rem 1rem 1.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .content-carousel .carousel-item .text {
    width: 340px;
    background: #fff;
    padding: 2.75rem 3rem 2.5rem 3.5rem;
    position: absolute;
    bottom: 0;
    left: 3.875rem;
  }
}

.content-carousel .carousel-item .text .number {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.6;
  text-decoration: none;
  position: absolute;
  top: .5rem;
  left: .75rem;
}

@media (max-width: 1200px) {
  .content-carousel .carousel-item .text .number {
    font-size: calc(14.6px + .45vw);
  }
}

.content-carousel .carousel-item .text p {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-carousel .carousel-item .text p {
    font-size: calc(14.2px + .15vw);
  }
}

.content-carousel .carousel-item .text p:last-child {
  margin-bottom: 0;
}

.content-video {
  width: 100%;
  max-width: 864px;
  margin: 1.5rem auto 2.5rem;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .content-video {
    margin: 4rem auto;
  }
}

@media (min-width: 992px) {
  .content-video {
    margin: 5rem auto;
  }
}

@media (min-width: 1200px) {
  .content-video {
    margin: 7rem auto;
  }
}

.content-video .media {
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

.content-video .media .video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.content-video .media .video.playerActive .btn-play, .content-video .media .video.playerActive .poster {
  opacity: 0;
  visibility: hidden;
}

.content-video .media .poster {
  z-index: 2;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  inset: 0;
}

.content-video .media .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-video .media .btn-play {
  width: 5rem;
  height: 5rem;
  z-index: 3;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-video .media iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.content-quote {
  width: 100%;
  max-width: 560px;
  margin: 5rem auto 3rem;
  padding: 0 1rem;
  position: relative;
}

@media (min-width: 768px) {
  .content-quote {
    margin: 6rem auto 4rem;
  }
}

@media (min-width: 1200px) {
  .content-quote {
    margin: 8rem auto 4rem;
  }
}

.content-quote:before {
  content: "";
  width: 103px;
  height: 85px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%230095C6' fill-opacity='.3'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-10%, -60%);
}

@media (max-width: 767.98px) {
  .content-quote:before {
    width: 5rem;
  }
}

.content-quote p {
  color: #0095c6;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-quote p {
    font-size: calc(15.8px + 1.35vw);
  }
}

.content-banner {
  width: 100%;
  margin-bottom: 3rem;
  position: relative;
}

@media (min-width: 768px) {
  .content-banner {
    margin-bottom: 10rem;
    padding-right: 5%;
  }
}

@media (min-width: 992px) {
  .content-banner {
    margin-bottom: 10rem;
    padding-right: 6%;
  }
}

@media (min-width: 1200px) {
  .content-banner {
    margin-bottom: 226px;
    padding-right: 7%;
  }
}

.content-banner .image img {
  width: 100%;
  height: auto;
}

.content-banner .text {
  position: relative;
}

@media (min-width: 768px) {
  .content-banner .text {
    z-index: 2;
    width: 100%;
    max-width: 1712px;
    padding: 0 1rem;
    position: absolute;
    bottom: -7rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 992px) {
  .content-banner .text {
    bottom: -4rem;
  }
}

.content-banner .text-wrapper {
  width: 100%;
  max-width: 840px;
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
  padding: 2rem 1rem;
}

@media (min-width: 768px) {
  .content-banner .text-wrapper {
    padding: 2rem 3.5rem;
  }
}

@media (min-width: 1400px) {
  .content-banner .text-wrapper {
    padding: 3.5rem 5.5rem;
  }
}

.content-banner .text h2 {
  color: #f2f2f2;
  letter-spacing: -.4px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.14583;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-banner .text h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

@media (min-width: 1400px) {
  .content-banner .text h2 {
    max-width: 70%;
    margin-bottom: 2rem;
  }
}

.content-banner .text p {
  color: #fff;
  letter-spacing: .1px;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.63636;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-banner .text p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .content-banner .text p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .content-banner .text p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 1400px) {
  .content-banner .text p {
    max-width: 70%;
    margin-bottom: 3rem;
  }
}

.content-banner .text .links {
  align-items: center;
  display: flex;
}

.content-banner .text .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  margin-left: 2.75rem;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-banner .text .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.content-banner .text .link-bullet:first-child {
  margin-left: 0;
}

.image-fullwidth {
  width: 100%;
  margin-bottom: 3rem;
}

@media (min-width: 992px) {
  .image-fullwidth {
    max-width: 1714px;
    margin: 0 auto 6.5rem;
    padding: 0 1rem;
  }
}

.image-fullwidth img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image-fullwidth img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .image-fullwidth img {
    display: none;
  }

  .image-fullwidth img.mobile {
    display: block;
  }
}

.content-text-image {
  margin-bottom: 3rem;
}

@media (min-width: 992px) {
  .content-text-image {
    margin-bottom: 6.5rem;
  }

  .content-text-image.section-vision.left .text-wrapper {
    padding-right: 10rem;
  }

  .content-text-image.section-vision.right .text-wrapper {
    padding-left: 10rem;
  }
}

@media (min-width: 768px) {
  .content-text-image.left .image {
    order: 1;
  }

  .content-text-image.left .text, .content-text-image.right .image {
    order: 2;
  }
}

.content-text-image.right .text {
  order: 1;
}

.content-text-image.right .text-wrapper {
  margin-left: auto;
}

.content-text-image-wrapper {
  width: 100%;
  max-width: 1712px;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.content-text-image-wrapper .image {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .content-text-image-wrapper .image {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0;
  }
}

.content-text-image-wrapper .image img {
  width: 100%;
  height: auto;
}

.content-text-image-wrapper .text {
  width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .content-text-image-wrapper .text {
    width: 50%;
    flex: 0 0 50%;
  }
}

.content-text-image-wrapper .text-wrapper {
  max-width: 672px;
}

@media (min-width: 768px) {
  .content-text-image-wrapper .text-wrapper {
    padding: 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .content-text-image-wrapper .text-wrapper {
    padding: 0 4.5rem;
  }
}

.content-text-image-wrapper .text .number {
  color: #9d9d9d;
  margin-bottom: 1.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 300;
  line-height: .681818;
  text-decoration: none;
  display: block;
}

@media (max-width: 1200px) {
  .content-text-image-wrapper .text .number {
    font-size: calc(17px + 2.25vw);
  }
}

.content-text-image-wrapper .text h2 {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.125;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-text-image-wrapper .text h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

@media (min-width: 768px) {
  .content-text-image-wrapper .text h2 {
    margin-bottom: 3rem;
  }
}

.content-text-image-wrapper .text h3 {
  color: #333;
  margin-bottom: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.28571;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-text-image-wrapper .text h3 {
    font-size: calc(15.4px + 1.05vw);
  }
}

@media (min-width: 768px) {
  .content-text-image-wrapper .text h3 {
    margin-bottom: 1.625rem;
  }
}

.content-text-image-wrapper .text p {
  color: #4e4e4e;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.63636;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-text-image-wrapper .text p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .content-text-image-wrapper .text p {
    color: #4e4e4e;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .content-text-image-wrapper .text p {
    font-size: calc(14.4px + .3vw);
  }
}

.content-text-image-wrapper .text p:last-child {
  margin-bottom: 0;
}

.management {
  margin-top: 4rem;
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .management {
    margin-top: 5rem;
    padding-bottom: 4.5rem;
  }
}

@media (min-width: 992px) {
  .management {
    margin-top: 7rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 1600px) {
  .management {
    margin-top: 10rem;
    padding-bottom: 9.5rem;
  }
}

.management-wrapper {
  width: 100%;
  max-width: 870px;
  margin: 0 auto;
  padding: 0 1rem;
}

.management-wrapper h2 {
  color: #0f0f0f;
  text-transform: uppercase;
  width: 100%;
  max-width: 550px;
  margin: 0 auto 4rem;
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: .833333;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .management-wrapper h2 {
    font-size: calc(16.2px + 1.65vw);
  }
}

@media (min-width: 1600px) {
  .management-wrapper h2 {
    color: #0f0f0f;
    font-family: Montserrat, sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: .8;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .management-wrapper h2 {
    font-size: calc(17.1px + 2.325vw);
  }
}

@media (max-width: 991.98px) {
  .management-wrapper h2 {
    margin-bottom: 3rem;
  }
}

@media (max-width: 767.98px) {
  .management-wrapper h2 {
    margin-bottom: 2rem;
  }
}

.management-wrapper h2 strong {
  font-weight: 700;
}

.management-wrapper .element {
  margin-bottom: 5.5rem;
}

.management-wrapper .element h3 {
  width: 100%;
  max-width: 550px;
  color: #0f0f0f;
  text-transform: uppercase;
  margin: 0 auto 3.5rem;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: .75;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .management-wrapper .element h3 {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .management-wrapper .element h3 {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 1200px) {
  .management-wrapper .element h3 {
    font-size: calc(15.4px + 1.05vw);
  }
}

@media (min-width: 1600px) {
  .management-wrapper .element h3 {
    color: #0f0f0f;
    font-family: Lato, sans-serif;
    font-size: 33px;
    font-weight: 300;
    line-height: .787879;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .management-wrapper .element h3 {
    font-size: calc(15.9px + 1.425vw);
  }
}

.management-wrapper .element h3 strong {
  font-weight: 700;
}

.management-wrapper .element img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .management-wrapper .element img {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .management-wrapper .element img {
    margin-bottom: 3rem;
  }
}

.management-wrapper .element p {
  width: 100%;
  max-width: 550px;
  color: #4e4e4e;
  margin: 0 auto 3.5rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.63636;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .management-wrapper .element p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .management-wrapper .element p {
    color: #4e4e4e;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .management-wrapper .element p {
    font-size: calc(14.4px + .3vw);
  }
}

.link-bullet {
  color: #333;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
  transition: background-size .2s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 991.98px) {
  .link-bullet {
    color: #fff;
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23FFFFFF' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
  }
}

.link-bullet:hover {
  background-size: 29px 29px !important;
}

.text-container:last-child, .image-text-container:last-child, .content-carousel:last-child, .content-video:last-child, .content-quote:last-child, .content-banner:last-child, .content-text-image:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .content-text-image .link-bullet, .text-container .link-bullet {
    color: #333;
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  }
}

@media only screen and (max-width: 768px) {
  #moove_gdpr_cookie_info_bar {
    bottom: 0 !important;
    right: 0 !important;
  }

  #moove_gdpr_cookie_info_bar .mgbutton.moove-gdpr-infobar-allow-all {
    display: block;
    margin: auto !important;
  }

  #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container {
    padding: 10px !important;
  }

  .page-about.page-service-page {
    padding-top: 6rem !important;
  }

  .section-knowledge .text p {
    display: none;
  }

  .page-section-interesting .carousel-item .text {
    margin-top: 1rem;
  }
}

.content-text-image-wrapper .text p a {
  color: #0095c6;
}

.home-slides .section-projects .content p {
  color: #f2f2f2;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.31818;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-projects .content p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (min-width: 768px) {
  .home-slides .section-projects .content p {
    margin-bottom: 2.75rem;
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .content p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.38889;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-projects .content p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .content p {
    font-size: 1rem;
  }
}

.home-slides .section-projects .content h2 {
  margin-bottom: 20px !important;
}

.multimedia-block {
  background-color: #e9e9e9;
  margin-bottom: 3.5rem;
  padding: 2.75rem 0;
}

@media (min-width: 768px) {
  .multimedia-block {
    margin-bottom: 4.5rem;
    padding: 3.75rem 0;
  }
}

@media (min-width: 1600px) {
  .multimedia-block {
    margin-bottom: 7.5rem;
    padding: 5.75rem 0;
  }
}

.multimedia-block-wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .multimedia-block-wrapper {
    padding: 0 5rem;
  }
}

@media (max-width: 1599.98px) {
  .multimedia-block-wrapper {
    max-width: 1160px;
  }
}

.multimedia-block .topbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  display: flex;
}

@media (min-width: 1600px) {
  .multimedia-block .topbar {
    margin-bottom: 5.5rem;
  }
}

.multimedia-block .topbar h2 {
  color: #151515;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: .8;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .multimedia-block .topbar h2 {
    font-size: calc(18.6px + 3.45vw);
  }
}

@media (max-width: 1599.98px) {
  .multimedia-block .topbar h2 {
    color: #151515;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .multimedia-block .topbar h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .multimedia-block .topbar h2 {
    flex: 0 0 100%;
    margin-bottom: 1rem;
  }

  .multimedia-block .topbar .link {
    text-align: right;
    flex: 0 0 100%;
  }
}

.multimedia-block .topbar .link a {
  color: #000;
  text-transform: uppercase;
  padding-right: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.28571;
  text-decoration: none;
  position: relative;
}

.multimedia-block .topbar .link a:after {
  content: "";
  width: 35px;
  height: 31px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23000' stroke='%23000'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23000'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23000' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px 31px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.multimedia-block .topbar .link a:hover:after {
  opacity: 0;
  visibility: hidden;
  right: -24px;
}

.multimedia-block-carousel > .row {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

@media (max-width: 767.98px) {
  .multimedia-block-carousel > .row {
    flex-wrap: nowrap;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .multimedia-block-carousel-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-wrap: nowrap;
    display: flex;
    overflow: auto;
  }

  .multimedia-block-carousel-wrapper::-webkit-scrollbar {
    display: none;
  }
}

.multimedia-block-carousel .slick-slider {
  position: relative;
}

.multimedia-block-carousel .slick-slider .slick-arrow {
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #c6c6c6;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.multimedia-block-carousel .slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -4.5rem;
}

.multimedia-block-carousel .slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -4.5rem;
}

.multimedia-block-carousel .slick-slider .slick-list {
  overflow: hidden;
}

.multimedia-block-carousel .slick-slider .slick-slide {
  float: left;
}

@media (max-width: 767.98px) {
  .multimedia-block-carousel .carousel-item {
    flex: none;
    margin-right: 1rem;
  }
}

.multimedia-block-carousel .carousel-item > .row {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

@media (max-width: 991.98px) {
  .multimedia-block-carousel .carousel-item > .row {
    flex-wrap: nowrap;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 992px) {
  .multimedia-block-carousel .carousel-col {
    padding: 0 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .multimedia-block-carousel .carousel-col.col-md-8 {
    width: 300px;
    flex: 0 0 300px;
    margin-left: 0;
    margin-right: -1rem;
  }

  .multimedia-block-carousel .carousel-col.col-md-8 .block-single {
    padding: 0;
  }

  .multimedia-block-carousel .carousel-col.col-md-4 {
    width: 60%;
    min-width: 520px;
    flex-wrap: nowrap;
    flex: 0 0 60%;
    display: flex;
  }

  .multimedia-block-carousel .carousel-col.col-md-4 .block-single {
    width: 300px;
    flex: 0 0 300px;
  }
}

.multimedia-block-carousel .block-single {
  margin-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
  .multimedia-block-carousel .block-single {
    margin-bottom: 0;
    padding: 0 1rem;
  }
}

.multimedia-block-carousel .block-single .media {
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

.multimedia-block-carousel .block-single .media .video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.multimedia-block-carousel .block-single .media .video.playerActive .btn-play, .multimedia-block-carousel .block-single .media .video.playerActive .poster {
  opacity: 0;
  visibility: hidden;
}

.multimedia-block-carousel .block-single .media .poster {
  z-index: 2;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  inset: 0;
}

.multimedia-block-carousel .block-single .media .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.multimedia-block-carousel .block-single .media .btn-play {
  width: 5rem;
  height: 5rem;
  z-index: 3;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.multimedia-block-carousel .block-single .media iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.multimedia-block-carousel .block-single .text .date {
  color: #151515;
  margin-bottom: .25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 2.125;
  text-decoration: none;
  display: block;
}

@media (max-width: 1200px) {
  .multimedia-block-carousel .block-single .text .date {
    font-size: calc(14.2px + .15vw);
  }
}

.multimedia-block-carousel .block-single .text h3 {
  color: #151515;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.41667;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .multimedia-block-carousel .block-single .text h3 {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 1599.98px) {
  .multimedia-block-carousel .block-single .text h3 {
    font-size: 18px;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .multimedia-block-carousel .block-single .text h3 {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-base {
  position: relative;
}

.cover-base .image {
  height: 100vh;
  max-height: 1080px;
  min-height: 640px;
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

.cover-base .image .poster {
  z-index: 2;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  inset: 0;
}

.cover-base .image .poster:before {
  content: "";
  z-index: 2;
  mix-blend-mode: multiply;
  opacity: .95;
  background: linear-gradient(0deg, #494949 0%, #bababa21 100%);
  position: absolute;
  inset: 0;
}

.cover-base .image .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-base .image .poster img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .cover-base .image .poster img {
    display: none;
  }

  .cover-base .image .poster img.mobile {
    display: block;
  }
}

.cover-base .image .poster video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991.98px) {
  .cover-base .image .poster video {
    display: none;
  }
}

.cover-base .btn-play {
  width: 5rem;
  height: 5rem;
  z-index: 3;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%) !important;
}

@media (max-width: 767.98px) {
  .cover-base .btn-play {
    top: 65%;
    left: 50%;
  }
}

.cover-base .video-overlay {
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  background-color: #151515cc;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: fixed;
  inset: 0;
}

.overlay-video-active .cover-base .video-overlay {
  opacity: 1;
  visibility: visible;
}

.cover-base .video-overlay .video-close {
  z-index: 5;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.cover-base .video-overlay .video-close span {
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.cover-base .video-overlay .video-close span:before {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.cover-base .video-overlay .video-close span:after {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.cover-base .video-overlay-wrapper {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .cover-base .video-overlay-wrapper {
    width: 100%;
  }
}

.cover-base .video-overlay .iframe-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.cover-base .video-overlay iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cover-base .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  padding: 0 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .cover-base .content {
    top: 35%;
  }
}

@media (max-width: 1599.98px) {
  .cover-base .content {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .cover-base .content-wrapper {
    max-width: 66%;
  }
}

.cover-base .content-wrapper h1 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-base .content-wrapper h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-base .content-wrapper h1 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-base .content-wrapper h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

.cover-base .content-wrapper h1 strong {
  font-weight: 700;
}

.cover-base .content-wrapper p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: .95;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-base .content-wrapper p {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-base .content-wrapper p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: .909091;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-base .content-wrapper p {
    font-size: calc(14.8px + .6vw);
  }
}

.cover-base .scroll-down {
  z-index: 2;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%) !important;
}

.cover-base .scroll-down .icon {
  width: 31px;
  height: 34px;
  margin: 0 auto .5rem;
}

.cover-base .scroll-down span {
  color: #fff;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.71429;
  text-decoration: none;
  display: block;
}

.cover-base .scroll-down svg path {
  animation-name: arrowDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes arrowDown {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  10% {
    opacity: 1;
  }

  75% {
    opacity: .65;
  }

  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

.page-about {
  padding-top: 2.5rem;
}

@media (min-width: 992px) {
  .page-about {
    padding-top: 7rem;
  }
}

.page-about .content-text-image .text h2 {
  font-weight: 400;
}

.page-about .content-text-image .text h2 strong {
  font-weight: 600;
}

.home-slides #fullpage-menu {
  z-index: 9;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1.5rem;
  transition: transform .4s cubic-bezier(.47, .17, .17, .58);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 767.98px) {
  .home-slides #fullpage-menu {
    display: none;
  }
}

.fp-viewing-section6 .home-slides #fullpage-menu {
  transform: translateX(-100%);
}

.home-slides #fullpage-menu a {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: .75rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}

.home-slides #fullpage-menu a:last-child {
  margin-bottom: 0;
}

.home-slides #fullpage-menu a.active {
  font-weight: 800;
}

.home-slides .section {
  position: relative;
}

@media (max-width: 767.98px) {
  .home-slides .section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home-slides .section:before {
    content: "";
    z-index: 0;
    opacity: .6;
    background: linear-gradient(#1e1e1e00 9.92%, #1e1e1e 73.53%);
    position: absolute;
    inset: 0;
  }
}

.home-slides .section .background {
  position: absolute;
  inset: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section .background {
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .home-slides .section .background {
    height: 100%;
    width: 100%;
    position: relative;
    inset: auto;
  }
}

.home-slides .section .background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-slides .section .background img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .home-slides .section .background img {
    display: none;
  }

  .home-slides .section .background img.mobile {
    display: block;
  }
}

.home-slides .section .next-slide {
  z-index: 2;
  text-align: center;
  transition: bottom .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%) !important;
}

.fp-viewing-section1 .home-slides .section .next-slide {
  opacity: 1;
  visibility: visible;
  bottom: 3rem;
}

.home-slides .section .next-slide .icon {
  width: 31px;
  height: 34px;
  margin: 0 auto .5rem;
}

.home-slides .section .next-slide span {
  color: #fff;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.71429;
  text-decoration: none;
  display: block;
}

.home-slides .section .next-slide span svg path {
  animation-name: arrowDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.home-slides .section-cover .background:before {
  content: "";
  z-index: 1;
  opacity: .6;
  background: linear-gradient(#1e1e1e00 9.92%, #1e1e1e 73.53%);
  position: absolute;
  inset: 0;
}

.home-slides .section-cover .background img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .home-slides .section-cover .background img {
    display: none;
  }

  .home-slides .section-cover .background img.mobile {
    display: block;
  }
}

.home-slides .section-cover .background video {
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

@media (max-width: 991.98px) {
  .home-slides .section-cover .background video {
    display: none;
  }
}

.home-slides .section-cover .content {
  z-index: 2;
  width: 100%;
  max-width: 1080px;
  text-align: center;
  padding: 2rem 1rem;
  transition: top .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fp-viewing-section1 .home-slides .section-cover .content {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.home-slides .section-cover .content h1 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-cover .content h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-cover .content h1 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-cover .content h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-cover .content h1 {
    font-size: 2rem;
  }
}

.home-slides .section-cover .content h1 strong {
  font-weight: 700;
}

.home-slides .section-cover .content p {
  color: #f2f2f2;
  max-width: 520px;
  margin: 0 auto;
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.1875;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-cover .content p {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-cover .content p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.55556;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-cover .content p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-cover .content p {
    font-size: 1.25rem;
  }
}

.home-slides .section-vision {
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .home-slides .section-vision .fp-scroller {
    height: 100%;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .fp-scroller {
    min-height: 600px;
  }
}

.home-slides .section-vision .background:before {
  content: "";
  z-index: 1;
  background-blend-mode: multiply;
  opacity: .6;
  background: linear-gradient(0deg, #142d18 9.63%, #57575700 93.24%);
  position: absolute;
  inset: 0;
}

.home-slides .section-vision .content {
  padding: 2rem 1rem;
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content {
    height: 100%;
    padding-top: 7rem;
    position: relative;
  }
}

@media (min-width: 768px) {
  .home-slides .section-vision .content {
    z-index: 2;
    width: 100%;
    max-width: 1560px;
    margin-top: 0;
    padding: 2rem 1rem 2rem 140px;
    transition: top .4s cubic-bezier(.47, .17, .17, .58) .5s, opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fp-viewing-section2 .home-slides .section-vision .content {
    opacity: 1;
    top: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .home-slides .section-vision .content {
    max-width: 1160px;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content-wrapper {
    height: 100%;
  }
}

.home-slides .section-vision .content .title-row {
  margin-bottom: 1rem;
  display: flex;
  position: static;
}

@media (min-width: 768px) {
  .home-slides .section-vision .content .title-row {
    position: relative;
  }
}

@media (min-width: 1200px) {
  .home-slides .section-vision .content .title-row {
    margin-bottom: 2rem;
  }
}

.home-slides .section-vision .content .title-row:first-child {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .home-slides .section-vision .content .title-row:first-child {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  .home-slides .section-vision .content .title-row:first-child {
    margin-bottom: 3rem;
  }
}

.home-slides .section-vision .content .title-row.active {
  z-index: 2;
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row.active .title h2:before {
    bottom: calc(22rem - 100vh);
  }
}

.home-slides .section-vision .content .title-row.active .title h2:after {
  right: 0;
}

@media (min-width: 1200px) {
  .home-slides .section-vision .content .title-row.active .title h2:after {
    right: -50%;
  }
}

.home-slides .section-vision .content .title-row.active .text {
  opacity: 1;
  visibility: visible;
  transform: translateY(8px)translateX(-20px);
}

.home-slides .section-vision .content .title-row.active .text:before {
  opacity: 1;
  bottom: 4rem;
}

.home-slides .section-vision .content .title-row .title {
  cursor: pointer;
  width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .home-slides .section-vision .content .title-row .title {
    width: 40%;
    flex: 0 0 40%;
  }
}

@media (min-width: 992px) {
  .home-slides .section-vision .content .title-row .title {
    width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 1400px) {
  .home-slides .section-vision .content .title-row .title {
    width: 30%;
    flex: 0 0 30%;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-vision .content .title-row .title {
    width: 40%;
    flex: 0 0 40%;
  }
}

.home-slides .section-vision .content .title-row .title h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  position: relative;
}

@media (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .title h2 {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-vision .content .title-row .title h2 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.13636;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .title h2 {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row .title h2 {
    font-size: 1.25rem;
  }

  .home-slides .section-vision .content .title-row .title h2:before {
    content: "";
    width: 2px;
    opacity: 1;
    background-color: #fff;
    transition: bottom .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58);
    position: absolute;
    top: 0;
    bottom: 100%;
    right: 0;
  }
}

.home-slides .section-vision .content .title-row .title h2:after {
  content: "";
  height: 2px;
  background-color: #fff;
  transition: right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
}

.home-slides .section-vision .content .title-row .title h2.big {
  color: #f2f2f2;
  padding-bottom: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .title h2.big {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-vision .content .title-row .title h2.big {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .title h2.big {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row .title h2.big {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .home-slides .section-vision .content .title-row .title h2.big {
    padding-bottom: 1.75rem;
  }
}

.home-slides .section-vision .content .title-row .title h2 strong {
  font-weight: 700;
}

.home-slides .section-vision .content .title-row .text {
  opacity: 0;
  visibility: hidden;
  width: 60%;
  flex: 0 0 60%;
  padding-left: 1rem;
  transition: visibility .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58), transform .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(120px)translateX(-20px);
}

@media (min-width: 992px) {
  .home-slides .section-vision .content .title-row .text {
    width: 50%;
    flex: 0 0 50%;
    left: 35%;
  }
}

@media (min-width: 1400px) {
  .home-slides .section-vision .content .title-row .text {
    width: 40%;
    flex: 0 0 40%;
    left: 40%;
    right: auto;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-vision .content .title-row .text {
    width: 30%;
    flex: 0 0 30%;
    left: 50%;
    right: auto;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row .text {
    z-index: 3;
    width: 80%;
    text-align: right;
    flex: 0 0 80%;
    padding-left: 0;
    padding-right: 1rem;
    top: auto;
    bottom: 2rem;
  }
}

.home-slides .section-vision .content .title-row .text:before {
  content: "";
  width: 2px;
  opacity: 0;
  background-color: #fff;
  transition: bottom .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: -2rem;
  bottom: 100%;
  left: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row .text:before {
    display: none;
  }
}

.home-slides .section-vision .content .title-row .text p {
  color: #f2f2f2;
  margin-bottom: 2.5rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.31818;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .text p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-vision .content .title-row .text p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .text p {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-vision .content .title-row .text p {
    font-size: 1rem;
  }
}

.home-slides .section-vision .content .title-row .text .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-vision .content .title-row .text .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-projects {
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
}

.home-slides .section-projects:before {
  display: none;
}

@media (max-width: 991.98px) {
  .home-slides .section-projects .fp-scroller {
    height: 100%;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .fp-scroller {
    min-height: 780px;
  }
}

.home-slides .section-projects.active .content .carousel {
  opacity: 1;
}

@media (min-width: 768px) {
  .home-slides .section-projects .background {
    height: 100vh;
    min-height: 700px;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-projects .background {
    min-height: 960px;
  }
}

.home-slides .section-projects .background:before {
  display: none;
}

.home-slides .section-projects .background.background-color {
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
}

.home-slides .section-projects .content {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .content {
    height: 100%;
    z-index: 2;
    padding: 50px 1rem;
    display: block;
    position: relative;
  }
}

@media (min-width: 768px) {
  .home-slides .section-projects .content {
    z-index: 2;
    width: 100%;
    max-width: 1160px;
    padding: 104px 1rem 4rem 140px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1600px) {
  .home-slides .section-projects .content {
    max-width: 1560px;
  }
}

.home-slides .section-projects .content:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#0098ca 28.65%, #0069ca 100%);
  position: absolute;
  inset: 0 calc(100% - 140px) 0 -999em;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .content:before {
    display: none;
  }
}

.home-slides .section-projects .content h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 4rem;
  font-family: Montserrat, sans-serif;
  font-size: 66px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-projects .content h2 {
    font-size: calc(19.2px + 3.9vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .content h2 {
    color: #f2f2f2;
    margin-bottom: 2rem;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.05;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-projects .content h2 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .content h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .home-slides .section-projects .content h2 {
    opacity: 0;
    transition: transform .4s cubic-bezier(.47, .17, .17, .58), opacity .4s cubic-bezier(.47, .17, .17, .58);
    transform: translateY(100%);
  }

  .fp-viewing-section3 .home-slides .section-projects .content h2 {
    opacity: 1;
    transform: translateY(0%);
  }
}

.home-slides .section-projects .content h2 strong {
  font-weight: 700;
}

.home-slides .section-projects .carousel {
  margin-bottom: 3rem;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
}

@media (min-width: 768px) {
  .home-slides .section-projects .carousel {
    align-items: stretch;
    margin: 0 -1.5rem 3rem;
    display: flex;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-projects .carousel {
    align-items: stretch;
    margin: 0 -3rem 3rem;
    display: flex;
  }
}

.Safari .home-slides .section-projects .carousel {
  transform: translateX(1rem);
}

.home-slides .section-projects .carousel.slick-slider .slick-prev {
  display: none !important;
}

.home-slides .section-projects .carousel.slick-slider .slick-track {
  align-items: stretch;
  display: flex;
}

.home-slides .section-projects .carousel.slick-slider .slick-slide {
  float: left;
  width: 160px;
  margin-right: 4rem;
}

@media (min-width: 768px) {
  .home-slides .section-projects .carousel.slick-slider .slick-slide {
    width: auto;
    margin-right: 0;
    padding: 0 1.5rem;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-projects .carousel.slick-slider .slick-slide {
    padding: 0 3.5rem;
  }
}

.home-slides .section-projects .carousel.slick-slider .slick-slide > div {
  height: 100%;
}

.home-slides .section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .title {
  opacity: 1;
}

.home-slides .section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-slides .section-projects .carousel.slick-slider .slick-slide.slick-current .carousel-item .text {
  opacity: 1;
}

.home-slides .section-projects .carousel-item {
  height: 100%;
  position: relative;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .carousel-item {
    margin-bottom: 30px;
  }
}

.home-slides .section-projects .carousel-item-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.home-slides .section-projects .carousel-item .title {
  opacity: .7;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-start;
  margin-bottom: 10px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58);
  display: flex;
}

.home-slides .section-projects .carousel-item .title h3 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-projects .carousel-item .title h3 {
    font-size: calc(14.6px + .45vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .carousel-item .title h3 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-projects .carousel-item .title h3 {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-projects .carousel-item .image {
  width: 100%;
  flex: none;
  margin-bottom: 10px;
  position: relative;
}

.home-slides .section-projects .carousel-item .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: width .4s cubic-bezier(.47, .17, .17, .58), height .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.home-slides .section-projects .carousel-item .text {
  opacity: .7;
  flex: none;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58);
}

.home-slides .section-projects .carousel-item .text p {
  color: #f2f2f2;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.58824;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-projects .carousel-item .text p {
    font-size: calc(14.3px + .225vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .carousel-item .text p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.28571;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .carousel-item .text p {
    font-size: 16px;
  }
}

.home-slides .section-projects .carousel-item .visit {
  color: #fff;
  text-transform: uppercase;
  margin-top: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .carousel-item .visit {
    width: auto;
    flex: none;
    order: 2;
    margin-top: 20px;
  }
}

.home-slides .section-projects .links {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .links {
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1400px) {
  .home-slides .section-projects .links {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.home-slides .section-projects .links .visit {
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .links .visit {
    width: 100%;
    flex: 0 0 100%;
    order: 2;
  }
}

.home-slides .section-projects .links .btn-carousel-right {
  color: #e9e9e9;
  text-transform: uppercase;
  padding-right: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.28571;
  text-decoration: none;
  transition: padding-right .4s cubic-bezier(.47, .17, .17, .58);
  position: relative;
}

.home-slides .section-projects .links .btn-carousel-right:after {
  content: "";
  width: 35px;
  height: 31px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px 31px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .home-slides .section-projects .links .btn-carousel-right {
    width: 100%;
    text-align: right;
    flex: 0 0 100%;
    margin-bottom: 1.5rem;
  }
}

.home-slides .section-projects .links .btn-carousel-right:hover:after {
  opacity: 0;
  visibility: hidden;
  right: -24px;
}

@media (max-width: 991.98px) {
  .home-slides .section-book .fp-scroller {
    height: 100%;
  }
}

.home-slides .section-book .background:before {
  content: "";
  z-index: 1;
  background-blend-mode: multiply;
  background: linear-gradient(270deg, #3a3a3a00 8.63%, #3a3a3a85 50.57%);
  position: absolute;
  inset: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section-book .content {
    z-index: 2;
    margin-top: 5rem;
    padding: 2rem 1rem;
    position: relative;
  }
}

@media (min-width: 768px) {
  .home-slides .section-book .content {
    z-index: 2;
    width: 100%;
    max-width: 1160px;
    opacity: 0;
    margin-top: 2.5rem;
    padding: 2rem 1rem 0 140px;
    transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fp-viewing-section4 .home-slides .section-book .content {
    opacity: 1;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-book .content {
    max-width: 1560px;
  }
}

.home-slides .section-book .content .content-top {
  max-width: 580px;
  margin-bottom: 3rem;
}

@media (min-width: 1400px) {
  .home-slides .section-book .content .content-top {
    margin-bottom: 5rem;
  }
}

.home-slides .section-book .content .content-top h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-book .content .content-top h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-book .content .content-top h2 {
    color: #f2f2f2;
    margin-bottom: 1.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-book .content .content-top h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-book .content .content-top h2 {
    font-size: 2.5rem;
  }
}

.home-slides .section-book .content .content-top h2 strong {
  font-weight: 700;
}

.home-slides .section-book .content .content-top .text {
  margin-bottom: 2rem;
}

.home-slides .section-book .content .content-top p {
  color: #f2f2f2;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.27273;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-book .content .content-top p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-book .content .content-top p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33333;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-book .content .content-top p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-book .content .content-top p {
    font-size: 1rem;
  }
}

.home-slides .section-book .content .content-top p:last-child {
  margin-bottom: 0;
}

.home-slides .section-book .content .content-top .links {
  align-items: center;
  display: flex;
}

.home-slides .section-book .content .content-top .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  margin-left: 2.75rem;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-book .content .content-top .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-book .content .content-top .link-bullet:first-child {
  margin-left: 0;
}

.home-slides .section-book .content .content-bottom {
  width: 100%;
  max-width: 1160px;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 767.98px) {
  .home-slides .section-book .content .content-bottom {
    display: none;
  }
}

.home-slides .section-book .content .content-bottom .content-col {
  width: 60%;
  border-left: 1px solid #fff;
  flex: 0 0 60%;
}

.home-slides .section-book .content .content-bottom .content-col.active p {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.home-slides .section-book .content .content-bottom .content-col:first-child {
  width: 40%;
  border-left: 0;
  flex: 0 0 40%;
}

.home-slides .section-book .content .content-bottom .content-col:first-child h3, .home-slides .section-book .content .content-bottom .content-col:first-child p {
  padding-left: 0;
}

.home-slides .section-book .content .content-bottom .content-col h3 {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  padding: 0 2rem 1.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-book .content .content-bottom .content-col h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-book .content .content-bottom .content-col h3 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-book .content .content-bottom .content-col h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

.home-slides .section-book .content .content-bottom .content-col p {
  color: #f2f2f2;
  opacity: 0;
  visibility: hidden;
  padding: 1rem 2rem 1.25rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.27273;
  text-decoration: none;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), transform .4s cubic-bezier(.47, .17, .17, .58);
  transform: translateY(100px);
}

@media (max-width: 1200px) {
  .home-slides .section-book .content .content-bottom .content-col p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-book .content .content-bottom .content-col p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-book .content .content-bottom .content-col p {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-book .content .content-bottom .content-col p:last-child {
  margin-bottom: 0;
}

.home-slides .section-book .content .content-bottom .content-col p strong {
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .home-slides .section-approach .fp-scroller, .home-slides .section-vision .fp-scroller, .home-slides .section-knowledge .fp-scroller {
    height: 100%;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .fp-scroller, .home-slides .section-vision .fp-scroller, .home-slides .section-knowledge .fp-scroller {
    min-height: 700px;
  }
}

.home-slides .section-approach.active .content, .home-slides .section-vision.active .content, .home-slides .section-knowledge.active .content, .home-slides .section-approach.active .content .carousel, .home-slides .section-vision.active .content .carousel, .home-slides .section-knowledge.active .content .carousel {
  opacity: 1;
}

.home-slides .section-approach .background:before, .home-slides .section-vision .background:before, .home-slides .section-knowledge .background:before {
  content: "";
  z-index: 1;
  background-blend-mode: multiply;
  background: linear-gradient(#3a3a3a00 6.87%, #1d1d1d85 50.72%);
  position: absolute;
  inset: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .content, .home-slides .section-vision .content, .home-slides .section-knowledge .content {
    z-index: 2;
    height: 100%;
    margin-top: 5rem;
    padding: 2rem 1rem;
    position: relative;
  }
}

@media (min-width: 768px) {
  .home-slides .section-approach .content, .home-slides .section-vision .content, .home-slides .section-knowledge .content {
    z-index: 2;
    width: 100%;
    max-width: 1160px;
    margin-top: 5rem;
    padding: 2rem 1rem 2rem 140px;
    transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fp-viewing-section5 .home-slides .section-approach .content, .fp-viewing-section5 .home-slides .section-vision .content, .fp-viewing-section5 .home-slides .section-knowledge .content {
    opacity: 1;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-approach .content, .home-slides .section-vision .content, .home-slides .section-knowledge .content {
    max-width: 1560px;
  }
}

.home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 1.625rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-approach .content h2, .home-slides .section-vision .content h2, .home-slides .section-knowledge .content h2 {
    margin-bottom: 6.5rem;
  }
}

.home-slides .section-approach .content h2 strong, .home-slides .section-vision .content h2 strong, .home-slides .section-knowledge .content h2 strong {
  font-weight: 700;
}

.home-slides .section-approach .content .text-wrapper, .home-slides .section-vision .content .text-wrapper, .home-slides .section-knowledge .content .text-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .content .text-wrapper, .home-slides .section-vision .content .text-wrapper, .home-slides .section-knowledge .content .text-wrapper {
    flex-wrap: wrap;
  }
}

.home-slides .section-approach .content .text, .home-slides .section-vision .content .text, .home-slides .section-knowledge .content .text {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  .home-slides .section-approach .content .text, .home-slides .section-vision .content .text, .home-slides .section-knowledge .content .text {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 2.75rem;
    padding-right: 6rem;
  }
}

.home-slides .section-approach .content h3, .home-slides .section-vision .content h3, .home-slides .section-knowledge .content h3 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-approach .content h3, .home-slides .section-vision .content h3, .home-slides .section-knowledge .content h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-approach .content h3, .home-slides .section-vision .content h3, .home-slides .section-knowledge .content h3 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-approach .content h3, .home-slides .section-vision .content h3, .home-slides .section-knowledge .content h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .content h3, .home-slides .section-vision .content h3, .home-slides .section-knowledge .content h3 {
    font-size: 1.375rem;
  }
}

.home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
  color: #f2f2f2;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.31818;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (min-width: 768px) {
  .home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
    margin-bottom: 2.75rem;
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.38889;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-approach .content p, .home-slides .section-vision .content p, .home-slides .section-knowledge .content p {
    font-size: 1rem;
  }
}

.home-slides .section-approach .content .link-bullet, .home-slides .section-vision .content .link-bullet, .home-slides .section-knowledge .content .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-approach .content .link-bullet, .home-slides .section-vision .content .link-bullet, .home-slides .section-knowledge .content .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-approach .content .link-bullet:first-child, .home-slides .section-vision .content .link-bullet:first-child, .home-slides .section-knowledge .content .link-bullet:first-child {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge:after {
    content: "";
    height: 300px;
    background-color: #e0e0e0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .home-slides .section-knowledge .fp-tableCell {
    z-index: 1;
    position: relative;
  }
}

.home-slides .section-knowledge .fp-scrollable {
  touch-action: none;
}

.home-slides .section-knowledge .section-wrapper {
  height: 100vh;
  position: relative;
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .section-wrapper {
    height: auto;
  }
}

.home-slides .section-knowledge .background:before {
  content: "";
  z-index: 1;
  background-blend-mode: multiply;
  background: linear-gradient(#3a3a3a00 0%, #3a3a3a6b 38.14%);
  position: absolute;
  inset: 0;
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content {
    z-index: 2;
    height: 100%;
    padding: 7rem 1rem 2rem;
    position: relative;
  }
}

@media (min-width: 768px) {
  .home-slides .section-knowledge .content {
    z-index: 2;
    width: 100%;
    max-width: 1160px;
    margin-top: 3rem;
    padding: 2rem 1rem 2rem 140px;
    transition: opacity .4s cubic-bezier(.47, .17, .17, .58) .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fp-viewing-section6 .home-slides .section-knowledge .content {
    opacity: 1;
  }
}

@media (min-width: 1600px) {
  .home-slides .section-knowledge .content {
    max-width: 1560px;
  }
}

.home-slides .section-knowledge .content h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-knowledge .content h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-knowledge .content h2 {
    color: #f2f2f2;
    margin-bottom: 1.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-knowledge .content h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content h2 {
    font-size: 2.5rem;
  }
}

.home-slides .section-knowledge .content h2 strong {
  font-weight: 700;
}

.home-slides .section-knowledge .content .text-intro {
  max-width: 40rem;
  margin-bottom: 8rem;
}

@media (max-width: 1599.98px) {
  .home-slides .section-knowledge .content .text-intro {
    margin-bottom: 4rem;
  }
}

.home-slides .section-knowledge .content .text-intro p {
  color: #f2f2f2;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.22727;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-knowledge .content .text-intro p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-knowledge .content .text-intro p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.27778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-knowledge .content .text-intro p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content .text-intro p {
    font-size: 1rem;
  }
}

.home-slides .section-knowledge .content .text-intro a {
  opacity: 1;
}

.home-slides .section-knowledge .content .text-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -1.25rem;
  display: flex;
}

@media (min-width: 768px) {
  .home-slides .section-knowledge .content .text-wrapper.slick-slider .slick-list {
    overflow: hidden;
  }
}

.home-slides .section-knowledge .content .text-wrapper.slick-slider .slick-slide {
  float: left;
}

.home-slides .section-knowledge .content .text {
  width: 25%;
  flex: 0 0 25%;
  padding: 1.25rem;
  position: relative;
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content .text {
    width: auto;
    max-width: 290px;
    flex: none;
  }
}

.home-slides .section-knowledge .content .text:hover a {
  opacity: 1;
  transform: translateY(0);
}

.home-slides .section-knowledge .content h3 {
  color: #fff;
  margin-bottom: 1.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36364;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-knowledge .content h3 {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-knowledge .content h3 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.44444;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .home-slides .section-knowledge .content h3 {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content h3 {
    font-size: 1.5rem;
  }
}

.home-slides .section-knowledge .content p {
  color: #f2f2f2;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33333;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home-slides .section-knowledge .content p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 1599.98px) {
  .home-slides .section-knowledge .content p {
    color: #f2f2f2;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) {
  .home-slides .section-knowledge .content p {
    font-size: 1rem;
  }
}

.home-slides .section-knowledge .content .link-bullet {
  color: #f2f2f2;
  text-transform: uppercase;
  opacity: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), transform .4s cubic-bezier(.47, .17, .17, .58);
  transform: translateY(50px);
}

@media (max-width: 1200px) {
  .home-slides .section-knowledge .content .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.home-slides .section-knowledge .content .link-bullet:first-child {
  margin-left: 0;
}

.home-slides .section-projects .content {
  justify-content: center;
}

@media (max-width: 767.98px) {
  .section-vision .fp-tableCell, .section-projects .fp-tableCell, .section-approach .fp-tableCell, .section-last .fp-tableCell, .home-slides .section-vision, .home-slides .section-projects, .section-approach, .section-last {
    height: auto !important;
  }

  .home-slides .section-last:before {
    display: none !important;
  }
}

.home-slides .section-projects .content h2 {
  opacity: 1 !important;
  transition: none !important;
  transform: none !important;
}

h2.big span, #fullpage-menu a span {
  text-transform: none !important;
}

@media (max-width: 767.98px) {
  .nomax {
    min-height: auto !important;
  }
}

.home-slides .section-projects .carousel-item .image-logineko-project {
  height: 200px;
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .carousel-item .image-logineko-project {
    height: 170px;
  }
}

@media (max-width: 1399.98px) {
  .home-slides .section-projects .carousel-item .image-logineko-project {
    height: 160px;
  }
}

@media (max-width: 1199.98px) {
  .home-slides .section-projects .carousel-item .image-logineko-project {
    height: 150px;
  }
}

@media (max-width: 991.98px) {
  .home-slides .section-projects .carousel-item .image-logineko-project {
    height: 200px;
  }
}

.home-slides .section-projects .carousel-item .image {
  height: 200px;
}

@media (max-width: 1599.98px) {
  .home-slides .section-projects .carousel-item .image {
    height: 170px;
  }
}

@media (max-width: 1399.98px) {
  .home-slides .section-projects .carousel-item .image {
    height: 160px;
  }
}

@media (max-width: 1199.98px) {
  .home-slides .section-projects .carousel-item .image {
    height: 150px;
  }
}

@media (max-width: 991.98px) {
  .home-slides .section-projects .carousel-item .image {
    height: 200px;
  }
}

.home-slides .projects-eko, .home-slides .projects-eko .background.background-color, .home-slides .projects-eko .content:before {
  background: linear-gradient(#aab555 0%, #799b38 100%);
}

.home-slides .projects-eko .content p {
  max-width: 1100px;
}

.home-slides .projects-eko h2 span {
  text-transform: none !important;
}

@media (min-width: 992px) {
  .home-slides .section-projects .content {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.cover-project .content-wrapper p a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.cover-project .content-wrapper p a:hover {
  text-decoration: none;
}

.home-slides .section-projects .carousel-item .visit:after {
  display: none !important;
}

.home-slides .section-projects .carousel-item .title h3 a, .home-slides .section-projects .carousel-item .title h3 a:hover {
  color: #fff;
}

.home-slides .section-projects .content p a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.home-slides .section-projects .content p a:hover {
  color: #fff;
  text-decoration: none;
}

.home-slides .section-projects .carousel-item .title {
  justify-content: flex-start !important;
}

@media screen and (max-width: 1660px) {
  .home-slides .section-projects .carousel-item .title {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1660px) {
  .home-slides .section-projects .carousel-item .title h3 {
    font-size: 16px !important;
  }
}

.cover-news {
  position: relative;
}

.cover-news-wrapper {
  width: 100%;
  max-width: 1560px;
  min-height: 640px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 1599.98px) {
  .cover-news-wrapper {
    max-width: 1160px;
  }
}

.cover-news-wrapper > .row {
  height: 100%;
}

@media (min-width: 768px) {
  .cover-news-wrapper > .row {
    min-height: 640px;
  }
}

.cover-news-wrapper > .row .text {
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

@media (min-width: 768px) {
  .cover-news-wrapper > .row .text {
    min-height: 640px;
  }
}

.cover-news-wrapper .text-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-right: 6rem;
  display: flex;
}

@media (max-width: 1399.98px) {
  .cover-news-wrapper .text-wrapper {
    padding-right: 2rem;
  }
}

@media (max-width: 767.98px) {
  .cover-news-wrapper .text-wrapper {
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .cover-news-wrapper .text-wrapper {
    min-height: 520px;
  }
}

.cover-news-wrapper h1 {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-family: Montserrat, sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: .875;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-news-wrapper h1 {
    font-size: calc(19px + 3.75vw);
  }
}

@media (max-width: 991.98px) {
  .cover-news-wrapper h1 {
    color: #fff;
  }
}

@media (max-width: 1399.98px) {
  .cover-news-wrapper h1 {
    font-size: 56px;
  }
}

@media (max-width: 1399.98px) and (max-width: 1200px) {
  .cover-news-wrapper h1 {
    font-size: calc(18.2px + 3.15vw);
  }
}

.cover-news-wrapper p {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.8;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-news-wrapper p {
    font-size: calc(14.6px + .45vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-news-wrapper p {
    font-family: Lato, sans-serif;
    font-size: 16 32;
    line-height: 400 / 16 32;
    font-weight: #333;
    color: none;
  }
}

@media (max-width: 1399.98px) {
  .cover-news-wrapper p {
    font-size: 16px;
  }
}

@media (max-width: 1399.98px) and (max-width: 1200px) {
  .cover-news-wrapper p {
    font-size: calc(14.2px + .15vw);
  }
}

.cover-news-wrapper p:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .cover-news-wrapper p {
    color: #fff;
  }
}

.cover-news-wrapper .date {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: -2.5rem;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  text-decoration: none;
  display: block;
}

@media (max-width: 1200px) {
  .cover-news-wrapper .date {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 991.98px) {
  .cover-news-wrapper .date {
    color: #fff;
  }
}

.cover-news-wrapper .link-bullet {
  color: #333;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-news-wrapper .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

@media (max-width: 991.98px) {
  .cover-news-wrapper .link-bullet {
    color: #fff;
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23FFFFFF' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
  }
}

.cover-news-wrapper .hero-scroll {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
}

@media (max-width: 991.98px) {
  .cover-news-wrapper .hero-scroll {
    bottom: 2rem;
  }
}

.cover-news-wrapper .hero-scroll .scroll-down {
  color: #000;
  text-transform: uppercase;
  writing-mode: tb-rl;
  padding: 42px .25rem 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.28571;
  text-decoration: none;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  .cover-news-wrapper .hero-scroll .scroll-down {
    color: #fff;
  }
}

.cover-news-wrapper .hero-scroll .scroll-down:before {
  content: "";
  width: 30px;
  height: 32px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 25V1h1v24h-1z' fill='%23000000' stroke='%23000000'/%3E%3Cpath d='M15.5 28L9.005 16.75h12.99L15.5 28z' fill='%23000000'/%3E%3Ccircle cx='15.5' cy='19.5' r='14.5' stroke='%23000000' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

@media (max-width: 991.98px) {
  .cover-news-wrapper .hero-scroll .scroll-down:before {
    background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 25V1h1v24h-1z' fill='%23FFFFFF' stroke='%23FFFFFF'/%3E%3Cpath d='M15.5 28L9.005 16.75h12.99L15.5 28z' fill='%23FFFFFF'/%3E%3Ccircle cx='15.5' cy='19.5' r='14.5' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
  }
}

.cover-news-image {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  .cover-news-image {
    width: 100%;
    z-index: -1;
    left: 0;
  }

  .cover-news-image:before {
    content: "";
    z-index: 1;
    background-color: #00000080;
    position: absolute;
    inset: 0;
  }
}

.cover-news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-news {
  overflow: hidden;
}

.page-news-blocks {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 1599.98px) {
  .page-news-blocks {
    max-width: 1160px;
  }
}

.page-news-blocks > .row {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media (min-width: 1600px) {
  .page-news-blocks > .row {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}

.page-news-blocks .block {
  margin-bottom: 2.5rem;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .page-news-blocks .block {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1600px) {
  .page-news-blocks .block {
    margin-bottom: 9rem;
    padding: 0 3rem;
  }
}

.page-news-blocks .block-wrapper {
  position: relative;
}

.page-news-blocks .block .image {
  margin-bottom: 1.5rem;
  position: relative;
}

@media (min-width: 1400px) {
  .page-news-blocks .block .image {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1599.98px) {
  .page-news-blocks .block .image:after {
    content: "";
    padding-bottom: 100%;
    display: block;
  }
}

.page-news-blocks .block .image img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .page-news-blocks .block .image img {
    height: 436px;
  }
}

@media (max-width: 1599.98px) {
  .page-news-blocks .block .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.page-news-blocks .block .text h2 {
  color: #151515;
  margin-bottom: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36364;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-news-blocks .block .text h2 {
    font-size: calc(14.8px + .6vw);
  }
}

@media (min-width: 768px) {
  .page-news-blocks .block .text h2 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1600px) {
  .page-news-blocks .block .text h2 {
    color: #151515;
    margin-bottom: 2.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33333;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .page-news-blocks .block .text h2 {
    font-size: calc(15px + .75vw);
  }
}

.page-news-blocks .block .text p {
  color: #151515;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-news-blocks .block .text p {
    font-size: calc(14.6px + .45vw);
  }
}

@media (max-width: 1599.98px) {
  .page-news-blocks .block .text p {
    color: #151515;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.875;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-news-blocks .block .text p {
    font-size: calc(14.2px + .15vw);
  }
}

@media (min-width: 768px) {
  .page-news-blocks .block .text p {
    margin-bottom: 1.75rem;
  }
}

.page-news-blocks .block .text .link-bullet {
  color: #333;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  padding: .5rem 0 .5rem 2.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33333;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-news-blocks .block .text .link-bullet {
    font-size: calc(14.4px + .3vw);
  }
}

@media (max-width: 1599.98px) {
  .page-news-blocks .block .text .link-bullet {
    color: #333;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.375;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-news-blocks .block .text .link-bullet {
    font-size: calc(14.2px + .15vw);
  }
}

.page-news-single {
  padding-top: 5.75rem;
}

.page-news-single-wrapper {
  padding-bottom: 3rem;
}

.cover-project {
  position: relative;
}

.cover-project .image {
  height: 100vh;
  max-height: 1080px;
  min-height: 640px;
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

.cover-project .image .poster {
  z-index: 2;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  inset: 0;
}

.cover-project .image .poster:before {
  content: "";
  z-index: 2;
  mix-blend-mode: multiply;
  opacity: .95;
  background: linear-gradient(0deg, #0000 0%, #0006 99.24%);
  position: absolute;
  inset: 0;
}

.cover-project .image .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-project .image .poster img.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .cover-project .image .poster img {
    display: none;
  }

  .cover-project .image .poster img.mobile {
    display: block;
  }
}

.cover-project .image .poster video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991.98px) {
  .cover-project .image .poster video {
    display: none;
  }
}

.cover-project .image .btn-play {
  width: 5rem;
  height: 5rem;
  z-index: 3;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .cover-project .image .btn-play {
    top: 65%;
    left: 50%;
  }
}

.cover-project .image .video-overlay {
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  background-color: #151515cc;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58);
  position: fixed;
  inset: 0;
}

.overlay-video-active .cover-project .image .video-overlay {
  opacity: 1;
  visibility: visible;
}

.cover-project .image .video-overlay .video-close {
  z-index: 5;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.cover-project .image .video-overlay .video-close span {
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.cover-project .image .video-overlay .video-close span:before {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.cover-project .image .video-overlay .video-close span:after {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.cover-project .image .video-overlay-wrapper {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .cover-project .image .video-overlay-wrapper {
    width: 100%;
  }
}

.cover-project .image .video-overlay .iframe-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.cover-project .image .video-overlay iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cover-project .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  padding: 0 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1599.98px) {
  .cover-project .content {
    max-width: 1160px;
  }
}

@media (max-width: 767.98px) {
  .cover-project .content {
    top: 35%;
  }
}

.cover-project .content-wrapper {
  max-width: 60%;
}

@media (max-width: 767.98px) {
  .cover-project .content-wrapper {
    max-width: 100%;
  }
}

.cover-project .content-wrapper h1 {
  color: #f2f2f2;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-project .content-wrapper h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-project .content-wrapper h1 {
    color: #f2f2f2;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-project .content-wrapper h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

.cover-project .content-wrapper p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: .95;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-project .content-wrapper p {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-project .content-wrapper p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-project .content-wrapper p {
    font-size: calc(14.8px + .6vw);
  }
}

.cover-project .scroll-down {
  z-index: 2;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%) !important;
}

.cover-project .scroll-down .icon {
  width: 31px;
  height: 34px;
  margin: 0 auto .5rem;
}

.cover-project .scroll-down span {
  color: #fff;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.71429;
  text-decoration: none;
  display: block;
}

.cover-project .scroll-down svg path {
  animation-name: arrowDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.page-project {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .page-project {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .page-project {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.page-project .content-quote:before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%230095C6' fill-opacity='.3'/%3E%3C/svg%3E");
}

.page-id-126 .page-project .content-quote:before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath opacity='.4' d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%239DAB47'/%3E%3C/svg%3E");
}

.page-id-126 .page-project .content-quote p {
  color: #9dab47;
}

.page-id-126 .page-project .content-offset ul li:before, .page-id-126 .page-project .text-container ul li:before, .page-id-126 .page-project .content-text-image ul li:before {
  background-color: #9dab47;
}

.about-project {
  position: relative;
}

.about-project .image {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.about-project .image:before {
  content: "";
  z-index: 1;
  background: radial-gradient(29.06% 41.09% at 70.94% 51.99%, #9dab47bf 0%, #9dab47de 100%);
  position: absolute;
  inset: 0;
}

.about-project .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-project-wrapper {
  width: 100%;
  max-width: 1560px;
  z-index: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2.5rem;
  padding: 3rem 1rem;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .about-project-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .about-project-wrapper {
    margin: 0 auto 3.75rem;
    padding: 4rem;
  }
}

@media (min-width: 992px) {
  .about-project-wrapper {
    padding: 4rem 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-wrapper {
    margin: 0 auto 5.75rem;
    padding: 9rem 5rem 11rem;
  }
}

.about-project-wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-wrapper h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 768px) {
  .about-project-wrapper h2 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1600px) {
  .about-project-wrapper h2 {
    color: #fff;
    margin-bottom: 7rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-wrapper h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.about-project-wrapper h2 strong {
  font-weight: 700;
}

.about-project-wrapper .carousel-nav {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 3rem;
}

@media (min-width: 768px) {
  .about-project-wrapper .carousel-nav {
    margin: 0 auto 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-wrapper .carousel-nav {
    margin: 0 auto 11rem;
  }
}

@media (max-width: 1199.98px) {
  .about-project-wrapper .carousel-nav {
    padding: 0 3rem;
  }
}

.about-project-wrapper .carousel-nav.slick-slider {
  position: relative;
}

.about-project-wrapper .carousel-nav.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #77842b;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
}

.about-project-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
    right: 0;
  }
}

.about-project-wrapper .carousel-nav.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-wrapper .carousel-nav.slick-slider .slick-track {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.about-project-wrapper .carousel-nav.slick-slider .slick-slide {
  float: left;
  text-align: center;
  cursor: pointer;
}

.about-project-wrapper .carousel-nav.slick-slider .slick-slide.slick-current .element {
  color: #fff;
  font-weight: 800;
}

.about-project-wrapper .carousel-nav.slick-slider .slick-slide .element {
  color: #ffffffb3;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11111;
  text-decoration: none;
  transition: font-size .4s cubic-bezier(.47, .17, .17, .58), font-weight .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .about-project-wrapper .carousel-nav.slick-slider .slick-slide .element {
    font-size: calc(14.4px + .3vw);
  }
}

.about-project-wrapper .carousel-text.slick-slider {
  position: relative;
}

.about-project-wrapper .carousel-text.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #77842b;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
    left: -4rem;
  }
}

.about-project-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
    right: -4rem;
  }
}

.about-project-wrapper .carousel-text.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-wrapper .carousel-text.slick-slider .slick-slide {
  float: left;
}

.about-project-wrapper .carousel-text .element h3 {
  color: #fff;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-wrapper .carousel-text .element h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (min-width: 1600px) {
  .about-project-wrapper .carousel-text .element h3 {
    color: #fff;
    margin-bottom: 3.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-wrapper .carousel-text .element h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (min-width: 768px) {
  .about-project-wrapper .carousel-text .element .text {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

.about-project-wrapper .carousel-text .element .text p {
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.77778;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-wrapper .carousel-text .element .text p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 768px) {
  .about-project-wrapper .carousel-text .element .text p {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 1600px) {
  .about-project-wrapper .carousel-text .element .text p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.72727;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-wrapper .carousel-text .element .text p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (min-width: 768px) {
  .about-project-wrapper .carousel-text .element .text p:nth-child(odd) {
    padding-right: 2.25rem;
  }

  .about-project-wrapper .carousel-text .element .text p:nth-child(2n) {
    padding-left: 2.25rem;
  }
}

.about-project-book {
  position: relative;
}

.about-project-book .image {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.about-project-book .image:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#17a2b896 .9% 57.97%, #17a2b896 100%);
  position: absolute;
  inset: 0;
}

.about-project-book .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-project-book-wrapper {
  width: 100%;
  max-width: 1560px;
  z-index: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2.5rem;
  padding: 3rem 1rem 0;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .about-project-book-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper {
    margin: 0 auto;
    padding: 4rem;
  }
}

@media (min-width: 992px) {
  .about-project-book-wrapper {
    padding: 4rem 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper {
    margin: 0 auto;
    padding: 9rem 5rem 11rem;
  }
}

.about-project-book-wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper h2 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper h2 {
    color: #fff;
    margin-bottom: 7rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.about-project-book-wrapper h2 strong {
  font-weight: 700;
}

.about-project-book-wrapper .carousel-nav {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 3rem;
}

@media (min-width: 768px) {
  .about-project-book-wrapper .carousel-nav {
    margin: 0 auto 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-nav {
    margin: 0 auto 11rem;
  }
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav {
    padding: 0 3rem;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider {
  position: relative;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
    right: 0;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-track {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide {
  float: left;
  text-align: center;
  cursor: pointer;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide.slick-current .element {
  color: #fff;
  font-size: 22px;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-slide.slick-current .element {
    font-size: calc(14.8px + .6vw);
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide .element {
  color: #ffffffb3;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11111;
  text-decoration: none;
  transition: font-size .4s cubic-bezier(.47, .17, .17, .58), font-weight .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-slide .element {
    font-size: calc(14.4px + .3vw);
  }
}

.about-project-book-wrapper .carousel-text {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.about-project-book-wrapper .carousel-text.slick-slider {
  position: relative;
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
    left: -4rem;
  }
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
    right: -4rem;
  }
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-slide {
  float: left;
}

.about-project-book-wrapper .carousel-text .element h3 {
  color: #fff;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    color: #fff;
    margin-bottom: 3.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper .carousel-text .element .text {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

.about-project-book-wrapper .carousel-text .element p {
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.77778;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-text .element p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.72727;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element p {
    font-size: calc(14.8px + .6vw);
  }
}

.section-aim {
  background-color: #9dab47;
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  .section-aim {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1600px) {
  .section-aim {
    padding-top: 10rem;
    padding-bottom: 4rem;
  }
}

.section-aim:last-child {
  margin-bottom: -2.5rem;
}

@media (min-width: 768px) {
  .section-aim:last-child {
    margin-bottom: -4rem;
  }
}

@media (min-width: 992px) {
  .section-aim:last-child {
    margin-bottom: -7rem;
  }
}

.section-aim-wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 1599.98px) {
  .section-aim-wrapper {
    max-width: 1160px;
  }
}

.section-aim .section-top {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .section-aim .section-top {
    align-items: flex-start;
    display: flex;
  }
}

@media (min-width: 992px) {
  .section-aim .section-top {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1600px) {
  .section-aim .section-top {
    margin-bottom: 8rem;
  }
}

.section-aim .section-top h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (min-width: 768px) {
  .section-aim .section-top h2 {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0;
    padding-right: 3.5rem;
  }
}

@media (min-width: 992px) {
  .section-aim .section-top h2 {
    padding-right: 5rem;
  }
}

@media (min-width: 1200px) {
  .section-aim .section-top h2 {
    padding-right: 7rem;
  }
}

@media (max-width: 1200px) {
  .section-aim .section-top h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 1600px) {
  .section-aim .section-top h2 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .section-aim .section-top h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.section-aim .section-top h2 strong {
  font-weight: 700;
}

.section-aim .section-top p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.63636;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .section-aim .section-top p {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 1599.98px) {
  .section-aim .section-top p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.77778;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .section-aim .section-top p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 768px) {
  .section-aim .section-top p {
    width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .section-aim .section-top p {
    padding-right: 3rem;
  }
}

@media (min-width: 1600px) {
  .section-aim .section-top p {
    color: #fff;
    padding-right: 5rem;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.66667;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .section-aim .section-top p {
    font-size: calc(15px + .75vw);
  }
}

.section-aim .section-bottom {
  position: relative;
}

.section-aim .section-bottom:before {
  content: "";
  z-index: 1;
  background: #9dab47;
  position: absolute;
  inset: 0 100% 0 -999em;
}

.section-aim .section-bottom .carousel {
  margin-bottom: 3.75rem;
}

.section-aim .section-bottom .carousel.slick-slider .slick-prev {
  display: none !important;
}

.section-aim .section-bottom .carousel.slick-slider .slick-slide {
  float: left;
  width: 280px;
  margin-right: 4rem;
}

@media (max-width: 767.98px) {
  .section-aim .section-bottom .carousel.slick-slider .slick-slide {
    margin-right: 1rem;
  }
}

.section-aim .section-bottom .carousel .element .image {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  position: relative;
  overflow: hidden;
}

.section-aim .section-bottom .carousel .element .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-aim .section-bottom .carousel .element .text {
  padding-right: 4rem;
}

.section-aim .section-bottom .carousel .element .text p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22222;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .section-aim .section-bottom .carousel .element .text p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 1600px) {
  .section-aim .section-bottom .carousel .element .text p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.16667;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .section-aim .section-bottom .carousel .element .text p {
    font-size: calc(15px + .75vw);
  }
}

.section-aim .section-bottom .links {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.section-aim .section-bottom .links .btn-carousel-right {
  color: #fff;
  text-transform: uppercase;
  padding-right: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.28571;
  text-decoration: none;
  transition: padding-right .4s cubic-bezier(.47, .17, .17, .58);
  position: relative;
}

.section-aim .section-bottom .links .btn-carousel-right:after {
  content: "";
  width: 35px;
  height: 31px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 31'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 16H1v-1h24v1z' fill='%23E9E9E9' stroke='%23fff'/%3E%3Cpath d='M28 15.5l-11.25 6.495V9.005L28 15.5z' fill='%23E9E9E9'/%3E%3Ccircle cx='19.5' cy='15.5' r='14.5' transform='rotate(-90 19.5 15.5)' stroke='%23E9E9E9' stroke-width='2'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px 31px;
  transition: opacity .4s cubic-bezier(.47, .17, .17, .58), visibility .4s cubic-bezier(.47, .17, .17, .58), right .4s cubic-bezier(.47, .17, .17, .58);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.section-aim .section-bottom .links .btn-carousel-right:hover:after {
  opacity: 0;
  visibility: hidden;
  right: -24px;
}

.cover-book {
  background: linear-gradient(#4ea8b6bf .9%, #4ea6b4bf 64.56%, #389dadbf 75.02%, #81c5cfbf 100%);
  position: relative;
}

.cover-book .background {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .cover-book .background {
    top: 120px;
    bottom: 120px;
    left: 85px;
  }
}

.cover-book .background img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .cover-book .background img {
    width: auto;
    height: 100%;
  }
}

.cover-book .image-mobile {
  height: 100vh;
  max-height: 1080px;
  min-height: 640px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: .25rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 992px) {
  .cover-book .image-mobile {
    display: none;
  }
}

.cover-book .image-mobile img {
  width: 100%;
  max-width: 480px;
  height: auto;
}

.cover-book .image {
  height: 100vh;
  max-height: 1080px;
  min-height: 640px;
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .cover-book .image {
    display: none;
  }
}

.cover-book .image img {
  height: 100%;
  max-height: 800px;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.cover-book .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  padding: 0 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1599.98px) {
  .cover-book .content {
    max-width: 1160px;
  }
}

@media (max-width: 767.98px) {
  .cover-book .content {
    top: 40%;
  }
}

.cover-book .content-wrapper {
  max-width: 50%;
}

@media (max-width: 767.98px) {
  .cover-book .content-wrapper {
    max-width: 100%;
  }
}

.cover-book .content-wrapper h1 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-book .content-wrapper h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-book .content-wrapper h1 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .cover-book .content-wrapper h1 {
    color: #fff;
    margin-bottom: 1rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.cover-book .content-wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  max-width: 572px;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-book .content-wrapper h2 {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-book .content-wrapper h2 {
    color: #fff;
    max-width: 360px;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper h2 {
    font-size: calc(14.8px + .6vw);
  }
}

@media (max-width: 767.98px) {
  .cover-book .content-wrapper h2 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper h2 {
    font-size: calc(14.8px + .6vw);
  }
}

.cover-book .content-wrapper p {
  max-width: 572px;
  color: #fff;
  margin-top: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}

@media (min-width: 768px) {
  .cover-book .content-wrapper p {
    margin-top: 52px;
  }
}

@media (max-width: 1200px) {
  .cover-book .content-wrapper p {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 1599.98px) {
  .cover-book .content-wrapper p {
    color: #fff;
    max-width: 376px;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33333;
    text-decoration: none;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper p {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-book .content-wrapper .cta {
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .cover-book .content-wrapper .cta {
    margin-top: 5rem;
  }
}

.cover-book .content-wrapper .cta .btn, .cover-book .content-wrapper .cta .content-carousel.slick-slider .slick-arrow, .content-carousel.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .multimedia-block-carousel .slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow {
  display: inline-block;
}

.cover-book .content-wrapper .cta .btn-white {
  color: #123156;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 8px;
  padding: .875rem 1.5rem 1rem;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .cover-book .content-wrapper .cta .btn-white {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 767.98px) {
  .cover-book .content-wrapper .cta .btn-white {
    color: #123156;
    padding: .625rem 1rem .75rem;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper .cta .btn-white {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-book .content-wrapper .cta .btn-white:hover {
  background-color: #fff9;
}

.cover-book .content-wrapper .cta .btn-plain, .cover-book .content-wrapper .cta .content-carousel.slick-slider .slick-arrow, .content-carousel.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .multimedia-block-carousel .slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow {
  color: #fff;
  text-transform: uppercase;
  padding: .75rem 1.5rem 1rem;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-book .content-wrapper .cta .btn-plain, .cover-book .content-wrapper .cta .content-carousel.slick-slider .slick-arrow, .content-carousel.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .multimedia-block-carousel .slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 767.98px) {
  .cover-book .content-wrapper .cta .btn-plain, .cover-book .content-wrapper .cta .content-carousel.slick-slider .slick-arrow, .content-carousel.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .multimedia-block-carousel .slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow {
    color: #fff;
    padding: .625rem 1rem .75rem;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-book .content-wrapper .cta .btn-plain, .cover-book .content-wrapper .cta .content-carousel.slick-slider .slick-arrow, .content-carousel.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .multimedia-block-carousel .slick-slider .slick-arrow, .multimedia-block-carousel .slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-nav.slick-slider .cover-book .content-wrapper .cta .slick-arrow, .cover-book .content-wrapper .cta .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow, .about-project-book-wrapper .carousel-text.slick-slider .cover-book .content-wrapper .cta .slick-arrow {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-book .scroll-down {
  z-index: 2;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%) !important;
}

.cover-book .scroll-down .icon {
  width: 31px;
  height: 34px;
  margin: 0 auto .5rem;
}

.cover-book .scroll-down span {
  color: #fff;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.71429;
  text-decoration: none;
  display: block;
}

.cover-book .scroll-down svg path {
  animation-name: arrowDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.page-book-wrapper {
  width: 100%;
  max-width: 1492px;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .page-book-wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .page-book-wrapper {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

@media (max-width: 991.98px) {
  .page-book-wrapper .sidemenu {
    width: 100%;
    max-width: 576px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 auto;
    padding: 0 1rem 2rem;
    display: flex;
    overflow: auto;
  }

  .page-book-wrapper .sidemenu::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 992px) {
  .page-book-wrapper .sidemenu {
    position: absolute;
    top: 7rem;
    left: 1rem;
  }
}

.page-book-wrapper .sidemenu a {
  color: #000;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
  display: block;
  position: relative;
}

@media (max-width: 1200px) {
  .page-book-wrapper .sidemenu a {
    font-size: calc(15px + .75vw);
  }
}

@media (min-width: 992px) {
  .page-book-wrapper .sidemenu a {
    border-bottom: 1px solid #9f9f9f;
    margin-bottom: .75rem;
    padding-bottom: 1rem;
    padding-right: 44px;
  }
}

@media (max-width: 991.98px) {
  .page-book-wrapper .sidemenu a {
    flex: none;
    padding-right: 1.5rem;
  }
}

@media (min-width: 992px) {
  .page-book-wrapper .sidemenu a:last-child {
    border-bottom: 0;
  }
}

.page-book-wrapper .sidemenu a.active {
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .page-book-wrapper .sidemenu a.active:before {
    content: "";
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 1.5rem;
  }
}

.page-book .tabs-wrapper .tab {
  display: none;
}

.page-book .tabs-wrapper .tab.active {
  display: block;
}

.page-book .title-container h2 {
  color: #333;
  letter-spacing: -.04rem;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.10417;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-book .title-container h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

@media (max-width: 1599.98px) {
  .page-book .content-offset {
    max-width: 1380px;
  }

  .page-book .content-offset h2 {
    font-size: 48px;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-book .content-offset h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

.page-book .image-fullwidth img {
  display: block;
}

.page-book .image-fullwidth .cta {
  width: 100%;
  max-width: 576px;
  text-align: right;
  margin: 0 auto;
  padding: 0 1rem;
}

.page-book .image-fullwidth .cta a {
  color: #fff;
  text-transform: uppercase;
  background-color: #123156;
  border-radius: 8px;
  padding: .875rem 1.5rem 1rem;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .page-book .image-fullwidth .cta a {
    font-size: calc(15px + .75vw);
  }
}

@media (max-width: 767.98px) {
  .page-book .image-fullwidth .cta a {
    color: #fff;
    padding: .625rem 1rem .75rem;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .page-book .image-fullwidth .cta a {
    font-size: calc(14.4px + .3vw);
  }
}

.page-book .image-fullwidth .cta a:hover {
  background-color: #123156b3;
}

.page-book .content-quote:before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%23123156' fill-opacity='.4'/%3E%3C/svg%3E");
}

.page-book .content-quote p {
  color: #123156;
}

.page-book .content-offset-wrapper {
  max-width: none;
}

@media (min-width: 1400px) {
  .page-book .content-text-image {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1600px) {
  .page-book .content-text-image .image {
    width: 55%;
    flex: 0 0 55%;
  }

  .page-book .content-text-image .text {
    width: 45%;
    flex: 0 0 45%;
  }
}

@media (max-width: 1599.98px) {
  .page-book .content-text-image .text h2 {
    font-size: 36px;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-book .content-text-image .text h2 {
    font-size: calc(16.2px + 1.65vw);
  }
}

.page-book .content-text-image.right .text-wrapper {
  margin-left: 0;
  padding-left: 0;
}

.page-book .content-text-image.right .text-wrapper p {
  margin-bottom: 3rem;
}

@media (min-width: 1400px) {
  .page-book .content-text-image.right .text-wrapper p {
    margin-bottom: 6rem;
  }
}

.about-project-book {
  position: relative;
}

.about-project-book .image {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.about-project-book .image:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#17a2b896 .9% 57.97%, #17a2b896 100%);
  position: absolute;
  inset: 0;
}

.about-project-book .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-project-book-wrapper {
  width: 100%;
  max-width: 1560px;
  z-index: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2.5rem;
  padding: 3rem 1rem 0;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .about-project-book-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper {
    margin: 0 auto;
    padding: 4rem;
  }
}

@media (min-width: 992px) {
  .about-project-book-wrapper {
    padding: 4rem 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper {
    margin: 0 auto;
    padding: 9rem 5rem 11rem;
  }
}

.about-project-book-wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper h2 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper h2 {
    color: #fff;
    margin-bottom: 7rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.about-project-book-wrapper h2 strong {
  font-weight: 700;
}

.about-project-book-wrapper .carousel-nav {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 3rem;
}

@media (min-width: 768px) {
  .about-project-book-wrapper .carousel-nav {
    margin: 0 auto 5rem;
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-nav {
    margin: 0 auto 11rem;
  }
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav {
    padding: 0 3rem;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider {
  position: relative;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -2.75rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-arrow.slick-next {
    right: 0;
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-track {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide {
  float: left;
  text-align: center;
  cursor: pointer;
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide.slick-current .element {
  color: #fff;
  font-size: 22px;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-slide.slick-current .element {
    font-size: calc(14.8px + .6vw);
  }
}

.about-project-book-wrapper .carousel-nav.slick-slider .slick-slide .element {
  color: #ffffffb3;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11111;
  text-decoration: none;
  transition: font-size .4s cubic-bezier(.47, .17, .17, .58), font-weight .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-nav.slick-slider .slick-slide .element {
    font-size: calc(14.4px + .3vw);
  }
}

.about-project-book-wrapper .carousel-text {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.about-project-book-wrapper .carousel-text.slick-slider {
  position: relative;
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow {
  z-index: 3;
  width: 2.75rem;
  height: 2.75rem;
  text-indent: -999em;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  left: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-prev {
    left: -4rem;
  }
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 16px;
  right: -5rem;
}

@media (max-width: 1199.98px) {
  .about-project-book-wrapper .carousel-text.slick-slider .slick-arrow.slick-next {
    right: -4rem;
  }
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-list {
  overflow: hidden;
}

.about-project-book-wrapper .carousel-text.slick-slider .slick-slide {
  float: left;
}

.about-project-book-wrapper .carousel-text .element h3 {
  color: #fff;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    font-size: calc(15.8px + 1.35vw);
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    color: #fff;
    margin-bottom: 3.25rem;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element h3 {
    font-size: calc(16.6px + 1.95vw);
  }
}

@media (min-width: 768px) {
  .about-project-book-wrapper .carousel-text .element .text {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

.about-project-book-wrapper .carousel-text .element p {
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.77778;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element p {
    font-size: calc(14.4px + .3vw);
  }
}

@media (min-width: 1600px) {
  .about-project-book-wrapper .carousel-text .element p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.72727;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .about-project-book-wrapper .carousel-text .element p {
    font-size: calc(14.8px + .6vw);
  }
}

.why-book {
  position: relative;
  overflow: hidden;
}

.why-book .image {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.why-book .image:before {
  content: "";
  z-index: 1;
  mix-blend-mode: multiply;
  background: linear-gradient(#3a3a3a00 0%, #3a3a3a6b 38.14%);
  position: absolute;
  inset: 0;
}

.why-book .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.why-book-wrapper {
  width: 100%;
  max-width: 1560px;
  z-index: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: flex;
  position: relative;
}

@media (max-width: 1599.98px) {
  .why-book-wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .why-book-wrapper {
    margin: 0 auto;
    padding: 4rem;
  }
}

@media (min-width: 992px) {
  .why-book-wrapper {
    padding: 4rem 5rem;
  }
}

@media (min-width: 1600px) {
  .why-book-wrapper {
    margin: 0 auto;
    padding: 9rem 5rem 11rem;
  }
}

.why-book-wrapper .title {
  margin-bottom: 2.75rem;
}

@media (min-width: 768px) {
  .why-book-wrapper .title {
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem;
    display: flex;
  }
}

@media (min-width: 1600px) {
  .why-book-wrapper .title {
    margin-bottom: 7rem;
  }
}

.why-book-wrapper .title h2 {
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .why-book-wrapper .title h2 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (min-width: 1600px) {
  .why-book-wrapper .title h2 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
  }
}

@media (min-width: 1600px) and (max-width: 1200px) {
  .why-book-wrapper .title h2 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.why-book-wrapper .title h2 strong {
  font-weight: 700;
}

.why-book-wrapper .title a {
  color: #fff;
  text-transform: uppercase;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

@media (max-width: 1200px) {
  .why-book-wrapper .title a {
    font-size: calc(15px + .75vw);
  }
}

.why-book-wrapper .title a:hover {
  color: #000;
  background-color: #fff9;
}

.why-book-wrapper .content-wrapper {
  align-items: stretch;
  margin: 0 -42px;
  display: flex;
}

@media (max-width: 991.98px) {
  .why-book-wrapper .content-wrapper {
    margin: 0 -20px;
  }
}

@media (max-width: 767.98px) {
  .why-book-wrapper .content-wrapper {
    margin: 0;
  }
}

.why-book-wrapper .content-wrapper.slick-slider {
  position: relative;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-dots {
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-dots li {
  cursor: pointer;
  margin: 0 .375rem;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-dots li button {
  -webkit-appearance: none;
  appearance: none;
  width: .75rem;
  height: .75rem;
  text-indent: -9999em;
  background-color: #ffffff80;
  border: 0;
  border-radius: 50%;
  padding: 0;
  display: block;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-dots li.slick-active button {
  background-color: #fff;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-list, .why-book-wrapper .content-wrapper.slick-slider .slick-track {
  overflow: hidden;
}

.why-book-wrapper .content-wrapper.slick-slider .slick-track .slick-slide {
  float: left;
}

.why-book-wrapper .content-wrapper .text {
  width: 25%;
  flex-flow: column wrap;
  flex: 0 0 25%;
  justify-content: space-between;
  padding: 0 42px;
  display: flex;
}

@media (max-width: 991.98px) {
  .why-book-wrapper .content-wrapper .text {
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .why-book-wrapper .content-wrapper .text {
    padding: 0;
  }
}

.why-book-wrapper .content-wrapper .text h3 {
  color: #fff;
  margin-bottom: 28px;
  padding-left: 28px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;
  text-decoration: none;
  position: relative;
}

@media (max-width: 1200px) {
  .why-book-wrapper .content-wrapper .text h3 {
    font-size: calc(15px + .75vw);
  }
}

.why-book-wrapper .content-wrapper .text h3 span:first-child {
  position: absolute;
  top: 0;
  left: 0;
}

.why-book-wrapper .content-wrapper .text p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .why-book-wrapper .content-wrapper .text p {
    font-size: calc(14.2px + .15vw);
  }
}

.cover-shop {
  position: relative;
}

.cover-shop:before {
  content: "";
  z-index: 1;
  opacity: .7;
  background: #51b9ca;
  position: absolute;
  inset: 0;
}

.cover-shop .image {
  height: 295px;
  position: relative;
  overflow: hidden;
}

.cover-shop .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-shop .content {
  z-index: 2;
  width: 100%;
  max-width: 1560px;
  padding: 5rem 1rem 1rem;
  position: absolute;
  bottom: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1599.98px) {
  .cover-shop .content {
    max-width: 1160px;
  }
}

@media (max-width: 767.98px) {
  .cover-shop .content {
    bottom: 1.5rem;
  }
}

.cover-shop .content-wrapper {
  width: 100%;
  max-width: 480px;
}

.cover-shop .content-wrapper h1 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop .content-wrapper h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .cover-shop .content-wrapper h1 {
    color: #fff;
    margin-bottom: 1rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-shop .content-wrapper h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.page-shop {
  padding: 2rem 0;
}

@media (min-width: 768px) {
  .page-shop {
    padding: 4rem 0;
  }
}

.page-shop--wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1599.98px) {
  .page-shop--wrapper {
    max-width: 1160px;
  }
}

.page-shop .topbar {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .page-shop .topbar--wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .page-shop .topbar--wrapper .sort {
    margin-bottom: 1.5rem;
  }
}

.page-shop .topbar--wrapper .sort select {
  -webkit-appearance: none;
  appearance: none;
  color: #15151599;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 7'%3E%3Cpath fill='%236B6B6B' d='M5.5 7L.737.25h9.526L5.5 7z'/%3E%3C/svg%3E");
  background-position: right 2rem center;
  background-repeat: no-repeat;
  background-size: 11px 9px;
  border: 1px solid #f2f2f2;
  padding: .25rem 4rem .25rem 1rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28571;
  text-decoration: none;
}

.page-shop .topbar--wrapper .pagination .pager {
  align-items: center;
  display: flex;
}

@media (max-width: 767.98px) {
  .page-shop .topbar--wrapper .pagination .pager {
    justify-content: flex-end;
  }
}

.page-shop .topbar--wrapper .pagination .pager li {
  width: 30px;
  height: 30px;
  background-color: #eaeaea;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  display: flex;
}

.page-shop .topbar--wrapper .pagination .pager li.active {
  background-color: #51b9ca;
}

.page-shop .topbar--wrapper .pagination .pager li.active a {
  color: #fff;
}

.page-shop .topbar--wrapper .pagination .pager li.prev, .page-shop .topbar--wrapper .pagination .pager li.next {
  background-color: #0000;
}

.page-shop .topbar--wrapper .pagination .pager li.next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M9 5.5L.75 10.263V.737L9 5.5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
}

.page-shop .topbar--wrapper .pagination .pager li.prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M0 5.5L8.25.737v9.526L0 5.5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
}

.page-shop .topbar--wrapper .pagination .pager a {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.page-shop .bottombar {
  margin-top: 35px;
}

.page-shop .bottombar--wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.page-shop .bottombar--wrapper .pagination {
  margin-left: auto;
}

.page-shop .bottombar--wrapper .pagination .pager {
  align-items: center;
  display: flex;
}

.page-shop .bottombar--wrapper .pagination .pager li {
  width: 30px;
  height: 30px;
  background-color: #eaeaea;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  display: flex;
}

.page-shop .bottombar--wrapper .pagination .pager li.active {
  background-color: #51b9ca;
}

.page-shop .bottombar--wrapper .pagination .pager li.active a {
  color: #fff;
}

.page-shop .bottombar--wrapper .pagination .pager li.prev, .page-shop .bottombar--wrapper .pagination .pager li.next {
  background-color: #0000;
}

.page-shop .bottombar--wrapper .pagination .pager li.next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M9 5.5L.75 10.263V.737L9 5.5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
}

.page-shop .bottombar--wrapper .pagination .pager li.prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 11'%3E%3Cpath fill='%236B6B6B' d='M0 5.5L8.25.737v9.526L0 5.5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
}

.page-shop .bottombar--wrapper .pagination .pager a {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.page-shop--blocks {
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -.75rem;
  display: flex;
}

@media (min-width: 992px) {
  .page-shop--blocks {
    margin: 0 -1.75rem;
  }
}

.page-shop--blocks .block {
  margin-bottom: 50px;
  padding: 0 .75rem;
}

@media (min-width: 992px) {
  .page-shop--blocks .block {
    padding: 0 1.75rem;
  }
}

.page-shop--blocks .block.book {
  width: 25%;
  flex: 0 0 25%;
}

@media (max-width: 767.98px) {
  .page-shop--blocks .block.book {
    width: 50%;
    flex: 0 0 50%;
  }
}

.page-shop--blocks .block.workshop {
  width: 50%;
  flex: 0 0 50%;
}

@media (max-width: 767.98px) {
  .page-shop--blocks .block.workshop {
    width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .page-shop--blocks .block.workshop .content {
    width: 45%;
  }
}

.page-shop--blocks .block--wrapper {
  height: 100%;
  position: relative;
}

.page-shop--blocks .block--wrapper .image {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .page-shop--blocks .block--wrapper .image {
    height: 245px;
  }
}

@media (min-width: 992px) {
  .page-shop--blocks .block--wrapper .image {
    height: 320px;
  }
}

@media (min-width: 1600px) {
  .page-shop--blocks .block--wrapper .image {
    height: 480px;
  }
}

.page-shop--blocks .block--wrapper .image img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .page-shop--blocks .block--wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.page-shop--blocks .block--wrapper .image .badge {
  z-index: 2;
  border-radius: 0 11.5px 11.5px 0;
  flex-direction: column;
  justify-content: center;
  padding: .25rem;
  display: flex;
  position: absolute;
  top: 11px;
  left: 0;
}

.page-shop--blocks .block--wrapper .image .badge.new {
  background-color: #b93131;
}

.page-shop--blocks .block--wrapper .image .badge span {
  color: #fff;
  padding: .125rem .75rem;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

.page-shop--blocks .block--wrapper .content h2 {
  color: #15151599;
  margin-bottom: .75rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28571;
  text-decoration: none;
}

.page-shop--blocks .block--wrapper .content .price {
  color: #7d7d7d99;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28571;
  text-decoration: none;
  display: block;
}

.page-shop--blocks .block--wrapper .content .link {
  z-index: 2;
  position: relative;
}

.page-shop--blocks .block--wrapper .content .link a {
  color: #151515;
  text-transform: uppercase;
  background-color: #e0e0e0;
  padding: .75rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

.page-shop--blocks .block--wrapper .content .link a:hover {
  color: #fff;
  background-color: #0095c6;
}

.cover-shop--single {
  overflow: hidden;
}

.cover-shop--single--wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 6rem auto 0;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper {
    margin: 8.5rem auto 0;
  }
}

@media (max-width: 1599.98px) {
  .cover-shop--single--wrapper {
    max-width: 1160px;
  }
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery {
  width: 100%;
  flex: 0 0 100%;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery {
    width: 35%;
    flex: 0 0 35%;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery {
    width: 540px;
    flex: 0 0 540px;
    padding-bottom: 3rem;
  }
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery .main {
  margin-bottom: .75rem;
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery .main img {
  width: 100%;
  height: auto;
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery .nav {
  align-items: stretch;
  margin: 0 -.375rem;
  display: flex;
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery .nav .image {
  width: 33.3333%;
  height: 90px;
  flex: 0 0 33.3333%;
  padding: 0 .375rem;
  overflow: hidden;
}

.cover-shop--single--wrapper .content--wrapper.cover--workshop .gallery .nav .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper.cover--workshop .content {
    flex: 0 0 65%;
    padding-left: 3rem;
  }
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper.cover--workshop .content {
    flex: auto;
    padding-left: 4rem;
  }
}

.cover-shop--single--wrapper .breadcrumbs {
  margin-bottom: 1.5rem;
}

.cover-shop--single--wrapper .breadcrumbs .breadcrumb-item:before {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23C4C4C4'/%3E%3C/svg%3E");
}

@media (max-width: 991.98px) {
  .cover-shop--single--wrapper .breadcrumbs .breadcrumb-item:before {
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5l1 1-6 6-1-1 5-5-5-5 1-1 5 5z' fill='%23C4C4C4'/%3E%3C/svg%3E");
  }
}

.cover-shop--single--wrapper .breadcrumbs .breadcrumb-item a {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .breadcrumbs .breadcrumb-item a {
    font-size: calc(14.2px + .15vw);
  }
}

.cover-shop--single--wrapper .content--wrapper {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.cover-shop--single--wrapper .content--wrapper .gallery {
  width: 100%;
  flex: 0 0 100%;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper .gallery {
    width: 30%;
    flex: 0 0 30%;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper .gallery {
    width: 300px;
    flex: 0 0 300px;
    padding-bottom: 3rem;
  }
}

.cover-shop--single--wrapper .content--wrapper .gallery .top h1 {
  color: #151515;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .gallery .top h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .cover-shop--single--wrapper .content--wrapper .gallery .top h1 {
    color: #151515;
    margin-bottom: 1rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .gallery .top h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.cover-shop--single--wrapper .content--wrapper .gallery .top .author {
  color: #15151599;
  margin-bottom: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66667;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .gallery .top .author {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-shop--single--wrapper .content--wrapper .gallery .main {
  margin-bottom: .75rem;
}

.cover-shop--single--wrapper .content--wrapper .gallery .main img {
  width: 100%;
  height: auto;
}

.cover-shop--single--wrapper .content--wrapper .gallery .nav {
  align-items: stretch;
  margin: 0 -.375rem;
  display: flex;
}

.cover-shop--single--wrapper .content--wrapper .gallery .nav .image {
  width: 33.3333%;
  height: 90px;
  flex: 0 0 33.3333%;
  padding: 0 .375rem;
  overflow: hidden;
}

.cover-shop--single--wrapper .content--wrapper .gallery .nav .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper .content {
    flex: 0 0 70%;
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper .content {
    flex: auto;
    padding-left: 110px;
  }
}

.cover-shop--single--wrapper .content--wrapper .content .top h1 {
  color: #151515;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .content .top h1 {
    font-size: calc(16.8px + 2.1vw);
  }
}

@media (max-width: 767.98px) {
  .cover-shop--single--wrapper .content--wrapper .content .top h1 {
    color: #151515;
    margin-bottom: 1rem;
    font-family: Montserrat, sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
  }
}

@media (max-width: 767.98px) and (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .content .top h1 {
    font-size: calc(20.6px + 4.95vw);
  }
}

.cover-shop--single--wrapper .content--wrapper .content .top .author {
  color: #15151599;
  margin-bottom: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66667;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .content .top .author {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-shop--single--wrapper .content--wrapper .content .bottom {
  padding: 2rem 0;
  position: relative;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom {
    padding: 2.5rem 0 3rem;
  }
}

.cover-shop--single--wrapper .content--wrapper .content .bottom:before {
  content: "";
  z-index: -1;
  background-color: #51b9ca;
  position: absolute;
  inset: 0 -9999rem;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .text {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom .text {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom .text {
    max-width: 504px;
    margin-bottom: 10rem;
  }
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .text p {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66667;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom .text p {
    font-size: calc(14.4px + .3vw);
  }
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options {
  max-width: 550px;
  width: 100%;
  border-top: 1px solid #fff;
  align-items: center;
  padding-top: 3rem;
  display: flex;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form {
  align-items: center;
  margin-right: 1.5rem;
  display: flex;
}

@media (min-width: 992px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom .options form {
    margin-right: 2.5rem;
  }
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form .form-item {
  margin-right: 2rem;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form input[type="number"] {
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  -moz-appearance: textfield;
  background-color: #0000;
  border: 1px solid #fff;
  padding: .5rem;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 2.28571;
  text-decoration: none;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form input[type="number"]::-webkit-outer-spin-button, .cover-shop--single--wrapper .content--wrapper .content .bottom .options form input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form input[type="number"]::placeholder {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 2.28571;
  text-decoration: none;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form button {
  -webkit-appearance: none;
  appearance: none;
  color: #0095c6;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid #fff;
  padding: .75rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.16667;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options form button:hover {
  color: #fff;
  background-color: #0000;
}

.cover-shop--single--wrapper .content--wrapper .content .bottom .options .price {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .cover-shop--single--wrapper .content--wrapper .content .bottom .options .price {
    font-size: calc(15.8px + 1.35vw);
  }
}

.page-shop--single--wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

@media (max-width: 1599.98px) {
  .page-shop--single--wrapper {
    max-width: 1160px;
  }
}

@media (min-width: 768px) {
  .page-shop--single--wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .page-shop--single--wrapper {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

@media (max-width: 991.98px) {
  .page-shop--single--wrapper .sidemenu {
    width: 100%;
    max-width: 576px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 auto;
    padding: 0 1rem 2rem;
    display: flex;
    overflow: auto;
  }

  .page-shop--single--wrapper .sidemenu::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 992px) {
  .page-shop--single--wrapper .sidemenu {
    position: absolute;
    top: 7rem;
    left: 1rem;
  }
}

.page-shop--single--wrapper .sidemenu a {
  color: #000;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  text-decoration: none;
  display: block;
  position: relative;
}

@media (max-width: 1200px) {
  .page-shop--single--wrapper .sidemenu a {
    font-size: calc(15px + .75vw);
  }
}

@media (min-width: 992px) {
  .page-shop--single--wrapper .sidemenu a {
    border-bottom: 1px solid #9f9f9f;
    margin-bottom: .75rem;
    padding-bottom: 1rem;
    padding-right: 44px;
  }
}

@media (max-width: 991.98px) {
  .page-shop--single--wrapper .sidemenu a {
    flex: none;
    padding-right: 1.5rem;
  }
}

@media (min-width: 992px) {
  .page-shop--single--wrapper .sidemenu a:last-child {
    border-bottom: 0;
  }
}

.page-shop--single--wrapper .sidemenu a.active {
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .page-shop--single--wrapper .sidemenu a.active:before {
    content: "";
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 1.5rem;
  }
}

.page-shop--single .title-container, .page-shop--single .text-container {
  max-width: 736px;
}

@media (min-width: 992px) and (max-width: 1599.98px) {
  .page-shop--single .title-container, .page-shop--single .text-container {
    padding-left: 208px;
  }
}

.page-shop--single .text-container p {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66667;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-shop--single .text-container p {
    font-size: calc(14.4px + .3vw);
  }
}

.page-shop--single .tabs-wrapper .tab {
  display: none;
}

.page-shop--single .tabs-wrapper .tab.active {
  display: block;
}

.page-shop--single .title-container {
  margin-bottom: 2rem;
}

.page-shop--single .title-container h2 {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: .857143;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .page-shop--single .title-container h2 {
    font-size: calc(14.7px + .525vw);
  }
}

@media (max-width: 1599.98px) {
  .page-shop--single .content-offset {
    max-width: 1380px;
  }

  .page-shop--single .content-offset h2 {
    font-size: 48px;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-shop--single .content-offset h2 {
    font-size: calc(17.4px + 2.55vw);
  }
}

.page-shop--single .content-quote:before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%2351B9CA' fill-opacity='.3'/%3E%3C/svg%3E");
}

.page-shop--single .content-quote p {
  color: #51b9ca;
}

.page-shop--single .content-offset-wrapper {
  max-width: none;
}

@media (min-width: 1400px) {
  .page-shop--single .content-text-image {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1600px) {
  .page-shop--single .content-text-image .image {
    width: 55%;
    flex: 0 0 55%;
  }

  .page-shop--single .content-text-image .text {
    width: 45%;
    flex: 0 0 45%;
  }
}

@media (max-width: 1599.98px) {
  .page-shop--single .content-text-image .text h2 {
    font-size: 36px;
  }
}

@media (max-width: 1599.98px) and (max-width: 1200px) {
  .page-shop--single .content-text-image .text h2 {
    font-size: calc(16.2px + 1.65vw);
  }
}

.page-shop--single .content-text-image.right .text-wrapper {
  margin-left: 0;
  padding-left: 0;
}

.page-shop--single .content-text-image.right .text-wrapper p {
  margin-bottom: 3rem;
}

@media (min-width: 1400px) {
  .page-shop--single .content-text-image.right .text-wrapper p {
    margin-bottom: 6rem;
  }
}

.section-related--wrapper {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 1rem;
  overflow: hidden;
}

@media (max-width: 1599.98px) {
  .section-related--wrapper {
    max-width: 1160px;
  }
}

.section-related--wrapper .title {
  margin-bottom: 2.625rem;
}

.section-related--wrapper .title h2 {
  color: #15151599;
  font-family: Lato, sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: .857143;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .section-related--wrapper .title h2 {
    font-size: calc(14.7px + .525vw);
  }
}

.section-related--wrapper .blocks--wrapper {
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -.75rem;
  display: flex;
}

@media (min-width: 992px) {
  .section-related--wrapper .blocks--wrapper {
    margin: 0 -1.75rem;
  }
}

.section-related--wrapper .blocks--wrapper .block {
  margin-bottom: 50px;
  padding: 0 .75rem;
}

@media (min-width: 992px) {
  .section-related--wrapper .blocks--wrapper .block {
    padding: 0 1.75rem;
  }
}

.section-related--wrapper .blocks--wrapper .block.book {
  width: 25%;
  flex: 0 0 25%;
}

@media (max-width: 767.98px) {
  .section-related--wrapper .blocks--wrapper .block.book {
    width: 50%;
    flex: 0 0 50%;
  }
}

.section-related--wrapper .blocks--wrapper .block.workshop {
  width: 50%;
  flex: 0 0 50%;
}

@media (max-width: 767.98px) {
  .section-related--wrapper .blocks--wrapper .block.workshop {
    width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .section-related--wrapper .blocks--wrapper .block.workshop .content {
    width: 45%;
  }
}

.section-related--wrapper .blocks--wrapper .block--wrapper {
  height: 100%;
  position: relative;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .image {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .section-related--wrapper .blocks--wrapper .block--wrapper .image {
    height: 245px;
  }
}

@media (min-width: 992px) {
  .section-related--wrapper .blocks--wrapper .block--wrapper .image {
    height: 320px;
  }
}

@media (min-width: 1600px) {
  .section-related--wrapper .blocks--wrapper .block--wrapper .image {
    height: 480px;
  }
}

.section-related--wrapper .blocks--wrapper .block--wrapper .image img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .section-related--wrapper .blocks--wrapper .block--wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.section-related--wrapper .blocks--wrapper .block--wrapper .image .badge {
  z-index: 2;
  border-radius: 0 11.5px 11.5px 0;
  flex-direction: column;
  justify-content: center;
  padding: .25rem;
  display: flex;
  position: absolute;
  top: 11px;
  left: 0;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .image .badge.new {
  background-color: #b93131;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .image .badge span {
  color: #fff;
  padding: .125rem .75rem;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .content h2 {
  color: #15151599;
  margin-bottom: .75rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28571;
  text-decoration: none;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .content .price {
  color: #7d7d7d99;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28571;
  text-decoration: none;
  display: block;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .content .link {
  z-index: 2;
  position: relative;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .content .link a {
  color: #151515;
  text-transform: uppercase;
  background-color: #e0e0e0;
  padding: .75rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: background-color .4s cubic-bezier(.47, .17, .17, .58), color .4s cubic-bezier(.47, .17, .17, .58);
  display: inline-block;
}

.section-related--wrapper .blocks--wrapper .block--wrapper .content .link a:hover {
  color: #fff;
  background-color: #0095c6;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  opacity: .25;
  cursor: pointer;
  background: #333;
  border-radius: 50%;
  margin: 0 8px;
  display: inline-block;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}

body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}

body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: .1s;
}

body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}

body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: .1s;
}

body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: .15s;
}

body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}

body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: .15s;
}

body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: .2s;
}

body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}

body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: .2s;
}

body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: .25s;
}

body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}

body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: .25s;
}

body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: .3s;
}

body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}

body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: .3s;
}

body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: .35s;
}

body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}

body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: .35s;
}

body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: .4s;
}

body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}

body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: .4s;
}

body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: .45s;
}

body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}

body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: .45s;
}

body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: .5s;
}

body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}

body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: .5s;
}

body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: .55s;
}

body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}

body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: .55s;
}

body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: .6s;
}

body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}

body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: .6s;
}

body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: .65s;
}

body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}

body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: .65s;
}

body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: .7s;
}

body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}

body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: .7s;
}

body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: .75s;
}

body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}

body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: .75s;
}

body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: .8s;
}

body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}

body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: .8s;
}

body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: .85s;
}

body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}

body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: .85s;
}

body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: .9s;
}

body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}

body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: .9s;
}

body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: .95s;
}

body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}

body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: .95s;
}

body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1s;
}

body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}

body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1s;
}

body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1.05s;
}

body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}

body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1.05s;
}

body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1.1s;
}

body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}

body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1.1s;
}

body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1.15s;
}

body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}

body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1.15s;
}

body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1.2s;
}

body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}

body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1.2s;
}

body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1.25s;
}

body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}

body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1.25s;
}

body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1.3s;
}

body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}

body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1.3s;
}

body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1.35s;
}

body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}

body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1.35s;
}

body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1.4s;
}

body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}

body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1.4s;
}

body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1.45s;
}

body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}

body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1.45s;
}

body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1.5s;
}

body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}

body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1.5s;
}

body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1.55s;
}

body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}

body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1.55s;
}

body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1.6s;
}

body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}

body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1.6s;
}

body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1.65s;
}

body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}

body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1.65s;
}

body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1.7s;
}

body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}

body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1.7s;
}

body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1.75s;
}

body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}

body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1.75s;
}

body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1.8s;
}

body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}

body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1.8s;
}

body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1.85s;
}

body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}

body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1.85s;
}

body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1.9s;
}

body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}

body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1.9s;
}

body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1.95s;
}

body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}

body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1.95s;
}

body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2s;
}

body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}

body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2s;
}

body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2.05s;
}

body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}

body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2.05s;
}

body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2.1s;
}

body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}

body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2.1s;
}

body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2.15s;
}

body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}

body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2.15s;
}

body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2.2s;
}

body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}

body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2.2s;
}

body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2.25s;
}

body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}

body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2.25s;
}

body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2.3s;
}

body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}

body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2.3s;
}

body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2.35s;
}

body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}

body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2.35s;
}

body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2.4s;
}

body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}

body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2.4s;
}

body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2.45s;
}

body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}

body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2.45s;
}

body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2.5s;
}

body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}

body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2.5s;
}

body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2.55s;
}

body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}

body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2.55s;
}

body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2.6s;
}

body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}

body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2.6s;
}

body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2.65s;
}

body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}

body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2.65s;
}

body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2.7s;
}

body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}

body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2.7s;
}

body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2.75s;
}

body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}

body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2.75s;
}

body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2.8s;
}

body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}

body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2.8s;
}

body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2.85s;
}

body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}

body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2.85s;
}

body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2.9s;
}

body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}

body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2.9s;
}

body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2.95s;
}

body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}

body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2.95s;
}

body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3s;
}

body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}

body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3s;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: ease;
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: ease-in;
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: ease-out;
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

/*# sourceMappingURL=app.css.map */
