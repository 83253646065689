.cover-book {
  position: relative;
  background: linear-gradient(180deg, rgba(78, 168, 182, 0.75) 0.9%, rgba(78, 166, 180, 0.75) 64.56%, rgba(56, 157, 173, 0.75) 75.02%, rgba(129, 197, 207, 0.75) 100%);

  .background {
    position: absolute;
    left: 0;
    top: 0;

    @include media-breakpoint-up(md) {
      left: 85px;
      top: 120px;
      bottom: 120px;
    }

    img {
      width: 100%;
      height: auto;

      @include media-breakpoint-up(md) {
        width: auto;
        height: 100%;
      }
    }
  }

  .image-mobile {
    position: relative;
    margin-bottom: .25rem;
    overflow: hidden;
    height: 100vh;
    max-height: 1080px;
    min-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    img {
      width: 100%;
      max-width: 480px;
      height: auto;
    }
  }

  .image {
    position: relative;
    margin-bottom: .25rem;
    overflow: hidden;
    height: 100vh;
    max-height: 1080px;
    min-height: 640px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;

      height: 100%;
      max-height: 800px;
      width: auto;
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1560px;
    padding: 0 1rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-down(sm) {
      top: 40%;
    }

    &-wrapper {
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      h1 {
        @include font-style--alter($font-secondary, 80, 80, $weight--bold, $white, none);
        text-transform: uppercase;
        margin-bottom: 0;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 42, 42, $weight--bold, $white, none);
        }
        @include media-breakpoint-down(sm) {
          @include font-style--alter($font-secondary, 80, 80, $weight--bold, $white, none);
          margin-bottom: 1rem;
        }
      }

      h2 {
        @include font-style--alter($font-secondary, 32, 32, $weight--regular, $white, none);
        text-transform: uppercase;
        margin-bottom: 0;
        max-width: 572px;

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 22, 22, $weight--regular, $white, none);
          max-width: 360px;
        }
        @include media-breakpoint-down(sm) {
          @include font-style--alter($font-secondary, 22, 22, $weight--regular, $white, none);
        }
      }

      p {
        max-width: 572px;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          margin-top: 52px;
        }

        @include font-style--alter($font-secondary, 24, 30, $weight--regular, $white, none);

        @include media-breakpoint-down(xxl) {
          @include font-style--alter($font-secondary, 18, 24, $weight--regular, $white, none);
          max-width: 376px;
        }
      }

      .cta {
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
          margin-top: 5rem;
        }

        .btn {
          display: inline-block;

          &-white {
            padding: .875rem 1.5rem 1rem 1.5rem;
            border-radius: 8px;
            background-color: $white;
            @include font-style--alter($font-primary, 24, 24, $weight--regular, $color__blue-dark, none);
            text-transform: uppercase;

            transition: background-color $general-transition-time $general-transition-ease;

            @include media-breakpoint-down(sm) {
              padding: .625rem 1rem .75rem 1rem;
              @include font-style--alter($font-primary, 18, 18, $weight--regular, $color__blue-dark, none);
            }

            &:hover {
              background-color: rgba($white, 0.6);
            }
          }

          &-plain {
            padding: .75rem 1.5rem 1rem 1.5rem;
            @include font-style--alter($font-primary, 24, 24, $weight--regular, $white, none);
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
              padding: .625rem 1rem .75rem 1rem;
              @include font-style--alter($font-primary, 18, 18, $weight--regular, $white, none);
            }
          }
        }
      }
    }
  }

  .scroll-down {
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%) !important;

    display: inline-block;
    text-align: center;

    .icon {
      width: 31px;
      height: 34px;
      margin: 0 auto .5rem auto;
    }

    span {
      display: block;
      @include font-style--alter($font-primary, 14, 38, $weight--medium, $white, none);
      text-transform: uppercase;
    }

    svg {
      path {
        animation-name: arrowDown;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes arrowDown {
        0% {
          transform: translateY(0);
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        75% {
          opacity: .65;
        }
        100% {
          opacity: 0;
          transform: translateY(6px)
        }
      }
    }
  }
}

.page-book {
  &-wrapper {
    width: 100%;
    max-width: 1492px;
    //padding: 0 1rem;
    margin: 0 auto;
    position: relative;

    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }

    .sidemenu {
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 576px;
        margin: 0 auto;
        padding: 0 1rem 2rem 1rem;
        display: flex;
        overflow: auto;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 7rem;
        left: 1rem;
      }

      a {
        display: block;
        position: relative;
        @include font-style--alter($font-primary, 24, 24, $weight--light, $black, none);
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin-bottom: .75rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid $color__grey-9;
          padding-right: 44px;
        }

        @include media-breakpoint-down(md) {
          padding-right: 1.5rem;
          flex: 0 0 auto;
        }

        &:last-child {
          @include media-breakpoint-up(lg) {
            border-bottom: 0;
          }
        }

        &.active {
          font-weight: $weight--bold;

          @include media-breakpoint-down(md) {
            //order: -1;
            &::before {
              content: "";
              position: absolute;
              bottom: -.25rem;
              left: 0;
              right: 1.5rem;
              height: 1px;
              background-color: $black;
            }
          }
        }
      }
    }
  }

  .tabs-wrapper {
    .tab {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .title-container {
    h2 {
      @include font-style--alter($font-secondary, 48, 53, $weight--bold, $color__grey-dark, none);
      letter-spacing: -0.04rem;
    }
  }

  .content-offset {
    @include media-breakpoint-down(xxl) {
      max-width: 1380px;
    }

    h2 {
      @include media-breakpoint-down(xxl) {
        @include font-size(3rem);
      }
    }
  }

  .image-fullwidth {
    img {
      display: block;
    }

    .cta {
      width: 100%;
      max-width: 576px;
      padding: 0 1rem;
      margin: 0 auto;
      text-align: right;

      a {
        padding: .875rem 1.5rem 1rem 1.5rem;
        border-radius: 8px;
        background-color: $color__blue-dark;
        @include font-style--alter($font-primary, 24, 24, $weight--regular, $white, none);
        text-transform: uppercase;

        transition: background-color $general-transition-time $general-transition-ease;

        @include media-breakpoint-down(sm) {
          padding: .625rem 1rem .75rem 1rem;
          @include font-style--alter($font-primary, 18, 18, $weight--regular, $white, none);
        }

        &:hover {
          background-color: rgba($color__blue-dark, 0.7);
        }
      }
    }
  }

  .content-quote {
    &::before {
      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 85'%3E%3Cpath d='M31.934 41.649c3.969 1.623 7.035 4.238 9.2 7.846 2.344 3.427 3.516 7.575 3.516 12.445 0 6.493-2.074 11.814-6.222 15.962-4.149 4.148-9.29 6.222-15.421 6.222-6.494 0-11.814-2.074-15.963-6.222C2.896 73.754.822 68.432.822 61.94c0-3.247.36-6.403 1.082-9.47.902-3.066 2.525-7.665 4.87-13.797L21.113.255h21.643L31.934 41.65zm57.356 0c3.968 1.623 7.034 4.238 9.199 7.846 2.344 3.427 3.517 7.575 3.517 12.445 0 6.493-2.074 11.814-6.223 15.962-4.148 4.148-9.288 6.222-15.42 6.222-6.494 0-11.815-2.074-15.963-6.222-4.148-4.148-6.223-9.47-6.223-15.962 0-3.247.361-6.403 1.083-9.47.901-3.066 2.525-7.665 4.87-13.797L78.467.255h21.644L89.29 41.65z' fill='%23123156' fill-opacity='.4'/%3E%3C/svg%3E");
    }

    p {
      color: $color__blue-dark;
    }
  }

  .content-offset {
    //padding: 0;

    &-wrapper {
      max-width: none;
    }
  }

  .content-text-image {
    @include media-breakpoint-up(xxl) {
      margin-bottom: 5rem;
    }

    &-wrapper {
      //padding: 0;
    }

    .image {
      @include media-breakpoint-up(xxxl) {
        width: 55%;
        flex: 0 0 55%;
      }
    }
    .text {
      @include media-breakpoint-up(xxxl) {
        width: 45%;
        flex: 0 0 45%;
      }

      h2 {
        @include media-breakpoint-down(xxl) {
          @include font-size(2.25rem);
        }
      }
    }

    &.right {
      .text {
        &-wrapper {
          padding-left: 0;
          margin-left: 0;

          p {
            margin-bottom: 3rem;

            @include media-breakpoint-up(xxl) {
              margin-bottom: 6rem;
            }
          }
        }
      }
    }
  }
}

.about-project-book {
  position: relative;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(23, 162, 184, 0.59) 0.9%, rgba(23, 162, 184, 0.59) 57.97%, rgba(23, 162, 184, 0.59) 100%);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    position: relative;
    z-index: 1;
    margin: 0 auto 2.5rem auto;
    padding: 3rem 1rem 0 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 4rem 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 4rem 5rem;
    }
    @include media-breakpoint-up(xxxl) {
      margin: 0 auto;
      padding: 9rem 5rem 11rem 5rem;
    }

    h2 {
      @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
      text-transform: uppercase;
      margin-bottom: 2.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(xxxl) {
        margin-bottom: 7rem;
        @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
      }

      strong {
        font-weight: $weight--bold;
      }
    }

    .carousel-main {

    }

    .carousel-nav {
      width: 100%;
      max-width: 860px;
      margin: 0 auto 3rem auto;

      @include media-breakpoint-up(md) {
        margin: 0 auto 5rem auto;
      }
      @include media-breakpoint-up(xxxl) {
        margin: 0 auto 11rem auto;
      }

      @include media-breakpoint-down(lg) {
        padding: 0 3rem;
      }

      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $white;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: 0;
            }
          }
          &.slick-next {
            right: -2.75rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: 0;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;

          display: flex;
          align-items: center;
          margin: 0 auto;
        }

        .slick-slide {
          float: left;
          text-align: center;
          cursor: pointer;

          &.slick-current {
            .element {
              color: $white;
              font-weight: $weight--xBold;
              @include font-size(1.375rem);
            }
          }

          .element {
            @include font-style--alter($font-secondary, 18, 20, $weight--medium, rgba($white, .7), none);
            text-transform: uppercase;

            transition: font-size $general-transition-time $general-transition-ease,
            font-weight $general-transition-time $general-transition-ease,
            color $general-transition-time $general-transition-ease;
          }
        }
      }
    }

    .carousel-text {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;

      &.slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          z-index: 3;
          top: 50%;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;

          text-indent: -999em;
          background-color: $white;

          @extend .btn;
          @extend .btn-plain;

          &.slick-prev {
            left: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M7.2 1.95L1.7 8l5.5 6.05' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              left: -4rem;
            }
          }
          &.slick-next {
            right: -5rem;
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 16'%3E%3Cpath d='M1.8 14.05L7.3 8 1.8 1.95' stroke='%23277ED5' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 9px 16px;

            @include media-breakpoint-down(lg) {
              right: -4rem;
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          @extend %clearfix;
        }

        .slick-slide {
          float: left;
        }
      }

      .element {
        h3 {
          @include font-style--alter($font-secondary, 32, 32, $weight--bold, $white, none);
          margin-bottom: 2rem;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-secondary, 40, 40, $weight--bold, $white, none);
            margin-bottom: 3.25rem;
          }
        }

        .text {

          @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }


        }

        p {
          @include font-style--alter($font-primary, 18, 32, $weight--regular, $white, none);
          margin-bottom: 1.5rem;

          @include media-breakpoint-up(xxxl) {
            @include font-style--alter($font-primary, 22, 38, $weight--regular, $white, none);
          }
        }
      }
    }
  }
}

.why-book {
  position: relative;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(58, 58, 58, 0) 0%, rgba(58, 58, 58, 0.42) 38.14%);
      mix-blend-mode: multiply;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 3rem 1rem 3rem 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 4rem 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 4rem 5rem;
    }
    @include media-breakpoint-up(xxxl) {
      margin: 0 auto;
      padding: 9rem 5rem 11rem 5rem;
    }

    .title {
      margin-bottom: 2.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
      @include media-breakpoint-up(xxxl) {
        margin-bottom: 7rem;
      }

      h2 {
        @include font-style--alter($font-secondary, 42, 42, $weight--light, $white, none);
        text-transform: uppercase;

        @include media-breakpoint-up(xxxl) {
          @include font-style--alter($font-secondary, 80, 80, $weight--light, $white, none);
        }

        strong {
          font-weight: $weight--bold;
        }
      }

      a {
        display: inline-block;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem 1rem 1.5rem;
        border-radius: 8px;
        border: 1px solid $white;
        background-color: transparent;
        @include font-style--alter($font-primary, 24, 24, $weight--bold, $white, none);
        text-transform: uppercase;

        transition: background-color $general-transition-time $general-transition-ease,
        color $general-transition-time $general-transition-ease;

        &:hover {
          background-color: rgba($white, 0.6);
          color: $black;
        }
      }
    }

    .content-wrapper {
      display: flex;
      align-items: stretch;
      margin: 0 -42px;

      @include media-breakpoint-down(md) {
        margin: 0 -20px;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
      }

      &.slick-slider {
        position: relative;

        .slick-dots {
          position: absolute;
          bottom: -2rem;
          left: 0;
          right: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1rem;

          li {
            margin: 0 .375rem;
            cursor: pointer;

            button {
              appearance: none;
              border: 0;
              padding: 0;
              width: .75rem;
              height: .75rem;
              background-color: rgba($white, 0.5);
              border-radius: 50%;
              display: block;
              text-indent: -9999em;
            }

            &.slick-active {
              button {
                background-color: $white;
              }
            }
          }
        }

        .slick-list {
          overflow: hidden;
        }
        .slick-track {
          overflow: hidden;

          .slick-slide {
            float: left;
          }
        }
      }

      .text {
        width: 25%;
        flex: 0 0 25%;
        padding: 0 42px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
          padding: 0 20px;
        }
        @include media-breakpoint-down(sm) {
          padding: 0;
        }

        h3 {
          @include font-style--alter($font-secondary, 24, 30, $weight--xBold, $white, none);
          position: relative;
          padding-left: 28px;
          margin-bottom: 28px;

          span {
            &:first-child {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }

        p {
          @include font-style--alter($font-primary, 16, 23, $weight--regular, $white, none);
        }
      }
    }
  }
}