.cart-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  max-width: 412px;
  background-color: $color__beige;

  transform: translateX(100%);

  transition: transform $general-transition-time $general-transition-ease;

  @include media-breakpoint-up(md) {
    width: 80%;
  }

  .sidebar--active & {
    transform: translateX(0);
  }

  &--wrapper {
    padding: 1.5rem 1.25rem;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .close {
    display: flex;
    margin-left: auto;
    margin-bottom: 3rem;

    @include font-style--alter($font-secondary, 14, 14, $weight--semiBold, $color__blue-light-2, none);
    text-transform: uppercase;
  }

  .cart-content {
    margin-bottom: auto;
    padding-top: 1.25rem;
    border-top: 1px solid $color__blue-light-2;

    overflow: auto;

    .element {
      padding-bottom: 1.5rem;

      &--wrapper {
        display: flex;
        align-items: flex-end;
      }

      .image {
        width: 5rem;
        flex: 0 0 5rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      .content {
        width: 100%;
        padding-left: .75rem;
        margin-bottom: .25rem;

        .remove {
          display: flex;
          margin-left: auto;
          margin-bottom: 1rem;

          width: 14px;
          height: 14px;
          background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 9'%3E%3Cg stroke='%232AAEC3' opacity='.6'%3E%3Cpath d='M1.258 8.536L9.036.757M1.354.646l7.778 7.779'/%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% 100%;
        }

        .title {
          @include font-style--alter($font-primary, 18, 19, $weight--bold, $color__blue-light-2, none);
          text-transform: uppercase;
          margin-bottom: .5rem;
        }

        .description {
          @include font-style--alter($font-primary, 16, 18, $weight--regular, $color__blue-light-2, none);
          margin-bottom: 1rem;
        }

        .options {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .qty {
            display: flex;
            align-items: center;

            span {
              @include font-style--alter($font-primary, 14, 16, $weight--regular, $color__blue-light-2, none);
            }

            .quantity {
              position: relative;
              padding-left: .5rem;
              padding-right: .5rem;
              width: 3rem;
              margin-left: 1rem;

              &-nav {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                .quantity-button {
                  cursor: pointer;
                  width: .5rem;
                  height: .5rem;
                  position: absolute;
                }

                .quantity-down {
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);

                  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 3 4'%3E%3Cpath fill='%232AAEC3' d='M0 2L3 .268v3.464L0 2z'/%3E%3C/svg%3E");
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 8px 8px;
                }

                .quantity-up {
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);

                  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 3 4'%3E%3Cpath fill='%232AAEC3' d='M3 2L0 3.732V.268L3 2z'/%3E%3C/svg%3E");
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 8px 8px;
                }
              }
            }

            input[type="number"] {
              position: absolute;
              top: 50%;
              left: 50%;
              height: 1rem;
              width: 1rem;
              transform: translate(-50%, -50%);
              appearance: textfield;
              border: 0;
              border-radius: 0;
              background: transparent;
              text-align: center;
              padding: 0 .25rem;

              @include font-style--alter($font-primary, 14, 16, $weight--regular, $color__blue-light-2, none);

              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
              }

              &::placeholder {
                @include font-style--alter($font-primary, 14, 16, $weight--regular, $color__blue-light-2, none);
              }
            }
          }

          .price {
            @include font-style--alter($font-primary, 16, 18, $weight--bold, $color__blue-light-2, none);
          }
        }
      }
    }
  }

  .cart-footer {
    flex: 0 0 auto;

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    .subtotal {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: .5rem .125rem;
      border-top: 1px solid $color__blue-light-2;
      border-bottom: 1px solid $color__blue-light-2;

      margin-bottom: 1rem;

      span {
        @include font-style--alter($font-primary, 16, 30, $weight--bold, $color__blue-light-2, none);
      }

      .price {
        @include font-style--alter($font-primary, 16, 30, $weight--bold, $color__blue-light-2, none);
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .show {
        display: inline-block;
        padding: .75rem 1.5rem;
        background-color: transparent;
        border: 1px solid $color__blue-light-2;
        @include font-style--alter($font-primary, 14, 14, $weight--semiBold, $color__blue-light-2, none);
        text-transform: uppercase;

        transition: background-color $general-transition-time $general-transition-ease,
        color $general-transition-time $general-transition-ease,
        border-color $general-transition-time $general-transition-ease;

        &:hover {
          border: 1px solid $color__blue;
          color: $color__blue;
        }
      }
      .checkout {
        display: inline-block;
        padding: .75rem 1.5rem;
        background-color: $color__blue-light-2;
        border: 1px solid $color__blue-light-2;
        @include font-style--alter($font-primary, 14, 14, $weight--semiBold, $white, none);
        text-transform: uppercase;

        transition: background-color $general-transition-time $general-transition-ease,
        color $general-transition-time $general-transition-ease,
        border-color $general-transition-time $general-transition-ease;

        &:hover {
          border: 1px solid $color__blue;
          background-color: $color__blue;
          color: $white;
        }
      }
    }
  }
}