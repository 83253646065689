/*
  @file:
    _variables.scss

  @description:
    SASS variables used and shared amongst all the
    individual files/functions
  
  @implementation:
    File is included in the main.scss file via the
    @include function

  @naming:
    All variables should be named logically in
    order to avoid confusion and conflicts.

    Good example:
    $primary-color: #000;

    Bad example:
    $black-color: #000;
*/

// Imports
@import url("https://use.typekit.net/mgx0wna.css");


// Font weights
$weight--hairline       : 200;
$weight--extraLight     : 200;
$weight--light          : 300;
$weight--normal         : normal;
$weight--regular        : 400;
$weight--book           : 400;
$weight--medium         : 500;
$weight--semiBold       : 600;
$weight--bold           : 700;
$weight--bold--2        : bold;
$weight--xBold          : 800;
$weight--black          : 900;

// Colors
$white:                   #ffffff;
$black:                   #000000;
$black-2:                 #151515;
$black-3:                 #0F0F0F;
$color__grey-dark:        #333333;
$color__grey:             #4E4E4E;
$color__grey-light:       #F2F2F2;
$color__grey-light-2:     #EAEAEA;
$color__grey-2:           #E9E9E9;
$color__grey-3:           #E0E0E0;
$color__grey-4:           #525252;
$color__grey-5:           #969696;
$color__grey-6:           #C6C6C6;
$color__grey-7:           #2E2E2E;
$color__grey-8:           #9D9D9D;
$color__grey-9:           #9F9F9F;
$color__grey-10:          #C4C4C4;
$color__grey-11:          #7D7D7D;
$color__blue:             #0095C6;
$color__blue-dark:        #123156;
$color__blue-dark-2:      #277ED5;
$color__blue-light:       #51B9CA;
$color__blue-light-2:     #2AAEC3;
$color__green:            #77842b;
$color__green-light:      #9dab47;
$logo__green:             #aeab49;
$logo__grey:              #636466;
$logo__red:               #d71920;
$color__red:              #FF0202;
$color__red-2:            #B93131;
$logo__aphrodite-dark:    #0b1e41;
$logo__aphrodite-green:   #5cc7d3;
$color__beige:            #F4F4F4;

$primary:                 $color__grey;
$secondary:               $white;


$general-transition-time  : .4s;
$general-transition-ease  : cubic-bezier(.47,.17,.17,.58);

// Switches
$enable-responsive-font-sizes:		true;
$enable-pointer-cursor-for-buttons:	true;
$enable-validation-icons:			false;
//$enable-transitions:				false;

// Body
$body-bg:							white;
$body-color:						$primary;

$grid-gutter-width:					2rem;

// Fonts
$font-primary:					    'Lato', sans-serif;
$font-secondary:                    'Montserrat', sans-serif;
$font-family-base:					'Lato', sans-serif;
$headings-font-family:              'Montserrat', sans-serif;
$headings-font-weight:				400;
$headings-font-weight-alter:		600;
$headings-color:					$primary;
$font-weight-base:					400;
$font-weight-medium:				500;
$font-family-sans-serif:			$font-family-base;
$custom-select-font-family:			$font-family-base;
$input-font-family:					$font-family-base;
$font-size-base:					1rem;
$line-height-base:					1.5;
$lead-font-size:					1.2rem;
$lead-font-weight:					500;
$headings-line-height:				1.5;

// Heading sizes
$h1-big:                            11.5rem;
$h1-small:                          4.5rem;
$h1-font-size:						7.5rem;
$h2-font-size:						5rem;
$h3-font-size:						3rem;
$h4-font-size:						2.75rem;
$h5-font-size:						2.5rem;
$h6-font-size:						2rem;

// Spacers and widths
$spacer:							1rem;
$spacers: (
        0: 0,
        1: ($spacer * .5),
        2: ($spacer * 1),
        3: ($spacer * 1.5),
        4: ($spacer * 2),
        5: ($spacer * 2.5),
        6: ($spacer * 3),
        10: ($spacer * 5),
);
$container-max-widths: (
  //sm: 540px,
  //md: 720px,
        lg: 960px,
        xl: 1032px,
        xxl: 1232px
);
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px
);

// Buttons
$btn-font-weight:					bold;
$btn-font-weight-sm:				normal;
$btn-border-width:					0;
$btn-font-size:						1.25rem;
$btn-font-size-sm:					.75rem;
$btn-line-height:					1.5rem;
$btn-line-height-sm:				1.5rem;
$btn-padding-y:						1.75rem;
$btn-padding-x:						2rem;
$btn-padding-y-sm:					1rem;
$btn-padding-x-sm:					1rem;
$btn-border-radius:					0;
$btn-border-radius-sm:				$btn-border-radius;
$btn-disabled-opacity:				.5;

// Forms
$input-border-color:				$primary;
$input-focus-border-color:			#302b2b;
$input-padding-y:					1rem;
$input-padding-x:					1rem;
$input-line-height:					1.5;
$input-border-radius:				.25rem;

// Cards
$card-border-color:					$primary;
$card-border-radius:				.25rem;
$card-bg:							white;
$card-cap-bg:						transparent;
$card-spacer-y:						1rem;
$card-spacer-x:						1rem;

// Pagination
$pagination-border-width:			0;
$pagination-color:					$primary;
$pagination-active-color:			$primary;
$pagination-hover-color:			$primary;
$pagination-bg:						transparent;
$pagination-active-bg:				transparent;
$pagination-hover-bg:				transparent;