/*
  @file:
    functions.scss

  @description:
    SASS custom functions used and shared amongst all the
    individual files/functions
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

/*
  @function:
    em( $value, $base )

  @description
    Simple function that converts pixels to em's

  @arguments:
    $value : The pixel value
    $base  : The base font size

  @implementation:
    em( 25px )

*/

@function em(
  $value,
  $base: 16px
) {

  @return ($value / $base) * 1em;

}

/*
  @function:
    rem( $value, $base )

  @description
    Simple function that converts pixels to rem's

  @arguments:
    $value : The pixel value
    $base  : The base font size

  @implementation:
    rem( 25px )
*/

@function rem(
  $value,
  $base: 16px
) {

  @return ($value / $base) * 1rem;

}

/*
  @function:
    z-index( $layer, $z-layers )

  @description
    Simple function that generates a z-index value based on the key
    values from a list

  @arguments:
    $layer : The desired value to be taken from the list
    $z-layers : List that contains the z-index values

  @implementation:
    z-index( 'modal' )

*/

@function z-index(
  $layer,
  $z-layers
) {

  @if not map-has-key(
    $z-layers,
    $layer
  ) {
    @warn "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
  }

  @return map-get($z-layers, $layer);

}
