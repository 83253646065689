/*
  @file:
    _pager.scss

  @description:
    Module used to style/expand pager related
    styles
  
  @implementation:
    File is included in the main.scss file via the
    @include function
*/

.page-pager {
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 4.5rem;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 7.5rem;
  }

  .pager {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-bottom: 0;

    .pager-item {
      &.pager-prev {
        margin-right: 1.5rem;

        a {
          display: block;
          line-height: 0;
          padding: 1rem;

          svg {
            width: 8px;
            height: 20px;
          }
        }
      }

      &.pager-next {
        margin-left: 1.5rem;

        a {
          display: block;
          line-height: 0;
          padding: 1rem;

          svg {
            width: 8px;
            height: 20px;
          }
        }
      }

      &.pager-current {
        a {
          font-weight: $weight--black;
        }
      }

      a {
        padding: 1rem .5rem;
        @include font-style--alter($font-secondary, 20, 32, $weight--regular, $black-2, none);
      }
    }
  }
}