.cover-news {
  position: relative;

  &-wrapper {
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 1rem;
    //height: 100vh;
    //max-height: 976px;
    min-height: 640px;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    & > .row {
      height: 100%;
      @include media-breakpoint-up(md) {
        min-height: 640px;
      }

      .text {
        height: 100%;
        position: relative;
        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-up(md) {
          min-height: 640px;
        }
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-right: 6rem;

      @include media-breakpoint-down(xl) {
        padding-right: 2rem;
      }
      @include media-breakpoint-down(sm) {
        padding-right: 2rem;
      }

      @include media-breakpoint-up(md) {
        min-height: 520px;
      }
    }

    h1 {
      @include font-style--alter($font-secondary, 64, 56, $weight--medium, $color__grey-dark, none);
      text-transform: uppercase;
      margin-bottom: 3rem;

      @include media-breakpoint-down(md) {
        color: $white;
      }

      @include media-breakpoint-down(xl) {
        @include font-size(3.5rem);
      }
    }

    p {
      @include font-style--alter($font-primary, 20, 36, $weight--regular, $color__grey-dark, none);

      @include media-breakpoint-down(xxl) {
        @include font-style--alter($font-primary, 16 32, $weight--regular, $color__grey-dark, none);
      }

      @include media-breakpoint-down(xl) {
        @include font-size(1rem);
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        color: $white;
      }
    }

    .date {
      display: block;
      margin-top: -2.5rem;
      margin-bottom: 2rem;

      @include font-style--alter($font-primary, 16, 32, $weight--regular, $color__grey-dark, none);
      text-transform: uppercase;
      letter-spacing: 1px;

      @include media-breakpoint-down(md) {
        color: $white;
      }
    }

    .link-bullet {
      @include font-style--alter($font-secondary, 16, 24, $weight--xBold, $color__grey-dark, none);
      text-transform: uppercase;
      padding: .5rem 0 .5rem 2.25rem;

      background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 26px 26px;

      @include media-breakpoint-down(md) {
        color: $white;
        background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23FFFFFF' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }
    }

    .hero-scroll {
      position: absolute;
      right: 1.5rem;
      bottom: 0;

      @include media-breakpoint-down(md) {
        bottom: 2rem;
      }

      .scroll-down {
        @include font-style--alter($font-secondary, 14, 32, $weight--medium, $black, none);
        text-transform: uppercase;
        position: relative;
        transform: rotate(180deg);
        writing-mode: tb-rl;
        padding: 42px .25rem 0 .25rem;

        @include media-breakpoint-down(md) {
          color: $white;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          width: 30px;
          height: 32px;
          transform: translateX(-50%) rotate(180deg);
          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 25V1h1v24h-1z' fill='%23000000' stroke='%23000000'/%3E%3Cpath d='M15.5 28L9.005 16.75h12.99L15.5 28z' fill='%23000000'/%3E%3Ccircle cx='15.5' cy='19.5' r='14.5' stroke='%23000000' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;

          @include media-breakpoint-down(md) {
            background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 25V1h1v24h-1z' fill='%23FFFFFF' stroke='%23FFFFFF'/%3E%3Cpath d='M15.5 28L9.005 16.75h12.99L15.5 28z' fill='%23FFFFFF'/%3E%3Ccircle cx='15.5' cy='19.5' r='14.5' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }


  &-image {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
      z-index: -1;
    }

    &::before {
      @include media-breakpoint-down(md) {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($black, .5);
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.page-news {
  overflow: hidden;

  &-blocks {
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 1rem;

    @include media-breakpoint-down(xxl) {
      max-width: 1160px;
    }

    & > .row {
      margin-left: -1rem;
      margin-right: -1rem;

      @include media-breakpoint-up(xxxl) {
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }

    .block {
      margin-bottom: 2.5rem;
      padding: 0 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(xxxl) {
        margin-bottom: 9rem;
        padding: 0 3rem;
      }

      &-wrapper {
        position: relative;
      }

      .image {
        margin-bottom: 1.5rem;
        position: relative;

        @include media-breakpoint-up(xxl) {
          margin-bottom: 2rem;
        }

        &::after {
          @include media-breakpoint-down(xxl) {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }

        img {
          height: auto;
          width:100%;
          object-fit: cover;
          @include media-breakpoint-up(xl) {
            height:436px;
          }


          @include media-breakpoint-down(xxl) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .text {
        h2 {
          @include font-style--alter($font-secondary, 22, 30, $weight--bold, $black-2, none);
          margin-bottom: 1rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;
          }
          @include media-breakpoint-up(xxxl) {
            margin-bottom: 2.25rem;
            @include font-style--alter($font-secondary, 24, 32, $weight--bold, $black-2, none);
          }
        }

        p {
          @include font-style--alter($font-primary, 20, 34, $weight--regular, $black-2, none);
          margin-bottom: 1rem;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-primary, 16, 30, $weight--regular, $black-2, none);
          }
          @include media-breakpoint-up(md) {
            margin-bottom: 1.75rem;
          }
        }

        .link-bullet {
          @include font-style--alter($font-secondary, 18, 24, $weight--xBold, $color__grey-dark, none);
          text-transform: uppercase;
          padding: .5rem 0 .5rem 2.25rem;

          background-image: url("data:image/svg+xml;utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='12' stroke='%23333' stroke-width='2'/%3E%3Ccircle cx='13' cy='13' r='3' fill='%23333'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 26px 26px;

          @include media-breakpoint-down(xxl) {
            @include font-style--alter($font-secondary, 16, 22, $weight--xBold, $color__grey-dark, none);
          }
        }
      }
    }
  }

  &-single {
    padding-top: 5.75rem;

    &-wrapper {
      padding-bottom: 3rem;
    }
  }
}