/*
  @file:
    main.scss

  @description:
    Handles the import of all SASS snippets
*/
// Base
@import "base/reset";

// Components
@import "components/variables";
@import "components/bootstrap";
@import "components/bootstrap-overrides";
@import "vendors/fullpage";


// Helpers
@import "helpers/functions";
@import "helpers/placeholders";
@import "helpers/mixins";
@import "helpers/rfs";

@import "base/base";

@import "base/typography";
// Grid
//@import "layout/grid";

// Animations
@import "components/animations";

// Toolbars
@import "components/toolbar";

// Components
@import "components/pager";
@import "components/breadcrumbs";
@import "layout/sections";
@import "components/cart-sidebar";

// Layout
@import "layout/header";
@import "layout/footer";
@import "layout/admin";
@import "layout/overlay";

// Elements
@import "layout/elements";

// Multimedia
@import "sections/multimedia";

// Pages
@import "pages/basic";
@import "pages/home";
@import "pages/news";
@import "pages/project";
@import "pages/book";
@import "pages/shop";

// Sections

// Vendors
// @import "vendors/jquery-ui";
@import "vendors/flickity";
@import "components/aos";
